import http from 'config/http';
import { Statsig } from 'statsig-react';
import {
	LOGIN_REQUESTED,
	LOGIN_SUCCESS,
	LOGIN_FAILED,
	REGISTER_REQUESTED,
	REGISTER_SUCCESS,
	REGISTER_FAILED,
	CREATEAPP_REQUESTED,
	CREATEAPP_SUCCESS,
	CREATEAPP_FAILED,
	LISTORG_REQUESTED,
	LISTORG_SUCCESS,
	LISTORG_FAILED,
	PUBLIC_ACCESS
} from '../types/auth.type';

export const loginAction = (user, callback) => async (dispatch) => {
	dispatch({ type: LOGIN_REQUESTED });
	try {
		const payload = await http.get('auth/login/', user).then((res) => res.data);
		dispatch({ type: LOGIN_SUCCESS, payload });
		callback && callback();
	} catch (err) {
		dispatch({ type: LOGIN_FAILED, payload: err?.response?.data });
	}
};

export const loginThirdPartyAction =
	(payload, token, callback) => async (dispatch) => {
		dispatch({ type: LOGIN_REQUESTED });

		try {
			dispatch({ type: LOGIN_SUCCESS, payload });

			localStorage.setItem('session', token);

			callback && callback();
		} catch (err) {
			dispatch({ type: LOGIN_FAILED, payload: err?.response?.data });
		}
	};

export const publicAccessAction = () => async (dispatch) => {
	dispatch({ type: PUBLIC_ACCESS });
};

export const registerAction = (user, callback) => async (dispatch) => {
	dispatch({ type: REGISTER_REQUESTED });

	try {
		const payload = await http.post('auth/', user).then((res) => res.data);
		dispatch({ type: REGISTER_SUCCESS, payload });
		callback && callback();
	} catch (err) {
		dispatch({ type: REGISTER_FAILED, payload: err?.response?.data });
	}
};

export const getOrgAction = () => async (dispatch) => {
	dispatch({ type: LISTORG_REQUESTED });
	const token = localStorage.getItem('session');
	try {
		const payload = await http
			.get('auth/organisations', {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then((res) => res.data);
		dispatch({ type: LISTORG_SUCCESS, payload });
	} catch (err) {
		dispatch({ type: LISTORG_FAILED, payload: err?.response?.data });
	}
};

export const createAppAction = (data, callback) => async (dispatch) => {
	dispatch({ type: CREATEAPP_REQUESTED });
	const token = localStorage.getItem('session');
	try {
		const payload = await http
			.post('auth/organisations', data, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then((res) => res.data);
		dispatch({ type: CREATEAPP_SUCCESS, payload });
		callback && callback();
	} catch (err) {
		dispatch({ type: CREATEAPP_FAILED, payload: err?.response?.data });
	}
};

export const updateUserAction = (data, callback) => async (dispatch) => {
	dispatch({ type: CREATEAPP_REQUESTED });
	const token = localStorage.getItem('session');
	try {
		const payload = await http
			.post('auth/', data, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then((res) => res.data);
		dispatch({ type: REGISTER_SUCCESS, payload });
		callback && callback();
	} catch (err) {
		dispatch({ type: REGISTER_FAILED, payload: err?.response?.data });
	}
};

export const logoutAction = (callback) => {
	Statsig.logEvent('LOGGED_OUT');
	localStorage.removeItem('session');
	callback && callback();
};

export const addPaymentAction = (data, callback) => async (dispatch) => {
	dispatch({ type: CREATEAPP_REQUESTED });
	const token = localStorage.getItem('session');
	try {
		const payload = await http
			.post('auth/', data, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then((res) => res.data);
		dispatch({ type: REGISTER_SUCCESS, payload });
		callback && callback();
	} catch (err) {
		dispatch({ type: REGISTER_FAILED, payload: err?.response?.data });
	}
};

export const createTokenAction = async (data, callback) => {
	const token = localStorage.getItem('session');
	await http
		.post('tokens', data, {
			headers: { Authorization: `Bearer ${token}` }
		})
		.then((res) => callback(res.data));
};

export const getTokensAction = async (callback) => {
	const token = localStorage.getItem('session');
	await http
		.get('tokens', {
			headers: { Authorization: `Bearer ${token}` }
		})
		.then((res) => callback(res.data));
};
