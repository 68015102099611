import React, { useState, createRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SearchInput, ArticleRightTab } from 'components';
import { mainContent, content, sections } from './sample-data';
import './style.css';
import sidePlayer from 'assets/common/side-player.svg';

const HelpArticle = () => {
	const isMobile = useMediaQuery({ maxWidth: 800 });
	const [rightTabVisible, setRightTabVisible] = useState(false);
	const [activeSection, setActiveSection] = useState(0);
	const [mainContentData, setMainContentData] = useState(null);

	useEffect(() => {
		if (!mainContentData) {
			let temp = JSON.parse(JSON.stringify(mainContent));
			temp.map((item) => (item.headerRef = createRef()));
			setMainContentData(temp);
		}
	}, []);

	useEffect(() => {
		if (mainContentData) {
			document.addEventListener('scroll', handleScroll);
			return () => {
				document.removeEventListener('scroll', handleScroll);
			};
		}
	}, [mainContentData]);

	const nearestIndex = (
		currentPosition,
		sectionPositionArray,
		startIndex,
		endIndex
	) => {
		if (startIndex === endIndex) return startIndex;
		else if (startIndex === endIndex - 1) {
			if (
				Math.abs(
					sectionPositionArray[startIndex].headerRef.current?.offsetTop -
						currentPosition
				) <
				Math.abs(
					sectionPositionArray[endIndex].headerRef.current?.offsetTop -
						currentPosition
				)
			)
				return startIndex;
			else return endIndex;
		} else {
			var nextNearest = ~~((startIndex + endIndex) / 2);
			var a = Math.abs(
				sectionPositionArray[nextNearest].headerRef.current?.offsetTop -
					currentPosition
			);
			var b = Math.abs(
				sectionPositionArray[nextNearest + 1].headerRef.current?.offsetTop -
					currentPosition
			);
			if (a < b) {
				return nearestIndex(
					currentPosition,
					sectionPositionArray,
					startIndex,
					nextNearest
				);
			} else {
				return nearestIndex(
					currentPosition,
					sectionPositionArray,
					nextNearest,
					endIndex
				);
			}
		}
	};
	const handleScroll = () => {
		var index = nearestIndex(
			window.scrollY,
			mainContentData,
			0,
			mainContentData.length - 1
		);
		setActiveSection(index);
	};

	if (!mainContentData) return <div></div>;
	return (
		<div className="help-article">
			{(!isMobile || rightTabVisible) && (
				<ArticleRightTab
					isMobile={isMobile}
					hideContainer={() => {
						setTimeout(() => setRightTabVisible(false), 500);
					}}
					sections={sections}
					activeSection={activeSection}
					setActiveSection={(i) => {
						setActiveSection(i);
						mainContentData[i].headerRef.current.scrollIntoView();
					}}
					hideLowerSection
					numbers
					top={170}
				/>
			)}
			<div className="content-container">
				<SearchInput
					style={{
						borderRadius: 30,
						height: 65,
						zIndex: 99
					}}
					fontSize={20}
					placeholder="How can we help you?"
				/>
				<h3 className="sfprodisplay-bold-white-12px">{`MediaMagic Help Center > ${content.header}`}</h3>
				<h2 className="sfprodisplay-heavy-white-32px">
					{content.header}
				</h2>
				<img
					className="hidden-md player"
					src={sidePlayer}
					onClick={() => setRightTabVisible(!rightTabVisible)}
				/>
				{content.sections.map((item, i) => (
					<div
						id={item.title.replaceAll(' ', '-').toLowerCase().substring(3)}
						key={i}
						className="item"
						style={{ paddingTop: 40 }}
					>
						<h2 className="sfprodisplay-regular-white-28px">{item.title}</h2>
						<p
							dangerouslySetInnerHTML={{ __html: item.p }}
							className="sfprodisplay-medium-white-18px"
						></p>
					</div>
				))}
				{mainContentData &&
					mainContentData.map((item, i) => (
						<div
							key={`${i}-main`}
							className="item"
							style={{ paddingTop: 40 }}
							ref={item.headerRef}
						>
							<h2 className="sfprodisplay-regular-white-28px">{item.title}</h2>
							<p
								dangerouslySetInnerHTML={{ __html: item.p }}
								className="sfprodisplay-medium-white-18px"
							></p>
						</div>
					))}
			</div>
		</div>
	);
};

export default HelpArticle;
