import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { SearchInput, Tabs } from 'components';
import { individual, developer, business, trendings } from './sample-data';
import './style.css';

const Help = () => {
	const [search, setSearch] = useState('');
	const [activeTab, setActiveTab] = useState(0);
	const isMobile = useMediaQuery({ maxWidth: 800 });
	return (
		<div className="help">
			<div className="upper-section">
				<SearchInput
					style={{
						width: isMobile ? '92%' : '80%',
						borderRadius: 30,
						height: 65,
						zIndex: 99
					}}
					onChange={(e) => setSearch(e.target.value)}
					fontSize={20}
					placeholder="How can we help you?"
				/>
				<div className="content-container">
					<h2 className="sfprodisplay-bold-white-32px">Help Center</h2>
					<div className="hidden-md">
						<Tabs
							tabs={[
								{ title: 'Individuals' },
								{ title: 'Businesses' },
								{ title: 'Developers' }
							]}
							onClick={(val) => setActiveTab(val)}
							active={activeTab}
						/>
					</div>
					<div className="content">
						{(!isMobile || activeTab === 0) && (
							<div>
								<h6 className="sfprodisplay-heavy-white-14px hidden-sm">
									Individuals
								</h6>

								{individual.map(
									(item, i) =>
										item.header
											.toLowerCase()
											.includes(search.toLowerCase()) && (
											<Link to="help/article">
												<div className="item" key={`${i}-ind`}>
													<div />
													<div>
														<h4 className="sfprodisplay-medium-white-18px">
															{item.header}
														</h4>
														<p className="sfprodisplay-medium-white-12px">
															{item.desc}
														</p>
													</div>
												</div>
											</Link>
										)
								)}
							</div>
						)}
						{(!isMobile || activeTab === 1) && (
							<div>
								<h6 className="sfprodisplay-heavy-white-14px hidden-sm">
									Businesses
								</h6>
								{business.map(
									(item, i) =>
										item.header
											.toLowerCase()
											.includes(search.toLowerCase()) && (
											<Link to="help/article">
												<div className="item" key={`${i}-bus`}>
													<div />
													<div>
														<h4 className="sfprodisplay-medium-white-18px">
															{item.header}
														</h4>
														<p className="sfprodisplay-medium-white-12px">
															{item.desc}
														</p>
													</div>
												</div>
											</Link>
										)
								)}
							</div>
						)}
						{(!isMobile || activeTab === 2) && (
							<div>
								<h6 className="sfprodisplay-heavy-white-14px hidden-sm">
									Developers
								</h6>
								{developer.map(
									(item, i) =>
										item.header
											.toLowerCase()
											.includes(search.toLowerCase()) && (
											<Link to="help/article">
												<div className="item" key={`${i}-dev`}>
													<div />
													<div>
														<h4 className="sfprodisplay-medium-white-18px">
															{item.header}
														</h4>
														<p className="sfprodisplay-medium-white-12px">
															{item.desc}
														</p>
													</div>
												</div>
											</Link>
										)
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="center-section">
				<div>
					<h2 className="sfprodisplay-bold-white-32px">
						Trending help articles
					</h2>
					<div className="trends-container">
						{trendings.map((item, i) => (
							<Link key={`trends-${i}`} to="help/article">
								<div>
									<h5>{item.header}</h5>
									<h4>{item.subHeader}</h4>
									<p>{item.content}</p>
								</div>
							</Link>
						))}
					</div>
				</div>
			</div>
			<div className="lower-section">
				<p className="sfprodisplay-medium-white-24px">
					Can’t find what you’re looking for?
				</p>
				<Link to="contact-us">
					<button className="sfprodisplay-bold-white-24px"> Contact us</button>
				</Link>
			</div>
		</div>
	);
};

export default Help;
