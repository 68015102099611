import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Switcher, ArticleRightTab } from 'components';
import { useMediaQuery } from 'react-responsive';
import { sections } from './sample-data';
import './style.css';
import start from 'assets/common/start.svg';
import rate from 'assets/common/rate.svg';
import rateColor from 'assets/common/rate-color.svg';
import like from 'assets/common/like.svg';
import likeColor from 'assets/common/like-color.svg';
import subtract from 'assets/common/subtract.svg';
import article2 from 'assets/article/article2.png';
import sidePlayer from 'assets/common/side-player.svg';

const Article = () => {
	const isMobile = useMediaQuery({ maxWidth: 800 });
	const [sectionsData, setSectionsData] = useState(null);
	const [activeTab, setActiveTab] = useState(0);
	const [switchActive, setSwitchActive] = useState(false);
	const [rightTabVisible, setRightTabVisible] = useState(false);
	const [activeSection, setActiveSection] = useState(0);
	const [videoPlaying, setVideoPlaying] = useState(false);
	const [hoveredRate, setHoveredRate] = useState(null);

	useEffect(() => {
		if (!sectionsData) setSectionsData(sections);
	}, [sections]);

	const updateVideoInfo = (key, value) => {
		let tempSectionsData = JSON.parse(JSON.stringify(sectionsData));
		tempSectionsData[activeSection][key] = value;

		setSectionsData(tempSectionsData);
	};
	if (!sectionsData) return <></>;
	return (
		<div className="article">
			{(!isMobile || rightTabVisible) && (
				<ArticleRightTab
					isMobile={isMobile}
					hideContainer={() => {
						setTimeout(() => setRightTabVisible(false), 500);
					}}
					sections={sectionsData}
					activeSection={activeSection}
					setActiveSection={(i) => {
						setActiveSection(i);
						setVideoPlaying(false);
					}}
				/>
			)}
			<div
				className={`${switchActive ? 'white-leftcontainer' : 'leftcontainer'}`}
			>
				<div className="upper-container">
					<div className="flex-row-1">
						<div className="flex-col">
							<div className="switcher hidden-sm">
								Toggle Background
								<Switcher onChange={() => setSwitchActive(!switchActive)} />
							</div>

							<div className="group-47082 hidden-sm">
								<Link to="/app/dashboard">
									<div className="back sfprodisplay-medium-white-18px">
										<svg width="10" height="17" viewBox="0 0 10 17" fill="#fff">
											<path d="M0.753336 9.32407L7.27376 15.6823C7.73133 16.1365 8.41769 16.1365 8.87527 15.6823C9.33284 15.2281 9.33284 14.5469 8.87527 14.0927L3.26999 8.41575L8.87527 2.73877C9.33284 2.28461 9.33284 1.60337 8.87527 1.14921C8.64648 0.922133 8.41769 0.808594 8.07451 0.808594C7.73133 0.808594 7.50255 0.922133 7.27376 1.14921L0.753336 7.50744C0.295763 8.07513 0.295763 8.75637 0.753336 9.32407C0.753336 9.21053 0.753336 9.21053 0.753336 9.32407Z" />
										</svg>
										Back
									</div>
								</Link>
							</div>
							<div className="text-1-1">
								<div>Face Morphing : {sectionsData[activeSection].title}</div>
								<img
									className="hidden-md"
									src={sidePlayer}
									onClick={() => setRightTabVisible(!rightTabVisible)}
								/>
							</div>
							<div className="frame-47107">
								<p className="name sfprodisplay-medium-white-14px">
									Charlie Kelly, ML PHD &amp; Creative Director
								</p>
								<div className="ellipse-511"></div>
								<div className="group-47105 hidden-sm">
									<div className="group-47074">
										<div
											className={`overlap-group-7 ${
												sectionsData[activeSection].likeYourself
													? 'overlap-group-7-color'
													: ''
											}`}
											onClick={() =>
												updateVideoInfo(
													'likeYourself',
													!sectionsData[activeSection].likeYourself
												)
											}
										>
											<img
												src={
													sectionsData[activeSection].likeYourself
														? like
														: likeColor
												}
												width="12px"
												
											/>
											<div className="number">
												{sectionsData[activeSection].likeYourself
													? sectionsData[activeSection].like + 1
													: sectionsData[activeSection].like}
											</div>
										</div>
									</div>
									<div className="group-47073">
										<div className="rate-it">RATE IT</div>
										<div onMouseLeave={() => setHoveredRate(null)}>
											{[0, 1, 2, 3, 4].map((item) => (
												<img
													key={item}
													className={'frame-47082'}
													onClick={() =>
														updateVideoInfo('rateYourself', item + 1)
													}
													onMouseEnter={() => setHoveredRate(item + 1)}
													src={
														sectionsData[activeSection].rateYourself > item ||
														hoveredRate > item
															? rateColor
															: rate
													}
												/>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="group-47106">
							<div className="frame-47086">
								<div className={'group-47061 hidden-sm'}>
									<img className="frame-47080-1" src={like} />
									<div className="address">
										{sectionsData[activeSection].likeYourself
											? sectionsData[activeSection].like + 1
											: sectionsData[activeSection].like}{' '}
										LIKES
									</div>
								</div>
								<div className="group-47069">
									<img className="frame-47080-2" src={rate} />
									<div className="x463-on-74-ratings">
										{sectionsData[activeSection].rateVal} on{' '}
										{sectionsData[activeSection].rateCount} RATINGS
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="group-47104">
						<ReactPlayer
							playing={videoPlaying}
							controls={videoPlaying}
							url={[sectionsData[activeSection].src]}
							volume={1}
							width={'100%'}
							height={500}
						/>
						{!videoPlaying && (
							<div
								className="group-47058"
								onClick={() => {
									setVideoPlaying(true);
								}}
							>
								<img src={start} />
							</div>
						)}
						<div className="group-47084">
							<div className="group-47067">
								<div className="frame-47080-3">
									<img className="subtract-2" src={subtract} />
								</div>
								<div className="text-4">7,345</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex-row-2">
					{['About', 'Related', 'Discussion', 'Examples'].map((item, i) => (
						<div
							key={i}
							className={`about ${i === activeTab ? 'active-tab' : ''}`}
							onClick={() => setActiveTab(i)}
						>
							{item}
						</div>
					))}
				</div>
				{activeTab === 0 ? (
					<>
						<div className="about-this-ai">About this AI</div>
						<div className="text-2 sfprodisplay-medium-white-18px">
							Here is an easy less than 10 minute tutorial to making your first
							Face Morph. Lets morph a clothing modeling into your favorite
							celebrity.
						</div>
						<p className="text-3 sfprodisplay-medium-white-16px">
							Fusce elementum ante sed maximus viverra. Fusce vitae tempor
							purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus
							blandit. Sed ac mi arcu. Pellentesque interdum est nec justo
							vestibulum luctus. Ut vitae enim hendrerit urna semper tempus eget
							ac magna. Nullam sodales id tortor in blandit. Morbi tellus purus,
							vestibulum tristique odio ut, iaculis semper nisi. Sed eu rhoncus
							ligula. Etiam non sodales est. Mauris in est eget nibh rhoncus
							faucibus.
						</p>
						<div className="group-47107">
							<p className="text-5 sfprodisplay-medium-white-16px">
								Donec viverra, neque ut malesuada accumsan, dolor turpis
								ultrices augue, suscipit dapibus diam nibh quis ex. Cras a
								tincidunt orci. Ut sollicitudin tincidunt libero pretium dictum.
								Suspendisse potenti. Maecenas sit amet finibus ante. Sed ac
								fermentum lectus, convallis fermentum ligula. Duis placerat ac
								lectus vitae tempus. Vestibulum quis sem a justo rutrum lobortis
								in in odio. Cras tincidunt lorem neque, eget lobortis lacus
								porta placerat. Class aptent taciti sociosqu ad litora torquent
								per conubia nostra, per inceptos himenaeos. Aenean quis euismod
								nisi, et aliquet turpis.
							</p>
							<img className="image-156" src={article2} />
						</div>
						<p className="text-6 sfprodisplay-medium-white-16px">
							Fusce elementum ante sed maximus viverra. Fusce vitae tempor
							purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus
							blandit. Sed ac mi arcu. Pellentesque interdum est nec justo
							vestibulum luctus. Ut vitae enim hendrerit urna semper tempus eget
							ac magna. Nullam sodales id tortor in blandit. Morbi tellus purus,
							vestibulum tristique odio ut, iaculis semper nisi. Sed eu rhoncus
							ligula. Etiam non sodales est. Mauris in est eget nibh rhoncus
							faucibus.
						</p>
					</>
				) : activeTab === 1 ? (
					<div className="about-this-ai">Related this AI</div>
				) : activeTab === 2 ? (
					<div className="about-this-ai">Discussion this AI</div>
				) : (
					<div className="about-this-ai">Examples this AI</div>
				)}
				<div className="hidden-md" style={{ height: 70 }}></div>
			</div>
		</div>
	);
};

export default Article;
