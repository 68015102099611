import {
	applyMiddleware,
	legacy_createStore as createStore,
	combineReducers
} from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import authReducer from './reducers/auth.reducer';
import dashboardReducer from './reducers/dashboard.reducer';
import browseAiReducer from './reducers/browseai.reducer';
import mediaReducer from './reducers/media.reducer';
import jobsReducer from './reducers/jobs.reducer';
import usersReducer from './reducers/users.reducer';
import toastReducer from './reducers/toast.reducer';
import headerReducer from './reducers/header.reducer';

const persistConfig = {
	key: 'data',
	storage,
	whitelist: ['auth']
};

const rootReducer = combineReducers({
	auth: authReducer,
	dashboard: dashboardReducer,
	browseAi: browseAiReducer,
	media: mediaReducer,
	jobs: jobsReducer,
	users: usersReducer,
	toast: toastReducer,
	header: headerReducer
});

const pReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
	pReducer,
	composeWithDevTools(applyMiddleware(thunk))
);
persistStore(store);

export default store;
