const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
const masterRegex =
	/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/;
const amexRegex = /^3[47][0-9]{13}$/;
const dinerRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
const discoverRegex =
	/^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/;

export const validateEmail = (value) => {
	return emailPattern.test(value);
};

export const validateName = (value) => {
	return value.length > 2;
};

export const validatePass = (value) => {
	return value.length > 7;
};

export const validatePassAndConfirm = (value, confirmValue) => {
	return value === confirmValue;
};
export const validateCardNo = (value) => {
	return (
		visaRegex.test(value) ||
		masterRegex.test(value) ||
		amexRegex.test(value) ||
		dinerRegex.test(value) ||
		discoverRegex.test(value)
	);
};
export const validateCVV = (value) => {
	return value.length === 3;
};

const requiredMsg = 'This Field is required';
const emailMsg = 'Please enter a valid email';
const regexMsg = 'Inserted value is invalid';

const isEmpty = (value) => value === '';
const isExisty = (value) => value !== undefined && value !== null;
const required = (value) => isExisty(value) && !isEmpty(value);
const isEmail = (value) => emailPattern.test(value);
const minLength = (value, length) => value.length >= length;
const minLengthOptional = (value, length) =>
	value === null || value.length >= length;
const isValidCard = (value) =>
	visaRegex.test(value) ||
	masterRegex.test(value) ||
	amexRegex.test(value) ||
	dinerRegex.test(value) ||
	discoverRegex.test(value);
const isEqual = (value, value2) => value === value2;
const fixedLength = (value, length) => value.length === length;
const fixedDigitsLength = (value, length) =>
	`${value}`.length === length && !isNaN(value);
const ruleWrapper = (rule, value, defaultMsg, customMsg, data = null) => {
	if (rule(value, data)) return undefined;
	return customMsg || defaultMsg;
};

export const validators = {
	required: (value, msg = null) =>
		ruleWrapper(required, value, requiredMsg, msg),
	email: (value, msg = null) => ruleWrapper(isEmail, value, emailMsg, msg),
	card: (value, msg = null) => ruleWrapper(isValidCard, value, regexMsg, msg),
	match: (value, value2, field, msg = null) =>
		ruleWrapper(
			isEqual,
			value,
			`This field does not match ${field}`,
			msg,
			value2
		),
	minLength: (value, length, msg = null) =>
		ruleWrapper(
			minLength,
			value,
			`please enter at least ${length} characters long`,
			msg,
			length
		),
	minLengthOptional: (value, length, msg = null) =>
		ruleWrapper(
			minLengthOptional,
			value,
			`please enter at least ${length} characters long`,
			msg,
			length
		),
	fixedLength: (value, length, msg = null) =>
		ruleWrapper(
			fixedLength,
			value,
			`please enter exactly ${length} characters long`,
			msg,
			length
		),
	fixedDigitsLength: (value, length, msg = null) =>
		ruleWrapper(
			fixedDigitsLength,
			value,
			`please enter exactly ${length} digits long`,
			msg,
			length
		)
};
