import http from 'config/http';
import { Statsig } from 'statsig-react';
import {
	JOBS_REQUESTED,
	GET_JOBS_FAILED,
	GET_JOBS_SUCCESS
} from '../types/jobs.type';

export const getJobsDataAction = () => async (dispatch) => {
	dispatch({ type: JOBS_REQUESTED });
	const token = localStorage.getItem('session');
	Statsig.logEvent('LIST_JOBS_DATA');
	try {
		const payload = await http
			.get('jobs', {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then((res) => res.data);
		dispatch({ type: GET_JOBS_SUCCESS, payload });
	} catch (err) {
		dispatch({ type: GET_JOBS_FAILED, payload: err?.response?.data });
	}
};
