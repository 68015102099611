import axios from 'axios';
import pbkdf2 from 'pbkdf2';

const client = 'symbol';
const login =
	'090j1034k09i9joijlaerg98uabvjaeor9g0394joi3jh948jiln3kqh0b098grever3';
const version = 'v2.7.6.5';
const IS_INTERACTIVE = true;
const endpoints = (interactive = true) => {
	return !interactive
		? {
				newModelWithProducts: '/interactivedemo/get_default_model',
				uploadGarment: '/console/v1/process_new_garment',
				processedGarments: '/console/v1/get_filtered_garments',
				garmentById: '/console/v1/get_garment',
				modifyGarment: '/console/v1/modify_garment',
				deleteGarment: '/console/v1/delete_garment',
				models: '/console/v1/get_model_list',
				shoes: '/console/v1/get_selected_shoes',
				faces: '/console/v1/get_selected_faces',
				tryon: '/console/v1/request_tryon'
		  }
		: {
				newModelWithProducts: '/interactivedemo/get_default_model',
				uploadGarment: '/interactivedemo/get_default_model_filtered',
				processedGarments: '/interactivedemo/get_filtered_products',
				garmentById: '/console/v1/get_garment',
				modifyGarment: '/console/v1/modify_garment',
				deleteGarment: '/console/v1/delete_garment',
				models: '/interactivedemo/get_models_per_skin_tone',
				shoes: '/interactivedemo/get_selected_shoes_with_paging',
				// faces: '/interactivedemo/get_selected_revery_faces',
				faces: '/interactivedemo/get_selected_revery_faces',
				tryon: '/interactivedemo/create_tryon_for_products'
		  };
};

export const reveryS3Url =
	'https://revery-e-commerce-images.s3.us-east-2.amazonaws.com/';

// const reveryS3FacesUrl = (faceId) =>
// 	`https://revery-e-commerce-images.s3.us-east-2.amazonaws.com/revery_faces/${faceId}/crop.png`;

// const reveryModelImage = (model_file) =>
// 	`https://media.revery.ai/generated_model_image/${model_file}.png`;

const reveryApi = axios.create({
	baseURL: 'https://api.revery.ai'
});

const getAuthenticationHeader = () => {
	const timestamp = parseInt(Date.now() / 1000);
	let one_time_code = pbkdf2.pbkdf2Sync(
		login,
		timestamp.toString(),
		128,
		32,
		'sha256'
	);
	one_time_code = one_time_code.toString('hex');

	return {
		client: 'symbol',
		one_time_code,
		timestamp
	};
};

reveryApi.interceptors.request.use(
	(config) => {
		// const {client, one_time_code, timestamp} = getAuthenticationHeader();
		const headers = getAuthenticationHeader();

		config.params = {
			...config.params,
			version,
			client
		};

		config.headers = {
			...config.headers,
			...headers
		};

		return config;
	},
	(error) => Promise.reject(error)
);

export const fetchNewModelWithProducts = async () => {
	return await reveryApi.get(endpoints(IS_INTERACTIVE).newModelWithProducts)
		.data;
};
export const uploadGarment = async (data) => {
	return await reveryApi.post(endpoints(IS_INTERACTIVE).uploadGarment, data)
		.data;
};
export const tryonAction = async (data) => {
	return await reveryApi.post(endpoints(IS_INTERACTIVE).tryon, data).data;
};
export const getFaces = async (params) => {
	return await reveryApi.get(endpoints(IS_INTERACTIVE).faces, { params }).data;
};
export const getShoes = async (params) => {
	return await reveryApi.get(endpoints(IS_INTERACTIVE).shoes, { params }).data;
};
export const getModels = async (params) => {
	return await reveryApi.get(endpoints(IS_INTERACTIVE).models, { params }).data;
};

export const getReveryItemsAction = async (type, params = {}) => {
	return await (
		await reveryApi.get(endpoints(IS_INTERACTIVE)[type], {
			params: {
				gender: 'male',
				page_size: 50,
				...params
			}
		})
	).data;
};
export const getProcessedGarments = async (params) => {
	return await reveryApi.get(endpoints(IS_INTERACTIVE).processedGarments, {
		params: {
			...params,
			site_user_id: '',
			enabled: 1
		}
	}).data;
};
