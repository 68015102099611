import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createAppAction } from 'store/actions/auth.action';
import { validators } from 'helper/validator';
import CreateApp from './components/CreateApp/CreateApp';
import ChoosePlan from './components/ChoosePlan/ChoosePlan';
import AddPayment from './components/AddPayment/AddPayment';
import './style.css';
import logoWord from 'assets/common/logo-word.svg';
import arrowRight from 'assets/common/arrow_right.svg';
import arrowLeft from 'assets/common/left_arrow.svg';

const steps = [
	'Create your\nfirst app',
	'Choose\nyour plan',
	'Add Payment\nDetails'
];
const SignInFlow = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [step, setStep] = useState(0);

	const [teamName, setTeamName] = useState('');
	const [projectName, setProjectName] = useState('');
	const [createAppErrorMsg, setCreateAppErrorMsg] = useState({
		teamName: null,
		projectName: null
	});

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [cardNo, setCardNo] = useState('');
	const [expireDate, setExpireDate] = useState('2023-06');
	const [cvv, setCvv] = useState('');
	const [address, setAddress] = useState('');
	const [paymentErrorMsg, setPaymentErrorMsg] = useState({
		firstName: null,
		lastName: null,
		cardNo: null,
		expireDate: null,
		cvv: null,
		address: null
	});

	const nextStep = (nextStepValue) => {
		if (step === 0) createApp();
		else if (step === 2) addPaymentInfo();
		else setStep(nextStepValue);
	};

	const createApp = () => {
		const errorObj = {
			teamName:
				validators.required(teamName) || validators.minLength(teamName, 3),
			projectName:
				validators.required(projectName) || validators.minLength(projectName, 3)
		};
		setCreateAppErrorMsg(errorObj);

		if (Object.values(errorObj).every((value) => !value)) {
			dispatch(
				createAppAction({ name: teamName }, () => {
					setStep(1);
				})
			);
		}
	};

	const addPaymentInfo = () => {
		const errorObj = {
			firstName:
				validators.required(firstName) || validators.minLength(firstName, 3),
			lastName:
				validators.required(lastName) || validators.minLength(lastName, 3),
			cardNo: validators.required(cardNo) || validators.card(cardNo),
			cvv: validators.required(cvv) || validators.fixedDigitsLength(cvv, 3),
			address: validators.required(address) || validators.minLength(address, 10)
		};

		setPaymentErrorMsg(errorObj);

		if (Object.values(errorObj).every((value) => !value)) {
			history.push('/app/media');
		}
	};
	return (
		<div className="signin-flow">
			<div className={`left-container ${step === 1 && 'remove-padding'}`}>
				<div className="header-con">
					<div>
						<Link to="/">
							<div className="brand">
								<div className="zoose">media</div>
								<img className="ai" src={logoWord} />
							</div>
						</Link>
					</div>
					<div className={`progress-container ${step === 1 && 'add-padding'}`}>
						<div
							className={`rectangle-221 ${step >= 0 ? '' : 'white-line'}`}
						></div>
						<div
							className={`rectangle-221 ${step >= 1 ? '' : 'white-line'}`}
							style={{ marginInline: 26 }}
						></div>
						<div
							className={`rectangle-221 ${step === 2 ? '' : 'white-line'}`}
						></div>
					</div>
				</div>

				<div className="content-container">
					{step === 0 ? (
						<CreateApp
							setTeamName={(val) => setTeamName(val)}
							setProjectName={(val) => setProjectName(val)}
							errorMsg={createAppErrorMsg}
						/>
					) : step === 1 ? (
						<ChoosePlan />
					) : (
						<AddPayment
							setCardNo={(val) => setCardNo(val)}
							setAddress={(val) => setAddress(val)}
							setFirstName={(val) => setFirstName(val)}
							setLastName={(val) => setLastName(val)}
							setExpireDate={(val) => setExpireDate(val)}
							expireDate={expireDate}
							setCvv={(val) => setCvv(val)}
							errorMsg={paymentErrorMsg}
						/>
					)}
				</div>

				<div className="flex-row-1">
					<div
						className="confirmbutton"
						onClick={() =>
							step === 0 ? history.push('/auth') : setStep(step - 1)
						}
					>
						<img className="left-icon" src={arrowLeft} />
						<div className="place hidden-sm">Back</div>
					</div>

					<div className="nextbutton" onClick={() => nextStep(step + 1)}>
						<div className="next">Next</div>
						<img className="frame-3" src={arrowRight} />
					</div>
				</div>
			</div>
			<div className="right-container hidden-sm">
				{steps.map((item, i) => (
					<div key={i} className="item-container">
						<div className={i === step ? 'number-colored' : 'number'}>
							{i + 1}
						</div>
						<h1 className={i === step ? 'text-colored' : 'text'}>{item}</h1>
					</div>
				))}
			</div>
		</div>
	);
};

export default SignInFlow;
