import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { validators } from 'helper/validator';
import { registerAction } from 'store/actions/auth.action';
import { Checkbox } from '@mui/material';
import { Input } from 'components';
import './style.css';
import google from 'assets/common/google.svg';
import github from 'assets/common/github.svg';
import gitlab from 'assets/common/gitlab.svg';
import arrowRight from 'assets/common/arrow_right.svg';
import arrowLeft from 'assets/common/left_arrow.svg';

const Register = ({ setComponent, registerAuth0 }) => {
	const dispatch = useDispatch();
	const [isAcceptChecked, setAcceptChecked] = useState(false);
	const [isPassChecked, setPassChecked] = useState(false);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [errorMsg, setErrorMsg] = useState({
		email: null,
		password: null,
		confirmPassword: null,
		name: null
	});

	const register = () => {
		const errorObj = {
			email: validators.required(email) || validators.email(email),
			password:
				validators.required(password) || validators.minLength(password, 8),
			confirmPassword:
				validators.required(confirmPassword) ||
				validators.match(confirmPassword, password, 'Password'),
			name: validators.required(name) || validators.minLength(name, 3)
		};
		setErrorMsg(errorObj);
		if (Object.values(errorObj).every((value) => !value)) {
			dispatch(registerAction({}));
			setComponent(2);
		}
	};
	return (
		<div className="register">
			<div className="upper-section">
				<h2 className="sfprodisplay-heavy-white-26px">Register with</h2>
				<div className="register-with-container">
					<div className="register-with-item" onClick={() => registerAuth0()}>
						<img src={google} />
						<p className="sfprodisplay-bold-white-18px">Google</p>
					</div>
					<div className="register-with-item" onClick={() => registerAuth0()}>
						<img src={github} />
						<p className="sfprodisplay-bold-white-18px">GitHub</p>
					</div>
					<div className="register-with-item" onClick={() => registerAuth0()}>
						<img src={gitlab} />
						<p className="sfprodisplay-bold-white-18px">GitLab</p>
					</div>
				</div>
			</div>
			<div className="lower-section">
				<h2 className="sfprodisplay-heavy-white-26px">
					Register with Email
				</h2>
				<div style={{ paddingLeft: 20 }}>
					<Input
						label="Full Name"
						placeholder="Full Name..."
						onChange={(e) => setName(e.target.value)}
						errorMsg={errorMsg.name}
					/>
					<Input
						label="Email"
						placeholder="Email..."
						onChange={(e) => setEmail(e.target.value)}
						errorMsg={errorMsg.email}
					/>
					<div className="show-pass">
						<div
							className="checkbox-container"
							onClick={() => setPassChecked(!isPassChecked)}
						>
							<p className="sfprodisplay-regular-white-10px">
								Show Password
							</p>
							<Checkbox checked={isPassChecked} />
						</div>
						<Input
							label="Password"
							placeholder="Password..."
							type={isPassChecked ? 'text' : 'password'}
							onChange={(e) => setPassword(e.target.value)}
							errorMsg={errorMsg.password}
						/>
					</div>

					<Input
						label="Confirm Password"
						placeholder="Confirm Password..."
						type={isPassChecked ? 'text' : 'password'}
						onChange={(e) => setConfirmPassword(e.target.value)}
						errorMsg={errorMsg.confirmPassword}
					/>
					<div
						className="accept-terms"
						onClick={() => setAcceptChecked(!isAcceptChecked)}
					>
						<p className="sfprodisplay-regular-white-10px">
							I have read and agreed to the <span>Terms & Conditions</span>
						</p>
						<Checkbox checked={isAcceptChecked} />
					</div>
				</div>
				<div className="btns-container">
					<button className="btn white-btn" onClick={() => setComponent(0)}>
						<img src={arrowLeft} style={{ left: 10 }} />
						<p className="hidden-sm">Back</p>
					</button>
					<button className="btn red-btn" onClick={() => register()}>
						<p>Register</p>
						<img src={arrowRight} style={{ right: 10 }} />
					</button>
				</div>
			</div>
		</div>
	);
};

export default Register;
