import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import {
	loginThirdPartyAction,
	publicAccessAction
} from 'store/actions/auth.action';
import './style.css';
import arrowRight from 'assets/common/arrow-right.svg';
import logoWord from 'assets/common/logo-word.svg';
import headerMobile from 'assets/common/header_mobile_icon.svg';
import { LogoSvg } from './LogoSvg';

const pages = [
	{
		name: 'Docs',
		link: 'https://mediamagic.dev/docs',
		newTab: true
	},
	{
		name: 'Explore AI',
		link: '/products'
	},
	{
		name: 'Pricing',
		link: '/pricing'
	},
	{
		name: 'Help',
		link: '/help'
	},
	// {
	// 	name: 'About Us',
	// 	link: '/coming-soon'
	// },
	{
		name: 'Contact Us',
		link: '/contact-us'
	}
];
const MainHeader = ({ prodVersion }) => {
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const { user, isAuthenticated, loginWithPopup, getAccessTokenSilently } =
		useAuth0();
	useEffect(() => {
		const getUserMetadata = async () => {
			try {
				const accessToken = await getAccessTokenSilently();
				const payload = {
					email: user.email,
					name: user.name,
					picture: user.picture,
					id: user.sub
				};

				dispatch(
					loginThirdPartyAction(payload, accessToken, () => {
						history.push('/app/media');
					})
				);
			} catch (e) {
				console.log(e.message);
			}
		};
		const session = localStorage.getItem('session');
		if (isAuthenticated && !session) {
			getUserMetadata();
		}
	}, [isAuthenticated]);

	return (
		<div className="marketingheader">
			<div className="item-container">
				<Link to="/">
					<div className="item">
						<div className="zoose">media</div>
						<LogoSvg />
					</div>
				</Link>

				{!prodVersion &&
					pages.map((item, i) => (
						<a
							key={i}
							href={item.link}
							className="hidden-sm"
							target={item.newTab ? '_blank' : '_self'}
							rel="noreferrer"
						>
							<div className="item  sfprodisplay-bold-white-18px">
								{item.name}
							</div>
						</a>
					))}
			</div>
			{!prodVersion && (
				<div className="hidden-sm">
					{!isAuthenticated && (
						<div
							className="headereditorbutton"
							onClick={() => {
								dispatch(publicAccessAction());
								history.push('/app/media');
							}}
						>
							<div className="try-editor">Try Editor</div>
						</div>
					)}
					<div
						className="headerloginbutton"
						onClick={() =>
							isAuthenticated ? history.push('/app/media') : loginWithPopup()
						}
					>
						<div className="login">
							{isAuthenticated ? 'Go to Dashboard' : 'Login'}
						</div>
						<img src={arrowRight} />
					</div>
				</div>
			)}
			{!prodVersion && (
				<div
					className="hidden-md"
					onClick={() => setDropdownVisible(!dropdownVisible)}
				>
					<img src={headerMobile} />
				</div>
			)}

			{dropdownVisible && (
				<div className="mobile-landing-menu">
					<div className="overlap-group2">
						<div className="overlap-group">
							<img className="ai" src={logoWord} />
							<div className="zoose nicomoji-regular-normal-white-20-3px">
								media
							</div>
						</div>

						<div
							className="closemodalbutton"
							onClick={() => setDropdownVisible(false)}
						>
							<div className="overlap-group1">
								<div className="rectangle-2285"></div>
								<div className="rectangle-2286"></div>
							</div>
						</div>
					</div>
					{pages.map((item, i) => (
						<Link key={i} to={item.link}>
							<div
								className="sfprodisplay-bold-white-18px"
								onClick={() => setDropdownVisible(false)}
							>
								{item.name}
							</div>
						</Link>
					))}
					<div className="rectangle-2371"></div>

					<div className="headerloginbutton" onClick={() => loginWithPopup()}>
						<div className="login">Login</div>
						<img className="line-34" src={arrowRight} />
					</div>

					<div className="group-8436">
						<div className="overlap-group-1">
							<Link to="/auth/register">
								<div className="place ">Register</div>
							</Link>
							<Link to="/auth/register">
								<div className="text-91  sfprodisplay-bold-white-12px">
									Dont have an account?
								</div>
							</Link>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default MainHeader;
