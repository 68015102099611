export default {
	input_image_file: 'Selected',
	makeup_file: 'Non Makeup Face',
	non_makeup_file: 'Makeup Face',
	image: 'Selected',
	input: 'Selected',
	input_file: 'Selected',
	style: 'Style input',
	input_cloth_file: 'Selected Top',
	source: 'Description of Selected',
	target: 'Description of Change',
	input_person_file: 'Person',
	// content_image_file: 'Selected',
	// style_image_file: 'Color Target',
	n_images: 'number of images',
	background: 'Selected',
	base: 'Skin Tone',
	eyes: 'Eyes',
	hair: 'Hair',
	mouth: 'Mouth',
	target_file: 'Color Target',
	dst_txt: 'Text to Change',
	src_txt: 'New Text',
	text_prompt: 'Prompting text'
};
