import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Filter } from 'components';
import { EditTableModal } from 'modals';
import { useMediaQuery } from 'react-responsive';
import './style.css';
import arrowRight from 'assets/common/arrow_right.svg';
import arrowLeft from 'assets/common/left_arrow_white.svg';
import edit from 'assets/common/edit.svg';
import filter from 'assets/common/filter.svg';

const Table = ({ headers, data, types, filters }) => {
	const isSmallScreen = useMediaQuery({ maxWidth: 800 });
	const [hoverFilter, setHoverFilter] = useState(null);
	const [selectedFilters, setSelectedFilters] = useState(types);
	const [actualData, setActualData] = useState(null);
	const [filterVisible, setFilterVisible] = useState(false);
	const [editPopupVisible, setEditPopupVisible] = useState(false);
	const [page, setPage] = useState(1);
	const [rowNo, setRowNo] = useState(10);
	const [paginationNo, setPaginationNo] = useState(
		Math.ceil(data.length / rowNo)
	);

	useEffect(() => {
		if (!actualData) setActualData(data);
		else setPaginationNo(Math.ceil(actualData.length / rowNo));
	}, [actualData, rowNo]);

	const emptyArray = Array.from(Array(paginationNo).keys());
	const filterTableAIType = (selected) => {
		let tempFilter = JSON.parse(JSON.stringify(selectedFilters));
		const index = tempFilter.indexOf(selected);
		if (index > -1) {
			tempFilter.splice(index, 1);
		} else {
			tempFilter.push(selected);
		}

		setSelectedFilters(tempFilter);

		// let tempData = [];
		// data.forEach((item) => {
		// 	if (tempFilter.some((r) => item.ai_used.indexOf(r) >= 0)) {
		// 		tempData.push(item);
		// 	}
		// });
		// setActualData(tempData);
	};

	const filterTableInfo = (obj) => {
		const { credit, from, to } = obj;

		let tempData = [];
		data.forEach((item) => {
			if (item.date > from && item.date < to && credit > item.credit_used) {
				tempData.push(item);
			}
		});
		setActualData(tempData);
	};

	const renderTableCell = () =>
		actualData &&
		actualData
			.slice((page - 1) * rowNo, (page - 1) * rowNo + rowNo)
			.map((item, i) => (
				<tr className="overlap-group1 row" key={`user-${i}`}>
					{Object.keys(item).map((key, index) => {
						if (isSmallScreen && [2, 3, 4].includes(index)) return;
						if (typeof item[key] !== 'object') {
							return (
								<td
									key={`${i}-val-${index}`}
									className={`${
										index === 0 ? 'number' : 'title'
									} sfprodisplay-regular-white-10px`}
								>
									{key === 'created_at'
										? moment(item[key]).format('MM/DD/YYYY hh:mm')
										: item[key] +
										  `${
												key === 'credit_used'
													? ' Credits'
													: key === 'time_elapsed'
													? ' secs'
													: ''
										  }
										`}
								</td>
							);
						} else {
							return (
								<td
									key={`${i}-val-${index}`}
									className={`${
										index === 0 ? 'number' : 'title'
									} sfprodisplay-regular-white-10px`}
								>
									{item[key].map((aiType, aiIndex) => (
										<div
											key={`${i}-aiType-${aiIndex}`}
											className="title sfprodisplay-regular-white-10px"
										>
											{aiType}
										</div>
									))}
								</td>
							);
						}
					})}

					<td>
						<button
							className="editbutton-1"
							onClick={() => setEditPopupVisible(i + 1)}
						>
							<span className="hidden-sm">Edit </span> <img src={edit} />
						</button>
						{editPopupVisible && editPopupVisible === i + 1 && (
							<EditTableModal onClick={() => setEditPopupVisible(false)} />
						)}
					</td>
				</tr>
			));

	const renderTableHead = () => {
		if (!isSmallScreen) {
			return headers.map((item, i) => (
				<th key={i} className="sfprodisplay-bold-white-12px">
					{item}
				</th>
			));
		} else {
			return headers.map(
				(item, i) =>
					![2, 3, 4].includes(i) && (
						<th key={i} className="sfprodisplay-bold-white-12px">
							{item}
						</th>
					)
			);
		}
	};
	return (
		<div className="table">
			<div className="overlap-group19">
				<div className="flex-row-1">
					<div className="flex-container" style={{ overflow: 'hidden' }}>
						<div className="filter-a-is">Filter AIs:</div>
						<div className="rectangle-2455"></div>
						<div className="left-container">
							{filters.map((item, i) => (
								<div
									className={'icon'}
									onMouseEnter={() => (!isSmallScreen ? setHoverFilter(i) : {})}
									onMouseLeave={() =>
										!isSmallScreen ? setHoverFilter(null) : {}
									}
									onClick={() => filterTableAIType(item.name)}
									key={i}
								>
									<img
										src={item.color_icon}
										style={{
											opacity: selectedFilters.includes(item.name) ? 1 : 0.5
										}}
									/>
									{!isSmallScreen && hoverFilter === i && (
										<div className="hover-popup">{item.name}</div>
									)}
								</div>
							))}
						</div>
						<div className="rectangle-2454"></div>
					</div>
					<div className="right-container">
						<div
							className="main-button"
							onClick={() => setFilterVisible(!filterVisible)}
						>
							<div className="btn-text">Filters</div>
							<img className="group-3" src={filter} />
						</div>
						{filterVisible && (
							<Filter
								onFilter={(obj) => {
									filterTableInfo(obj);
									setFilterVisible(!filterVisible);
								}}
								onClose={() => setFilterVisible(!filterVisible)}
							/>
						)}
						<div
							className="pagination-content hidden-sm"
							style={{ marginRight: 30, width: 120, marginLeft: 20 }}
						>
							{[50, 25, 10].map((item, i) => (
								<div
									key={i}
									onClick={() => {
										setPage(1);
										setRowNo(item);
									}}
									className={`${
										rowNo === item ? 'active-page' : ''
									} pagination-number`}
									style={{ borderRadius: 12 }}
								>
									{item}
								</div>
							))}
						</div>
					</div>
				</div>

				<table className="flex-col-1">
					<thead>
						<tr className="overlap-group1" style={{ height: 40 }}>
							{renderTableHead()}
						</tr>
					</thead>
					<tbody>
						<tr style={{ height: 1 }} />
						{renderTableCell()}
					</tbody>
				</table>
			</div>
			{actualData && (
				<div className="pagination">
					<div className="pagination-content">
						<div
							className="main-button"
							onClick={() => (page !== 1 ? setPage(page - 1) : {})}
							style={{ marginRight: 10, opacity: page !== 1 ? 1 : 0.4 }}
						>
							<img className="group-3" src={arrowLeft} />
							<div className="btn-text hidden-sm">Back</div>
						</div>
						{(page > 5
							? emptyArray.slice(page - 5, page + 5)
							: emptyArray.slice(0, 10)
						).map((item, i) => (
							<div
								key={i}
								onClick={() => setPage(item + 1)}
								className={`${
									page - 1 === item ? 'active-page' : ''
								} pagination-number`}
							>
								{item + 1}
							</div>
						))}

						<div
							className="main-button"
							onClick={() => (page !== paginationNo ? setPage(page + 1) : {})}
							style={{ opacity: page !== paginationNo ? 1 : 0.5 }}
						>
							<div className="btn-text hidden-sm">Next</div>
							<img className="group-3" src={arrowRight} />
						</div>
					</div>
					<div className="title-7">Page {page}</div>
					<br />
				</div>
			)}
		</div>
	);
};

export default Table;
