import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { validators } from 'helper/validator';
import { createTokenAction, getTokensAction } from 'store/actions/auth.action';
import { teamSampleData, paymentSampleInfo } from './sample-data';
import { Input, MessagePopup, ConfirmationPopup } from 'components';
import InviteMemberModal from './components/InviteMemberModal/InviteMemberModal';
import AddPaymentModal from './components/AddPaymentModal/AddPaymentModal';
import './style.css';
import info from 'assets/common/info.svg';
import trash from 'assets/common/trash.svg';
import camera from 'assets/common/camera.svg';
import pen from 'assets/common/pen.svg';
import arrowRight from 'assets/common/arrow-right.svg';
import arrowUp from 'assets/common/arrow-up.svg';

const AccountSettings = () => {
	const { pathname, hash } = useLocation();
	const { user } = useAuth0();
	const fileInputRef = useRef();
	const [messageContent, setMessageContent] = useState('');
	const [confirmationContent, setConfirmationContent] = useState('');
	const [inviteModalVisible, setInviteModalVisible] = useState(false);

	const [name, setName] = useState(user?.name);
	const [email, setEmail] = useState(user?.email);
	const [photoName, setPhotoName] = useState('me');
	const [photoSrc, setPhotoSrc] = useState(user?.picture);
	const [currentPass, setCurrentPass] = useState('');
	const [newPass, setNewPass] = useState('');
	const [confirmNewPass, setConfirmNewPass] = useState('');
	const [personalErrorMsg, setPersonalErrorMsg] = useState({
		name: null,
		email: null,
		currentPass: null,
		newPass: null,
		confirmNewPass: null
	});

	const [teamData, setTeamData] = useState(null);
	const [editTeamMember, setEditTeamMember] = useState(null);

	const [selectedDeletedIndex, setSelectedDeletedIndex] = useState(null);
	const [paymentInfo, setPaymentInfo] = useState(null);
	const [editPaymentInfo, setEditPaymentInfo] = useState(null);
	const [addPaymentModalVisible, setAddPaymentModalVisible] = useState(false);
	const [upgradeVisible, setUpgradeVisible] = useState(true);

	const [secretKeys, setSecretKeys] = useState(null);
	const [restrictedKeys, setRestrictedKeys] = useState(null);

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);

	useEffect(() => {
		if (hash !== '') {
			const id = hash.replace('#', '');
			const element = document.getElementById(id);
			if (element) {
				element.scrollIntoView();
			}
		}
	}, [pathname, hash]);

	useEffect(() => {
		if (!teamData) setTeamData(teamSampleData);
		if (!paymentInfo) setPaymentInfo(paymentSampleInfo);
		if (!secretKeys) getKeys();
	}, [teamData, paymentInfo, secretKeys]);

	const revealKey = (i, restricted = false) => {
		let tempData = JSON.parse(
			JSON.stringify(restricted ? restrictedKeys : secretKeys)
		);
		tempData[i].hiddenToken = !tempData[i].hiddenToken;
		if (restricted) setRestrictedKeys(tempData);
		else setSecretKeys(tempData);
	};

	const getKeys = (type) => {
		getTokensAction((res) => {
			res.map((item) => (item.hiddenToken = 'hidden'));
			if (type !== 'Restricted')
				setSecretKeys(res.filter((item) => item.name === 'Secret'));
			if (type !== 'Secret')
				setRestrictedKeys(res.filter((item) => item.name === 'Restricted'));
		});
	};

	const createKey = (type) => {
		createTokenAction({ name: type }, () => {
			getKeys(type);
		});
	};

	const savePersonalInfo = () => {
		const errorObj = {
			email: validators.required(email) || validators.email(email),
			currentPass: validators.required(currentPass),
			newPass: validators.minLengthOptional(newPass, 8),
			confirmNewPass: validators.match(confirmNewPass, newPass, 'Password'),
			name: validators.required(name) || validators.minLength(name, 3)
		};
		setPersonalErrorMsg(errorObj);
		if (Object.values(errorObj).every((value) => !value)) {
			setConfirmationContent('SAVE CHANGES?');
		}
	};

	const uploadFile = () => {
		const file = fileInputRef.current.files[0];
		setPhotoName(file.name);
		setPhotoSrc(URL.createObjectURL(file));
	};

	const inviteMember = ({ name, email, role, index }) => {
		setInviteModalVisible(false);

		let tempTeamData = JSON.parse(JSON.stringify(teamData));
		if (index !== null) {
			setEditTeamMember(null);
			tempTeamData[index] = { name, email, role };
		} else tempTeamData.push({ name, email, role });
		setTeamData(tempTeamData);
	};

	const editMember = (obj, index) => {
		setEditTeamMember({ ...obj, index });
		setInviteModalVisible(true);
	};

	const addPayment = ({ cardNo, cvv, expireDate, index }) => {
		setAddPaymentModalVisible(false);

		let tempPaymentInfo = JSON.parse(JSON.stringify(paymentInfo));
		if (index !== null) {
			setEditPaymentInfo(null);
			tempPaymentInfo[index] = { cardNo, cvv, expireDate };
		} else tempPaymentInfo.push({ cardNo, cvv, expireDate });
		setPaymentInfo(tempPaymentInfo);
	};

	const deletePayment = (index) => {
		if (!confirmationContent) {
			setSelectedDeletedIndex(index);
			return setConfirmationContent('DELETE CARD?');
		}
		let tempPaymentInfo = JSON.parse(JSON.stringify(paymentInfo));
		tempPaymentInfo.splice(index, 1);
		setPaymentInfo(tempPaymentInfo);
		setConfirmationContent(null);
	};
	return (
		<div className="settings">
			{messageContent && (
				<MessagePopup
					content={messageContent}
					onClick={() => setMessageContent(null)}
				/>
			)}
			{confirmationContent && (
				<ConfirmationPopup
					content={confirmationContent}
					onCancel={() => setConfirmationContent(null)}
					onClick={() =>
						confirmationContent === 'DELETE CARD?'
							? deletePayment(selectedDeletedIndex)
							: setConfirmationContent(null)
					}
				/>
			)}
			{inviteModalVisible && (
				<InviteMemberModal
					cancel={() => setInviteModalVisible(false)}
					invite={(data) => inviteMember(data)}
					editTeamMember={editTeamMember}
				/>
			)}
			{addPaymentModalVisible && (
				<AddPaymentModal
					cancel={() => setAddPaymentModalVisible(false)}
					add={(data) => addPayment(data)}
					editPayment={editPaymentInfo}
				/>
			)}
			<div className="hidden-md">
				{upgradeVisible && (
					<div className="group-46940">
						<div
							className="cross-icon"
							onClick={() => setUpgradeVisible(false)}
						>
							<div />
							<div />
						</div>
						<div
							className="sfprodisplay-medium-white-14px"
							style={{ color: '#000' }}
						>
							Your billing plan is
							<span style={{ color: '#000' }}> Free.</span>
							<br />
							Upgrade your plan to use full powers of MediaMagic!
						</div>

						<div className="group-47039">
							<div className="group-47038">
								<div className="upgrade-now sfprodisplay-medium-white-18px">
									Upgrade Now{' '}
								</div>
								<img className="arrow-up" src={arrowUp} />
							</div>
						</div>
					</div>
				)}
			</div>
			<div className="section-container">
				<div className="group-46940 hidden-sm">
					<div className="flex-col">
						<h1 className="title">Account Settings</h1>
						<div className="text-1-1 sfprodisplay-medium-white-18px">
							Your billing plan is Free. <br />
							Upgrade your plan to use full powers of MediaMagic!
						</div>
					</div>
					<div className="group-47039">
						<div className="group-47038">
							<img className="arrow-up" src={arrowUp} />
							<div className="upgrade-now sfprodisplay-medium-white-18px">
								Upgrade Now
							</div>
						</div>
					</div>
				</div>
				<div className="group-46939">
					<div className="personal">Personal</div>
					<div className="flex-container">
						<div className="group-8321">
							<Input
								placeholder="Jonathan Thomas Johnson"
								onChange={(e) => setName(e.target.value)}
								value={name}
								label="Full Name"
								errorMsg={personalErrorMsg.name}
							/>
						</div>
						<div className="group-8321">
							<Input
								label="Email"
								onChange={(e) => setEmail(e.target.value)}
								value={email}
								placeholder="tayawg67@gmail.com"
								errorMsg={personalErrorMsg.email}
							/>
						</div>
					</div>
					<div className="flex-container">
						<div className="group-8321">
							<div className="full-name inter-black-white-18px">
								Profile Photo
							</div>
							<div className="formsinputdefault-copy-7">
								<div className="overlap-group">
									<img className="rectangle-1024" src={photoSrc} />
									<div>{photoName || 'No file chosen'}</div>
									<input
										type="file"
										ref={fileInputRef}
										accept="image/*"
										style={{ display: 'none' }}
										onChange={uploadFile}
									/>
									<label
										htmlFor="upload"
										className="group-1911"
										onClick={() => fileInputRef.current.click()}
									>
										<img className="title-1" src={camera} />
									</label>
								</div>
							</div>
						</div>
						<div className="group-8321">
							<Input
								label="Current Password"
								placeholder="current password..."
								value={currentPass}
								onChange={(e) => setCurrentPass(e.target.value)}
								type="password"
								errorMsg={personalErrorMsg.currentPass}
							/>
						</div>
					</div>
					<div className="flex-container">
						<div className="group-8321">
							<Input
								label="New Password"
								onChange={(e) => setNewPass(e.target.value)}
								value={newPass}
								placeholder="new password..."
								type="password"
								errorMsg={personalErrorMsg.newPass}
							/>
						</div>
						<div className="group-8321">
							<Input
								label="Confirm New Password"
								value={confirmNewPass}
								onChange={(e) => setConfirmNewPass(e.target.value)}
								placeholder="confirm new password..."
								type="password"
								errorMsg={personalErrorMsg.confirmNewPass}
							/>
						</div>
					</div>
					<div className="savebutton">
						<div className="overlap-group2" onClick={savePersonalInfo}>
							<div className="save  sfprodisplay-bold-white-24px">Save</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section-container" id="api-keys">
				<div className="flex-col">
					<div className="flex-container">
						<h1 className="title">API Keys</h1>
						<p className="text-4 sfprodisplay-bold-white-16px hidden-sm">
							Learn more about API authentication
							<img src={arrowRight} />
						</p>
					</div>
					<div
						className="flex-container  hidden-sm"
						style={{ marginBlock: 40 }}
					>
						<p className="text-4 sfprodisplay-bold-white-16px">
							<img className="info-icon" src={info} />
							Viewing live API keys. Toggle to view test keys.
						</p>
						<p className="text-4 sfprodisplay-bold-white-16px">
							View test data
						</p>
					</div>
					<div className="flex-container">
						<div className="flex-col">
							<div className="standard-keys">Standard Keys</div>
							<div className="text-6">
								These keys will allow you to aunthentic API Requests.
							</div>
						</div>
						<div className="group-8146" onClick={() => createKey('Secret')}>
							<div className="frame-8154">
								<div className="overlap-group-1">
									<div className="rectangle-2306"></div>
									<div className="rectangle-2307"></div>
								</div>
							</div>
							<div className="create-secret-key sfprodisplay-bold-white-16px">
								Create secret key
							</div>
						</div>
					</div>
				</div>
				<table className="api-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Token</th>
							<th>Last Used</th>
							<th>Create</th>
						</tr>
					</thead>
					<tbody>
						{secretKeys?.map((item, i) => (
							<tr key={i}>
								<td>{`${item.name} Key`}</td>
								<td>
									{item.hiddenToken && (
										<button
											className="reveal-key sfprodisplay-bold-white-16px"
											onClick={() => revealKey(i)}
											style={{
												display: item.hiddenToken ? 'block' : 'none'
											}}
										>
											Reveal Key
										</button>
									)}
									<div className={!item.hiddenToken ? '' : 'frame-8153'}>
										{item.token}
									</div>
								</td>
								<td>Jun 4</td>
								<td>
									<img className="info-icon hidden-sm" src={info} />
									May 26, 2020
								</td>
							</tr>
						))}
					</tbody>
				</table>

				<div className="flex-container restrict-container">
					<div className="flex-col">
						<div className="standard-keys">Restricted Keys</div>
						<div className="text-10 hidden-sm">
							For greeater security you can create restricted API keys that
							limit access and permissions for different areas of your account
							data.
						</div>
					</div>
					<div className="group-8146" onClick={() => createKey('Restricted')}>
						<div className="frame-8162">
							<div className="frame-8154">
								<div className="overlap-group-1">
									<div className="rectangle-2306"></div>
									<div className="rectangle-2307"></div>
								</div>
							</div>
							<div className="create-secret-key sfprodisplay-bold-white-16px">
								Create restricted key
							</div>
						</div>
					</div>
				</div>
				<table className="api-table">
					<thead>
						<tr>
							<th>NAME</th>
							<th>TOKEN</th>
							<th>LAST USED</th>
							<th>CREATED</th>
						</tr>
					</thead>
					<tbody>
						{restrictedKeys?.map((item, i) => (
							<tr key={i}>
								<td>{`${item.name} Key`}</td>
								<td>
									{item.hiddenToken && (
										<button
											className="reveal-key sfprodisplay-bold-white-16px"
											onClick={() => revealKey(i, true)}
											style={{
												display: item.hiddenToken ? 'block' : 'none'
											}}
										>
											Reveal Key
										</button>
									)}
									<div className={!item.hiddenToken ? '' : 'frame-8153'}>
										{item.token}
									</div>
								</td>
								<td>Jun 4</td>
								<td>
									<img className="info-icon hidden-sm" src={info} />
									May 26, 2020
								</td>
							</tr>
						))}
						{(!restrictedKeys || restrictedKeys?.length == 0) && (
							<tr>
								<td>No restricted Keys</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			<div className="section-container" id="team-members">
				<div className="flex-col">
					<div className="flex-container">
						<h1 className="title">Team Members</h1>
					</div>
				</div>
				<table className="api-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Email</th>
							<th>Role</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{teamData &&
							teamData.map((item, i) => (
								<tr key={i}>
									<td>{item.name}</td>
									<td>{item.email}</td>
									<td>{item.role}</td>
									<td className="pointer" onClick={() => editMember(item, i)}>
										<img className="pen-icon" src={pen} />
										Edit
									</td>
								</tr>
							))}
					</tbody>
				</table>

				<button
					className="invitebutton sfprodisplay-regular-white-16px"
					onClick={() => {
						if (editTeamMember) setEditTeamMember(null);
						setInviteModalVisible(!inviteModalVisible);
					}}
				>
					Invite member
				</button>
			</div>
			<div className="section-container" id="billing">
				<div className="flex-col">
					<div className="flex-container">
						<h1 className="title">Billing</h1>
					</div>
				</div>
				<div className="billing-container">
					<div className="billing">
						<h1>29</h1>
						<p>Jobs this month</p>
						<button>See Jobs 28</button>
					</div>
					<div className="billing">
						<h1>$1,860</h1>
						<p>Outcomes</p>
						<button>Invoices</button>
					</div>
					<div className="billing">
						<h1>$2,000</h1>
						<p>Limit</p>
						<button>Change limit</button>
					</div>
					<div className="billing">
						<h1>$182,018</h1>
						<p>Current balance</p>
						<button>Add funds</button>
					</div>
				</div>
			</div>
			<div className="section-container">
				<div className="flex-col">
					<div className="flex-container">
						<h1 className="title">Payment methods</h1>
					</div>
				</div>
				<table className="api-table">
					<thead>
						<tr>
							<th>Default</th>
							<th>Name</th>
							<th>Type</th>
							<th>Number</th>
							<th>Expires</th>
							<th>Delete</th>
						</tr>
					</thead>
					<tbody>
						{paymentInfo &&
							paymentInfo.map((item, i) => (
								<tr
									key={i}
									onClick={() => setSelectedPaymentMethod(i)}
									style={{ cursor: 'pointer' }}
								>
									<td>
										<div
											className={`empty_circle ${
												selectedPaymentMethod === i ? 'filled_circle' : ''
											}`}
										></div>
									</td>
									<td>{'Drake'}</td>
									<td>{'Visa'}</td>
									<td>{item.cardNo}</td>
									<td>{item.expireDate}</td>
									<td>
										<img
											className="pen-icon"
											src={trash}
											onClick={() => deletePayment(i)}
										/>
									</td>
								</tr>
							))}
					</tbody>
				</table>
				<div className="savebutton payment-btn">
					<div
						className="overlap-group2"
						onClick={() => setAddPaymentModalVisible(!addPaymentModalVisible)}
					>
						<div className="save sfprodisplay-regular-white-16px">
							Add payment method
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AccountSettings;
