import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { mobileDatesFilter } from 'helper/constants';
import './style.css';
import SummeryContainer from '../SummeryContainer/SummeryContainer';
import flag from 'assets/common/flag.svg';
import statistics from 'assets/common/statistics.svg';
import arrowRight from 'assets/common/arrow_right.svg';
import hide from 'assets/common/hide.svg';
import dash4 from 'assets/dashboard/sample-data/dash4.png';
import redline from 'assets/dashboard/sample-data/redline-sample.png';
import greenline from 'assets/dashboard/sample-data/greenline-sample.png';
import { useDispatch, useSelector } from 'react-redux';
import { getJobsDataAction } from 'store/actions/jobs.action';
import { getMediaDataAction } from 'store/actions/media.action';
import { getUsersDataAction } from 'store/actions/users.action';
const DashboardRightTab = ({ onClick, selectedDashboard }) => {
	const dispatch = useDispatch();
	const jobsDone = useSelector((state) => state.jobs.completedJobsLength);
	const uploadedMedia = useSelector((state) => state.media.length);
	const processedMedia = useSelector((state) => state.users.length);

	useEffect(() => {
		if (!jobsDone) dispatch(getJobsDataAction());
		if (!uploadedMedia) dispatch(getMediaDataAction());
		if (!processedMedia) dispatch(getUsersDataAction());
	}, []);

	const [filterDate, setFilterDate] = useState(5);
	const [revealKey, setRevealKey] = useState(false);
	return (
		<div className="dashboard-right-tab">
			<div className="header-container">
				<div className="overlap-group1">
					<div
						className={`group-46887 ${
							selectedDashboard === 1 ? 'selected-section' : ''
						}`}
						onClick={() => onClick(1)}
					>
						<img className="frame-4687" src={statistics} />
						<div className="sfprodisplay-regular-white-12px">Performance</div>
					</div>
					<div
						className={`group-46887 ${
							selectedDashboard === 0 ? 'selected-section' : ''
						}`}
						onClick={() => onClick(0)}
					>
						<img className="frame-4687" src={flag} />
						<div className="sfprodisplay-regular-white-12px">
							Getting Started
						</div>
					</div>
				</div>
				<div className="hidden-md filter-dates">
					{mobileDatesFilter.map((item, i) => (
						<div
							key={i}
							style={{ opacity: filterDate === item.key ? 1 : 0.6 }}
							onClick={() => setFilterDate(item.key)}
						>
							{item.value}
						</div>
					))}
				</div>
			</div>
			<div className="group-46935">
				<div className="overlap-group-10 hidden-sm">
					<div className="what-to-do-with-a-fu">Getting Started</div>
					<div className="dashboard-beta-5">Your API Keys</div>
					<div className="flex-row-11">
						<div className="sfprodisplay-bold-white-10px">Publishable key:</div>
						<div
							className="sfprodisplay-bold-white-10px"
							style={{ marginLeft: 5 }}
						>
							absndjfj_skdxjsJshd39
						</div>
					</div>
					<div className="flex-row-12">
						<div className="sfprodisplay-bold-white-10px">Secret key:</div>
						<div className="overlap-group2">
							<div
								className="xxxxxxxxxxxxxxxxxx"
								style={{ filter: !revealKey ? 'blur(2px)' : 'blur(0px)' }}
							>
								xxxxxxxxxxxxxxxxxx
								{revealKey && (
									<img
										src={hide}
										style={{ marginLeft: 5, cursor: 'pointer', width: '0.5vw' }}
										onClick={() => setRevealKey(false)}
									/>
								)}
							</div>
							{!revealKey && <div className="rectangle-2387"></div>}
							<div
								className="group-8154"
								style={{ display: revealKey ? 'none' : 'flex' }}
								onClick={() => setRevealKey(true)}
							>
								Reveal Key
							</div>
						</div>
					</div>
					<div className="now-trending sfprodisplay-regular-white-10px">
						Now trending topics
					</div>
					<div className="flex-row-13">
						<img className="img" src={dash4} />
						<div className="overlap-group3">
							<p className="sfprodisplay-regular-white-10px">
								One Day, a Machine Will
							</p>
							<div className="trends-con">
								<div className="tech sfprodisplay-regular-white-10px">
									#Tech
								</div>
								<div className="tech sfprodisplay-regular-white-10px">
									1.9k posts
								</div>
							</div>
						</div>
					</div>
					<div className="flex-row-13">
						<img className="img" src={dash4} />
						<div className="overlap-group3">
							<p className="sfprodisplay-regular-white-10px">
								Another Windows 10 Update
							</p>
							<div className="trends-con">
								<div className="tech sfprodisplay-regular-white-10px">
									#Windows
								</div>
								<div className="tech sfprodisplay-regular-white-10px">
									1.4k posts
								</div>
							</div>
						</div>
					</div>
				</div>
				<Link to="/coming-soon">
					<div className="group-8570">
						<div className="group-7750">
							<div className="frame-8342-1"></div>
							<div className="group-7767">
								<div
									className="sfprodisplay-regular-white-10px"
									style={{ marginBottom: 3 }}
								>
									Documentation
								</div>
								<div className="sfprodisplay-regular-white-10px">
									Api Resources
								</div>
							</div>
						</div>
						<img className="group-3" src={arrowRight} />
					</div>
				</Link>
				<SummeryContainer
					header="Media Uploaded"
					cost={uploadedMedia}
					increase
					image={redline}
				/>
				<SummeryContainer
					header="Jobs Done"
					cost={jobsDone}
					increase
					image={redline}
				/>
				<SummeryContainer
					header="Media Processed"
					cost={processedMedia}
					image={greenline}
				/>
			</div>
		</div>
	);
};

export default DashboardRightTab;
