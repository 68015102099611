import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateSearchAction } from 'store/actions/header.action';
import { useAuth0 } from '@auth0/auth0-react';
import { loginThirdPartyAction } from 'store/actions/auth.action';
import { DragDropModal, MediaTaggingModal } from 'modals';
import AnimatedNavbar from 'components/AnimatedNavbar/AnimatedNavbar';
import SettingDropdown from './components/SettingDropdown/SettingDropdown';
import DocsDropdown from './components/DocsDropdown/DocsDropdown';
import './style.css';
import logoWord from 'assets/common/logo-word.svg';
import headerMobile from 'assets/common/header_mobile_icon.svg';
import arrowDown from 'assets/common/arrow_down.svg';
import create from 'assets/common/create_icon.svg';
import docHelp from 'assets/common/docs_help_icon.svg';
import TryOnModal from 'modals/TryOnModal/TryOnModal';
import { FaSpinner } from 'react-icons/fa';
import { BiSearch } from 'react-icons/bi';

const AppHeader = () => {
	const searchRef = useRef();
	const buttonRef = useRef();
	const inputRef = useRef();
	const dispatch = useDispatch();
	const { user, isAuthenticated, loginWithPopup, getAccessTokenSilently } =
		useAuth0();
	const { pathname } = useLocation();

	const [settingVisible, setSettingVisible] = useState(false);
	const [docsVisible, setDocsVisible] = useState(false);
	const [dragDropVisible, setDragDropVisible] = useState(false);
	const [tryOnVisible, setTryOnVisible] = useState(false);
	const [isPostUpload, setPostUpload] = useState(false);
	const [searchInput, setInput] = useState('');

	useEffect(() => {
		const getUserMetadata = async () => {
			try {
				const accessToken = await getAccessTokenSilently();
				const payload = {
					email: user.email,
					name: user.name,
					picture: user.picture,
					id: user.sub
				};

				dispatch(
					loginThirdPartyAction(payload, accessToken, () => {
						history.push('/app/media');
					})
				);
			} catch (e) {
				console.log(e.message);
			}
		};
		const session = localStorage.getItem('session');
		if (isAuthenticated && !session) {
			getUserMetadata();
		}
	}, [isAuthenticated]);
	let timeout;

	useEffect(() => {
		dispatch(updateSearchAction(null, true));
	}, [pathname]);

	function loading() {
		searchRef.current.classList.add('loading');
		setTimeout(function () {
			searchRef.current.classList.remove('loading');
		}, 1500);
	}

	const handleSpinning = (e) => {
		setInput((prev) => ({
			...prev,
			input: e.target.value
		}));
		handleSearch(e.target.value);
	};

	const handleSearch = (value) => {
		loading();
		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(() => {
			dispatch(updateSearchAction(value || searchInput.input));
		}, 1000);
	};

	return (
		<div>
			{dragDropVisible && (
				<DragDropModal
					onClose={() => setDragDropVisible(false)}
					afterUploadCompleted={(files) => {
						setPostUpload(JSON.parse(JSON.stringify(files)));
						setDragDropVisible(false);
					}}
				/>
			)}
			{tryOnVisible && <TryOnModal onClose={() => setTryOnVisible(false)} />}
			{isPostUpload && (
				<MediaTaggingModal
					onClose={() => setPostUpload(false)}
					files={isPostUpload}
				/>
			)}
			<div className="header">
				<Link to="/app/media">
					<div className="logo-container hidden-sm">
						<h1 className="title">media</h1>
						<img src={logoWord} />
					</div>
				</Link>
				{/* <div onClick={() => setTryOnVisible(true)} className="upload-container">
					<div className="sfprodisplay-bold-white-14px">
						Try Clothes on Virtual room
					</div>
				</div> */}

				<div className="search" ref={searchRef}>
					<input
						id="input"
						placeholder="Search..."
						onChange={(e) => handleSpinning(e)}
						ref={inputRef}
					/>
					<button id="button" ref={buttonRef}>
						<BiSearch className="fa fa-search" onClick={() => handleSearch()} />
					</button>
					<div className="spinner">
						<FaSpinner className="spinner-style" />
					</div>
				</div>

				{isAuthenticated ? (
					<>
						<div className="upload-wrapper">
							<div className="file-upload">
								<input onClick={() => setDragDropVisible(true)} />
								<i className="fa fa-arrow-up"></i>
							</div>
						</div>
						<AnimatedNavbar
							duration={300}
							navbarConfig={[
								{
									item: (
										<div className="group-8318">
											<img className="group-7955" src={docHelp} />
											<div className="dashboard-alpha-1">DOCS + HELP</div>
											<img className="rectangle-20-3" src={arrowDown} />
										</div>
									),
									dropdown: <DocsDropdown onClick={() => {}} desktop />
								},
								{
									item: (
										<div className="creeatebutton">
											<div className="overlap-group1-2">
												<img className="group-8052" src={create} />
												<div className="create">Create</div>
											</div>
										</div>
									),
									dropdown: (
										<DocsDropdown
											onClick={() => {}}
											create
											desktop
											setDragDropVisible={setDragDropVisible}
										/>
									)
								},
								{
									item: (
										<div className="group-8201">
											<img className="rectangle-21" src={arrowDown} />
											<div className="group-7481">
												<img className="rectangle-1024" src={user?.picture} />
											</div>
										</div>
									),
									containerStyle: { marginRight: '13vw' },
									caretStyle: { marginRight: '-13vw' },
									dropdown: <SettingDropdown onClick={() => {}} desktop />
								}
							]}
						/>
					</>
				) : (
					<div className="headerloginbutton" onClick={() => loginWithPopup()}>
						<div className="login">Login</div>
					</div>
				)}
			</div>
			<div className="header-mobile">
				{docsVisible && (
					<DocsDropdown onClick={() => setDocsVisible(!docsVisible)} />
				)}

				<div className="group-46953">
					<img
						className="frame-8646"
						src={headerMobile}
						onClick={() => setDocsVisible(!docsVisible)}
					/>
					<Link to="/app/media" className="flex-container">
						<div className="sfprodisplay-heavy-white-32px">media</div>
						<img className="ai" src={logoWord} />
					</Link>
				</div>
				<div
					className="group-8201"
					onClick={() => setSettingVisible(!settingVisible)}
				>
					<img className="rectangle-21" src={arrowDown} />
					<div className="group-7481">
						<img className="rectangle-1024" src={user?.picture} />
					</div>
					{settingVisible && (
						<SettingDropdown
							onClick={() => setSettingVisible(!settingVisible)}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default AppHeader;
