import React from 'react';
import { useDisableBodyScroll } from 'hooks/DisableBodyScroll';
import { Editor } from 'pages';
import './style.css';
import close from 'assets/common/close.svg';

const EditorModal = ({
	selectedMedia,
	tags,
	onClose,
	goNext,
	goBack,
	imgLoading,
	selectedMediaIndex,
	mediaSelectedAI
}) => {
	useDisableBodyScroll();

	return (
		<>
			<div className="low-opacity" />
			<div className="editor-modal animate-appear modal-background-blur">
				<div className="closemodalbutton" onClick={onClose}>
					<img src={close} />
				</div>
				<Editor
					selectedMedia={selectedMedia}
					selectedMediaIndex={selectedMediaIndex}
					mediaSelectedAI={mediaSelectedAI}
					tags={tags}
					goNext={goNext}
					goBack={goBack}
					imgLoading={imgLoading}
				/>
			</div>
		</>
	);
};

export default EditorModal;
