import React, { useState } from 'react';
import './style.css';
import jsIcon from 'assets/common/js-icon.svg';
import jsText from 'assets/common/js-text.svg';
import go from 'assets/common/go.svg';
import ruby from 'assets/common/ruby.svg';
import php from 'assets/common/php.svg';
import python from 'assets/common/python.svg';
import arrowRight from 'assets/common/arrow_right.svg';
const SelectLang = ({ onClick }) => {
    const [langObj, setLangObj] = useState({
        javascript: false,
        php: false,
        go: false,
        python: false,
        ruby: false,
        others: false
    });

    const selectCode = lang => {
        let tempObj = JSON.parse(JSON.stringify(langObj));
        tempObj[lang] = !tempObj[lang];

        setLangObj(tempObj);
    };
    return (
        <div className="select-lang">
            <div className="overlap-group2-lang modal-background">
                <div className="flex-col">
                    <div className="sfprodisplay-bold-white-32px good-choice">Whats your language?</div>
                    <div className="sfprodisplay-bold-white-16px">select a language(s)</div>
                    <div className="flex-row">
                        <div
                            className={`buttonoff ${langObj.javascript ? 'selected-lang' : ''}`}
                            onClick={() => selectCode('javascript')}
                        >
                            <img src={jsIcon} />
                            <img src={jsText} />
                        </div>
                        <div
                            className={`buttonoff ${langObj.php ? 'selected-lang' : ''}`}
                            onClick={() => selectCode('php')}
                        >
                            <img src={php} />
                        </div>
                        <div
                            className={`buttonoff ${langObj.go ? 'selected-lang' : ''}`}
                            onClick={() => selectCode('go')}
                        >
                            <img src={go} />
                        </div>

                        <div
                            className={`buttonoff ${langObj.python ? 'selected-lang' : ''}`}
                            onClick={() => selectCode('python')}
                        >
                            <img src={python} />
                        </div>
                        <div
                            className={`buttonoff ${langObj.ruby ? 'selected-lang' : ''}`}
                            onClick={() => selectCode('ruby')}
                        >
                            <div className="frame-47015">
                                <img className="group-8035-1" src={ruby} />
                                <div className="image-236">Ruby</div>
                            </div>
                        </div>
                        <div
                            className={`buttonoff ${langObj.others ? 'selected-lang' : ''}`}
                            onClick={() => selectCode('others')}
                        >
                            <div className="sfprodisplay-bold-white-16px">other language</div>
                        </div>
                    </div>

                    <div
                        className="confirmbutton"
                        onClick={() => onClick(Object.values(langObj).map(item => item === true))}
                    >
                        <div className="overlap-group">
                            <div className="sfprodisplay-heavy-white-16px">Lets Go</div>
                            <img src={arrowRight} />
                        </div>
                    </div>
                    <div className="skip-this-step" onClick={() => onClick(null)}>
						Skip this step
                    </div>
                </div>

                <div className="closemodalbutton" onClick={() => onClick(null)}>
                    <div className="overlap-group1-lang">
                        <div className="rectangle-2285"></div>
                        <div className="rectangle-2286"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectLang;
