import { SET_HEADER_SEARCH, CLEAR_HEADER_SEARCH } from '../types/header.type';

const intialState = {
	search: '',
	typing: false,
	clear: false
};

const headerReducer = (state = intialState, action) => {
	switch (action.type) {
		case SET_HEADER_SEARCH:
			return { ...state, search: action.payload, clear: false };

		case CLEAR_HEADER_SEARCH:
			return { ...state, search: null, clear: true };
		default:
			return state;
	}
};

export default headerReducer;
