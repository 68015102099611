import React, { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { ProgressBar, Switcher, Input, Dropdown } from 'components';
import { CommandModal } from 'modals';
import { createDeploymentAction } from 'store/actions/users.action';
import SelectMediaModal from '../SelectMediaModal/SelectMediaModal';
import modelArgLabel from './modelArgLabel';
import './style.css';
import arrowRight from 'assets/common/arrow-right.svg';
import { useDispatch } from 'react-redux';
import { notifyToast } from 'helper/utils';

const AdjustSetting = ({
	selectedAI,
	undoSelectedAI,
	activeMedia,
	mediaList,
	runDeployment,
	setFontWidth,
	fontWidth,
	enableSketch,
	setEnableSketch,
	setMainLoading,
	setColor,
	color,
	changeImage,
	storeMask,
	actualImageDimensions
}) => {
	const dispatch = useDispatch();

	const [selectMediaModalVisible, setSelectMediaModalVisible] = useState(null);
	const [filesList, setFilesList] = useState(null);
	const [masksList, setMasksList] = useState(null);
	const [floatsList, setFloatsList] = useState(null);
	const [numbersList, setNumbersList] = useState(null);
	const [stringsList, setStringsList] = useState(null);
	const [commandModalVisible, setCommandModalVisible] = useState(false);
	const [selectedMask, setSelectedMask] = useState({ key: 0 });

	useEffect(() => {
		let files = []; // uploaded files
		let masks = []; // mask files
		let floats = []; // range for intensity value
		let numbers = [];
		let strings = [];
		Object.keys(selectedAI.args).map((itemKey, i) => {
			if (itemKey === 'output') return;
			else if (
				selectedAI.args[itemKey].type === 'file' &&
				(i === 0 || !files.length)
			)
				files.push({ key: itemKey, path: activeMedia });
			else if (
				selectedAI.args[itemKey].type === 'file' &&
				(itemKey.toLowerCase().includes('mask') ||
					itemKey.toLowerCase().includes('segment'))
			)
				masks.push({ key: itemKey, path: null });
			else if (selectedAI.args[itemKey].type === 'file')
				files.push({ key: itemKey, path: null });
			else if (
				selectedAI.args[itemKey].type === 'float' ||
				selectedAI.args[itemKey].type === 'array'
			)
				floats.push({ key: itemKey, value: 0.5 });
			else if (
				selectedAI.args[itemKey].type === 'integer' ||
				selectedAI.args[itemKey].type === 'number'
			)
				numbers.push({ key: itemKey, value: 0.7 });
			else if (selectedAI.args[itemKey].type === 'string') {
				if (itemKey === 'model')
					strings.push({ key: itemKey, value: 'mountain_pretrained' });
				else strings.push({ key: itemKey, value: '' });
			}
		});
		setFilesList(files);
		setMasksList(masks);
		setFloatsList(floats);
		setNumbersList(numbers);
		setStringsList(strings);
		if (masks.length > 1 && files)
			setSelectedMask({ key: 0, value: files[0].key });
		if (masks.length > 0 && activeMedia) setEnableSketch();
	}, []);

	useEffect(() => {
		if (filesList?.length > 0 && masksList?.length < 2) {
			let tempFilesList = [...filesList];
			tempFilesList[0].path = activeMedia;
			setFilesList(tempFilesList);
		}
		if (
			activeMedia &&
			selectedAI?.name == 'ObjectX' &&
			!enableSketch &&
			actualImageDimensions
		)
			setEnableSketch();
	}, [activeMedia, actualImageDimensions]);

	const changeMaskHandler = async (e, callback) => {
		let selectedFile;
		if (e.value) {
			selectedFile = filesList.filter((item) => item.key === e.value)[0];
			if (!selectedFile.path)
				return notifyToast('error', `please select image for ${e.value}`);
		}
		await storeMask(masksList?.length > 1 ? null : '#fff', (maskPath) => {
			if (maskPath) {
				let tempMasksList = [...masksList];
				tempMasksList[selectedMask.key].path = maskPath;
				setMasksList(tempMasksList); // update masks list}

				callback && callback(tempMasksList);
			} else {
				return notifyToast(
					'error',
					`please add mask for ${masksList[selectedMask.key].key}`
				);
			}
		}); // get mask path if exist
		setSelectedMask({ key: e.key, value: e.value }); // update dropdown
		if (selectedFile && selectedFile?.path !== activeMedia)
			changeImage(selectedFile.path); // update canvas image
	};
	const deploy = async (updatedMasksList = null) => {
		const checkFiles = filesList?.filter((file) => file.path === null);
		const tempMasksList = updatedMasksList || masksList;
		const checkMasks = tempMasksList?.filter((file) => file.path === null);
		if (checkFiles.length > 0)
			return notifyToast(
				'error',
				`please select image for ${checkFiles[0].key}`
			);
		else if (checkMasks.length > 0)
			return notifyToast('error', `please add mask for ${checkMasks[0].key}`);
		runDeployment({
			filesList,
			stringsList,
			floatsList,
			numbersList,
			masksList: tempMasksList
		});
	};
	return (
		<div className="setting-container">
			{commandModalVisible && (
				<CommandModal
					selectedAI={selectedAI}
					filesList={filesList}
					masksList={masksList}
					stringsList={stringsList}
					floatsList={floatsList}
					numbersList={numbersList}
					onClose={() => setCommandModalVisible(false)}
					onRequest={createDeploymentAction}
					dispatch={dispatch}
					setMainLoading={setMainLoading}
				/>
			)}
			<div className="ai-item">
				<img src={selectedAI.color_icon} className="ai-icon" />
				<div className="text-content">
					<h3 className="sfprodisplay-heavy-white-16px">{selectedAI.name}</h3>
					<p className="sfprodisplay-medium-white-14px">
						{selectedAI.description}
					</p>
				</div>
				<img
					className="arrow-bottom"
					src={arrowRight}
					onClick={() => {
						undoSelectedAI(null);
					}}
				/>
			</div>
			<div className="divider"></div>
			<div className="quality-container">
				{filesList?.map((item, i) => (
					<div className={'select-img-container'} key={`${i}-insert-img`}>
						<h4 className="sfprodisplay-medium-white-18px">
							{modelArgLabel[item.key] || item.key}
						</h4>
						<div
							className={`add-img ${item.path ? 'new-img' : ''}`}
							onClick={() => setSelectMediaModalVisible(i)}
							style={{ cursor: 'pointer' }}
						>
							{item.path ? (
								<img src={item.path} className="selected-img" />
							) : (
								'+'
							)}
						</div>
					</div>
				))}
				{filesList && masksList?.length > 1 && (
					<div>
						<h4 className="sfprodisplay-medium-white-18px">
							Select Canvas Image
						</h4>
						<Dropdown
							items={filesList.map((item, i) => ({
								key: i,
								value: modelArgLabel[item.key] || item.key
							}))}
							onClick={changeMaskHandler}
							menuclassName="bla"
							value={selectedMask.value}
							style={{ width: '100%' }}
						/>
					</div>
				)}
				{masksList?.length > 0 && (
					<div style={{ marginBottom: 20 }}>
						<div className={'select-img-container'}>
							<h4 className="sfprodisplay-medium-white-18px">
								Draw mask for AI
							</h4>
							<Switcher
								checked={enableSketch}
								onChange={() => setEnableSketch()}
							/>
						</div>
						<h4 className="sfprodisplay-medium-white-18px">
							Line Width in pixels
						</h4>
						<ProgressBar
							progressValue={fontWidth}
							noMove
							setProgressValue={(value) => {
								setFontWidth(value);
							}}
							min={1}
							max={100}
							style={{ paddingInline: 2 }}
						/>
						<h4
							style={{ marginBlock: 10 }}
							className="sfprodisplay-medium-white-18px"
						>
							Line Color
						</h4>
						<HexColorPicker
							style={{ margin: 'auto', marginTop: 20 }}
							color={color}
							onChange={(e) => setColor(e + 'b0')}
						/>
					</div>
				)}
				{stringsList?.map(
					(item, i) =>
						item.key !== 'model' && (
							<div key={`intensity-${i}`}>
								<Input
									label={modelArgLabel[item.key] || item.key}
									onChange={(e) => {
										let tempStringsList = [...stringsList];
										tempStringsList[i].value = e.target.value;
										setStringsList(tempStringsList);
									}}
									value={item.value}
									placeholder="string"
									style={{ marginBottom: 10 }}
								/>
							</div>
						)
				)}
				{floatsList?.map((item, i) => (
					<div key={`intensity-${i}`}>
						<h4 className="sfprodisplay-heavy-white-16px">
							{modelArgLabel[item.key] || item.key}
						</h4>
						<p className="sfprodisplay-medium-white-14px">
							The higher quality you select the more time and funds it will take
							to process imagery
						</p>

						<ProgressBar
							progressValue={floatsList[i].value}
							noMove
							setProgressValue={(value) => {
								let floatsTemp = [...floatsList];
								floatsTemp[i].value = value;
								setFloatsList(floatsTemp);
							}}
							min={0}
							max={1}
							step={'0.01'}
							style={{ paddingInline: 2 }}
						/>
					</div>
				))}
				{numbersList?.map((item, i) => (
					<div key={`numbers-${i}`}>
						<h4 className="sfprodisplay-heavy-white-16px">
							{modelArgLabel[item.key] || item.key}
						</h4>
						<ProgressBar
							progressValue={numbersList[i].value}
							noMove
							setProgressValue={(value) => {
								let numbersTemp = [...numbersList];
								numbersTemp[i].value = value;
								setNumbersList(numbersTemp);
							}}
							min={0}
							max={100}
							step={'0.1'}
							style={{ paddingInline: 2 }}
						/>
					</div>
				))}
				<button
					className={`btn ${
						filesList?.filter((file) => file.path === null).length > 0
							? 'disabled'
							: 'hovered'
					}`}
					onClick={() => {
						if (masksList?.length > 0)
							changeMaskHandler(selectedMask, (updatedMasksList) => {
								deploy(updatedMasksList);
							});
						else deploy();
					}}
					style={{ background: '#1AD697' }}
				>
					Generate
				</button>

				<button
					className={'btn hovered'}
					onClick={() => setCommandModalVisible(true)}
				>
					Show Command
				</button>
				{selectMediaModalVisible !== null && (
					<SelectMediaModal
						data={mediaList}
						selectedAI={selectedAI?.name.toLowerCase()}
						onClose={() => setSelectMediaModalVisible(null)}
						selectMedia={(selected) => {
							let filesTemp = [...filesList];
							filesTemp[selectMediaModalVisible].path = selected.media_key;
							setFilesList(filesTemp);
							setSelectMediaModalVisible(null);
							if (selectMediaModalVisible == 0) changeImage(selected.media_key);
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default AdjustSetting;
