import http from 'config/http';
import { PUBLIC_ACCESS } from 'helper/constants';
import { notifyToast } from 'helper/utils';
import { Statsig } from 'statsig-react';
import {
	USERS_REQUESTED,
	GET_USERS_FAILED,
	GET_USERS_SUCCESS,
	CREATED_DEPLOYMENT_SUCCESS,
	CREATE_DEPLOYMENT_REQUESTED,
	CREATED_DEPLOYMENT_FAILED
} from '../types/users.type';

export const getUsersDataAction = () => async (dispatch) => {
	dispatch({ type: USERS_REQUESTED });
	const token = localStorage.getItem('session');
	Statsig.logEvent('LIST_USERS_DATA');
	try {
		const payload = await http
			.get('deployments', {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then((res) => res.data);
		dispatch({ type: GET_USERS_SUCCESS, payload });
	} catch (err) {
		dispatch({ type: GET_USERS_FAILED, payload: err?.response?.data });
	}
};

export const createDeploymentAction =
	(data, callback, returnAllRes = false) =>
	async (dispatch) => {
		dispatch({ type: CREATE_DEPLOYMENT_REQUESTED });
		const token = localStorage.getItem('session');
		// if (token) Statsig.logEvent('CREATE_DEPLOYMENT');
		try {
			const deployment = await http
				.post('deployments', data, {
					headers: token ? { Authorization: `Bearer ${token}` } : PUBLIC_ACCESS
				})
				.then((res) => res.data);

			const output = deployment?.job?.status;
			notifyToast(
				output === 'complete' ? 'success' : 'error',
				output === 'complete'
					? 'Deployment Completed Successfully!'
					: 'Unexpected Error, please try again later!'
			);
			dispatch({
				type: CREATED_DEPLOYMENT_SUCCESS,
				payload: output
			});
			callback(
				returnAllRes
					? deployment
					: {
							status: output,
							file:
								deployment?.job?.files?.length > 0
									? deployment?.job?.files[0]
									: null,
							suggestedModels: deployment?.job?.output?.suggested_models,
							imageTags: deployment?.job?.output?.image_tags
					  }
			);
		} catch (err) {
			if (returnAllRes) callback(err.response);
			else callback();
			notifyToast(
				'error',
				err?.response?.data?.message ||
					'Unexpected Error, please try again later!'
			);
			dispatch({
				type: CREATED_DEPLOYMENT_FAILED,
				payload: err?.response?.data?.message
			});
		}
	};

export const checkDeploymentStatus = (jobId) => {
	const token = localStorage.getItem('session');
	return http
		.get(`jobs/${jobId}`, {
			headers: { Authorization: `Bearer ${token}` }
		})
		.then((res) => res.data);
};

export const runRecommenderAction =
	(input_image_file, callback) => async (dispatch) => {
		dispatch({ type: CREATE_DEPLOYMENT_REQUESTED });
		const token = localStorage.getItem('session');

		try {
			const deployment = await http
				.post(
					'deployments',
					{
						name: 'AnimeX',
						model_id: '2a5b76a9-5a3c-4591-88dd-97ed8de5287c',
						args: {
							input_image_file
						}
					},
					{
						headers: token
							? { Authorization: `Bearer ${token}` }
							: PUBLIC_ACCESS
					}
				)
				.then((res) => res.data);

			const output = deployment?.job?.status;

			dispatch({
				type: CREATED_DEPLOYMENT_SUCCESS,
				payload: output
			});
			callback({
				suggestedModels: deployment?.job?.output?.suggested_models,
				imageTags: deployment?.job?.output?.image_tags
			});
		} catch (err) {
			callback(null);
			notifyToast(
				'error',
				err?.response?.data?.message ||
					'Unexpected Error, please try again later!'
			);
			dispatch({
				type: CREATED_DEPLOYMENT_FAILED,
				payload: err?.response?.data?.message
			});
		}
	};
