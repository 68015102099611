import React from 'react';
import { Select, MenuItem, FormControl } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useMediaQuery } from 'react-responsive';
import './style.css';

const Dropdown = ({
	value,
	handleChange = () => {},
	items,
	style,
	styleClass,
	menuclassName,
	noValue,
	onClick = () => {}
}) => {
	const isMobile = useMediaQuery({ maxWidth: 800 });
	return (
		<FormControl className="formControl" style={style}>
			<Select
				value={isMobile || noValue ? '' : value}
				onChange={handleChange}
				IconComponent={ExpandMoreRoundedIcon}
				classes={{
					select: `dropdown ${styleClass}`
				}}
				MenuProps={{ classes: { paper: menuclassName || 'menu-top' } }}
			>
				{items.map((item) => (
					<MenuItem
						className={value === item.value ? 'selected-item' : ''}
						key={item.key}
						name={item.key}
						value={item.value}
						onClick={() => onClick(item)}
					>
						{item.value}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default Dropdown;
