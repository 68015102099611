import React, { useEffect, useState } from 'react';
import './style.css';
import Tag from './Tag';

const defaultSeprators = ['Enter'];

const TagsInput = ({
	placeHolder,
	value,
	onChange,
	onExisting,
	onRemoved,
	disabled,
	isEditOnRemove,
	beforeAddValidate
}) => {
	const [tags, setTags] = useState([...new Set(value)] || []);
	const [inputValue, setInputValue] = useState('');
	useEffect(() => {
		onChange && onChange(tags);
	}, [tags]);

	const handleOnKeyUp = (e) => {
		e.stopPropagation();
		const text = e.target.value;
		setInputValue(text);

		if (e.key === 'Backspace' && tags.length && !text) {
			e.target.value = isEditOnRemove ? `${tags.at(-1)} ` : '';
			setTags([...tags.slice(0, -1)]);
		}

		if (text && defaultSeprators.includes(e.key)) {
			if (beforeAddValidate && !beforeAddValidate(text, tags)) return;

			if (tags.includes(text)) {
				onExisting && onExisting(text);
				return;
			}
			setTags([text, ...tags]);
			e.target.value = '';
			setInputValue('');
			e.preventDefault();
		}
	};

	const onTagRemove = (text) => {
		setTags(tags.filter((tag) => tag !== text));
		onRemoved && onRemoved(text);
	};

	return (
		<div className="tags-container">
			<input
				style={{
					width: `${0.6 * inputValue.length}vw`,
					maxWidth: '100%',
					minWidth: '30%'
				}}
				placeholder={placeHolder}
				onKeyDown={handleOnKeyUp}
			/>
			{tags.map((tag) => (
				<Tag key={tag} text={tag} remove={onTagRemove} disabled={disabled} />
			))}
		</div>
	);
};

export default TagsInput;
