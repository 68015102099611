import {
	USERS_REQUESTED,
	GET_USERS_SUCCESS,
	GET_USERS_FAILED,
	CREATE_DEPLOYMENT_REQUESTED,
	CREATED_DEPLOYMENT_SUCCESS,
	CREATED_DEPLOYMENT_FAILED
} from '../types/users.type';

const intialState = {
	data: null,
	loading: false,
	error: null,
	deploymentStatus: null,
	length: null
};

const usersReducer = (state = intialState, action) => {
	switch (action.type) {
		case USERS_REQUESTED:
			return { ...state, loading: true };

		case GET_USERS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				length: action.payload.length
			};
		case GET_USERS_FAILED:
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload
			};
		case CREATE_DEPLOYMENT_REQUESTED:
			return { ...state, loading: true };

		case CREATED_DEPLOYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				deploymentStatus: action.payload
			};
		case CREATED_DEPLOYMENT_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		default:
			return state;
	}
};

export default usersReducer;
