import React from 'react';
import ReactPlayer from 'react-player';
import './style.css';
const MediaModal = ({ data, onClose }) => {
	const isJson = data.media_key.slice(-4) === 'json';
	return (
		<div
			className="video-modal animate-appear"
			style={isJson ? { minHeight: '5vw' } : {}}
		>
			<div
				className="sfprodisplay-medium-white-18px"
				style={{ marginBottom: 20 }}
			>
				{isJson
					? 'Recommended Models & Image Tags'
					: data.img_title || 'Media Title'}
			</div>
			<div className="closemodalbutton" onClick={onClose}>
				<div></div>
				<div></div>
			</div>
			<div className="cover-preview" style={isJson ? { height: 'auto' } : {}}>
				{data.media_key.slice(-3) === 'mp4' ||
				data.media_key.slice(-3) === 'mov' ? (
					<ReactPlayer
						controls
						url={data.media_key}
						volume={1}
						width={'100%'}
						height={'100%'}
					/>
				) : isJson ? (
					<div className="json-info-container">
						<div>
							<p className="sfprodisplay-bold-white-24px">{`${
								!data?.imageTags.length ? 'No' : ''
							} Suggested Models`}</p>
							<div style={{ overflowY: 'scroll', maxHeight: '32vw' }}>
								{data?.suggestedModels?.map((item, index) => (
									<p
										className="sfprodisplay-bold-white-18px json-info"
										key={`sg-${index}`}
									>
										{item}
									</p>
								))}
							</div>
						</div>
						<div>
							<p className="sfprodisplay-bold-white-24px">{`${
								!data?.imageTags.length ? 'No' : ''
							} Image Tags`}</p>
							{data?.imageTags?.map((item, index) => (
								<p
									className="sfprodisplay-bold-white-18px json-info"
									key={`it-${index}`}
								>
									{item}
								</p>
							))}
						</div>
					</div>
				) : (
					<img src={data.media_key} />
				)}
			</div>
		</div>
	);
};

export default MediaModal;
