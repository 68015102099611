import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Loading } from 'components';
import { data } from './sample-data';
import './style.css';
import arrowRight from 'assets/common/arrow_right.svg';

const BrowseAi = () => {
	const { data: browseAiData, loading } = useSelector(
		(state) => state.browseAi
	);
	const history = useHistory();

	return (
		<div className="browse-ai">
			{(!browseAiData || loading) && <Loading />}
			<div className="overlap-group11">
				<div className="flex-row-1">
					{browseAiData?.map((item, i) => (
						<div key={i} className="desktop">
							<img className="rectangle-1181" src={data[i % 8].img} />
							<div className="overlap-group6">
								<div>
									<h1 className="amount-1 sfprodisplay-heavy-white-28px">
										{item.name}
									</h1>
									<p className="text-6 sfprodisplay-regular-white-14px">
										{item.description}
									</p>
								</div>
								<a
									href="https://mediamagic.dev/docs"
									target={'_blank'}
									rel="noreferrer"
								>
									<div className="docbuttondesktop">
										<div className="sfprodisplay-heavy-white-14px">DOCS</div>
										<img src={arrowRight} />
									</div>
								</a>

								<div
									className="tryaibuttondesktop hidden-sm"
									onClick={() => history.push('/app/media')}
								>
									<div className="sfprodisplay-heavy-white-14px">Try Ai</div>
									<img src={arrowRight} />
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default BrowseAi;
