import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Auth, SignInFlow } from 'pages';
const AuthRoute = () => {
	return (
		<div style={{ height: '100vh' }}>
			<Switch>
				<Route path="/auth" component={Auth} />
				<Route path="/auth/signin-flow" component={SignInFlow} />
			</Switch>
		</div>
	);
};

export default AuthRoute;
