import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar, Slide, Alert } from '@mui/material';
import {
	Dashboard,
	Users,
	Jobs,
	BrowseAI,
	Media,
	AccountSettings,
	Article,
	ArticleV2
} from 'pages';
import { AppHeader } from 'components';
const AppRoute = ({ isAuthenticated }) => {
	const dispatch = useDispatch();
	const { message, status, visible } = useSelector((state) => state.toast);

	const handleClose = () => dispatch({ type: 'REMOVE_TOAST' });
	return (
		<div className="app-container">
			{visible && (
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					onClose={() => handleClose()}
					open={visible}
					autoHideDuration={5000}
					TransitionComponent={(props) => <Slide {...props} direction="left" />}
				>
					<Alert
						onClose={() => handleClose()}
						style={{
							background: status === 'error' ? '#D94948' : '#388E3C',
							color: '#fff'
						}}
					>
						{message}
					</Alert>
				</Snackbar>
			)}
			<AppHeader isAuthenticated={isAuthenticated} />
			{/* <AppLeftnav /> */}
			<Switch>
				{isAuthenticated && (
					<Route path="/app/dashboard/performance" component={Dashboard} />
				)}
				{isAuthenticated && (
					<Route path="/app/dashboard" component={Dashboard} />
				)}
				<Route path="/app/media" component={Media} />
				{isAuthenticated && <Route path="/app/jobs" component={Jobs} />}
				{isAuthenticated && <Route path="/app/users" component={Users} />}
				<Route path="/app/browse-ai" component={BrowseAI} />
				{isAuthenticated && (
					<Route path="/app/account-setting" component={AccountSettings} />
				)}
				<Route path="/app/article" component={Article} />
				<Route path="/app/article-v2" component={ArticleV2} />
				<Route path="*" render={() => <Redirect to="/" />} />
			</Switch>
		</div>
	);
};

export default AppRoute;
