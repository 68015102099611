import React from 'react';
import { FiAtSign } from 'react-icons/fi';
import './style.css';

const EmailAccess = ({ onClick, onChange, email }) => {
	return (
		<div className="home-landing">
			<div className="overlap-group17 container">
				<div className="content-wrpper">
					<div className="email-input ">
						<form>
							<div className="email-access">
								<div className="group">
									<input
										placeholder="xyz@gmail.com"
										value={email}
										onChange={onChange}
									/>
									<FiAtSign />
								</div>
								<div className="emailinputbutton">
									<button
										className="early-access"
										onClick={() => onClick(email)}
									>
										Early Access
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmailAccess;
