import {
	MEDIA_REQUESTED,
	GET_MEDIA_SUCCESS,
	GET_MEDIA_FAILED,
	ADD_MEDIA,
	UPLOAD_MASK,
	GET_THIRD_PARTY_FAILED,
	GET_THIRD_PARTY_SUCCESS,
	DELETE_MEDIA_FAILED,
	UPDATE_MEDIA_SUCCESS,
	UPDATE_MEDIA_FAILED
} from '../types/media.type';

const intialState = {
	data: null,
	length: null,
	loading: false,
	error: null,
	thirdpartyData: null,
	totalPages: null,
	newMediaAdded: null
};

const mediaReducer = (state = intialState, action) => {
	switch (action.type) {
		case MEDIA_REQUESTED:
			return { ...state, loading: true, newMediaAdded: null };

		case GET_MEDIA_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				length: action.payload.length
			};
		case ADD_MEDIA:
			return {
				...state,
				loading: false,
				newMediaAdded: true,
				data: [action.payload, ...(state.data || [])]
			};
		case UPLOAD_MASK:
			return {
				...state,
				loading: false
			};
		case UPDATE_MEDIA_SUCCESS:
			state.data[action.selectedIndex].tags = action.tags;
			return {
				...state
			};
		case GET_THIRD_PARTY_SUCCESS:
			return {
				...state,
				thirdpartyData:
					action.page === 1
						? action.payload
						: [...state.thirdpartyData, ...action.payload],
				totalPages: action.totalPages
			};
		case GET_MEDIA_FAILED:
		case GET_THIRD_PARTY_FAILED:
		case UPDATE_MEDIA_FAILED:
		case DELETE_MEDIA_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload,
				totalPages: null
			};
		default:
			return state;
	}
};

export default mediaReducer;
