import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import { MessagePopup, SelectLang } from 'components';
import { validateEmail } from 'helper/validator';
import jsIcon from 'assets/common/js-icon.svg';
import go from 'assets/common/go.svg';
import ruby from 'assets/common/ruby.svg';
import php from 'assets/common/php.svg';
import pythonIcon from 'assets/common/python-icon.svg';
import simple from 'assets/landing/features/simple-color.svg';
import secure from 'assets/landing/features/secure-color.svg';
import scalable from 'assets/landing/features/scalable-color.svg';
import fast from 'assets/landing/features/fast-color.svg';
import debug from 'assets/landing/features/debug-color.svg';
import sync from 'assets/landing/features/sync-color.svg';
import Effect from './greenEffect';
// import SelectTutorial from './selectTutorial';
import { FiAtSign } from 'react-icons/fi';

const languages = [
	{
		title: 'Javascript',
		icon: jsIcon
	},
	{
		title: 'Python',
		icon: pythonIcon
	},
	{
		title: 'Golang',
		icon: go
	},
	{
		title: 'PHP',
		icon: php
	},
	{
		title: 'Ruby',
		icon: ruby
	}
];

const Landing = () => {
	const parallex1Ref = useRef(null);
	const parallexRef = useRef(null);
	const FirsteGroupRef = useRef(null);
	const SecondGroupRef = useRef(null);
	const emailFirstRef = useRef(null);
	const emailSecondRef = useRef(null);
	const [messageContent, setMessageContent] = useState(false);
	const [openSelectLang, setOpenSelectLang] = useState(false);
	const [email, setEmail] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [parallex, setParallex] = useState(null);
	const selectLang = (e, email) => {
		e.preventDefault();
		if (!validateEmail(email)) {
			setMessageContent('Email is invalid');
		} else setOpenSelectLang(true);
	};

	const verifySelectedLang = (selected) => {
		if (!selected) return setOpenSelectLang(false);
		if (selected && !selected.includes(true))
			return setMessageContent('Please selected at least one language!');
		setOpenSelectLang(false);
		return setMessageContent(
			`Thank you for requesting early access: ${email} will be notified of updates!`
		);
	};

	useEffect(() => {
		try {
			let c = document.createElement('canvas').getContext('2d');
			let postctx = document
				.getElementById('multicanvas')
				.appendChild(document.createElement('canvas'))
				.getContext('2d');
			let canvas = c.canvas;
			let vertices = [];

			let vertexCount = 7000;
			let vertexSize = 3;
			let oceanWidth = 204;
			let oceanHeight = -80;
			let gridSize = 32;
			let waveSize = 16;
			let perspective = 100;

			let depth = (vertexCount / oceanWidth) * gridSize;
			let frame = 0;
			let { sin, cos, PI } = Math;

			const loop = () => {
				let rad = (sin(frame / 100) * PI) / 20;
				let rad2 = (sin(frame / 50) * PI) / 10;
				frame++;
				if (
					postctx.canvas.width !== postctx.canvas.offsetWidth ||
					postctx.canvas.height !== postctx.canvas.offsetHeight
				) {
					postctx.canvas.width = canvas.width = postctx.canvas.offsetWidth;
					postctx.canvas.height = canvas.height = postctx.canvas.offsetHeight;
				}

				c.fillStyle = 'hsl(200deg, 100%, 2%)';
				c.fillRect(0, 0, canvas.width, canvas.height);
				c.save();
				c.translate(canvas.width / 2, canvas.height / 2);

				c.beginPath();
				vertices.forEach((vertex, i) => {
					let x = vertex[0] - (frame % (gridSize * 2));
					let z =
						vertex[2] -
						((frame * 2) % gridSize) +
						(i % 2 === 0 ? gridSize / 2 : 0);
					let wave =
						cos(frame / 45 + x / 50) -
						sin(frame / 20 + z / 50) +
						sin(frame / 30 + (z * x) / 10000);
					let y = vertex[1] + wave * waveSize;
					let a = Math.max(0, 1 - Math.sqrt(x ** 2 + z ** 2) / depth);
					let tx, ty, tz;

					y -= oceanHeight;

					// Transformation variables
					tx = x;
					ty = y;
					tz = z;

					// Rotation Y
					tx = x * cos(rad) + z * sin(rad);
					tz = -x * sin(rad) + z * cos(rad);

					x = tx;
					y = ty;
					z = tz;

					// Rotation Z
					tx = x * cos(rad) - y * sin(rad);
					ty = x * sin(rad) + y * cos(rad);

					x = tx;
					y = ty;
					z = tz;

					// Rotation X

					ty = y * cos(rad2) - z * sin(rad2);
					tz = y * sin(rad2) + z * cos(rad2);

					x = tx;
					y = ty;
					z = tz;

					x /= z / perspective;
					y /= z / perspective;

					if (a < 0.01) return;
					if (z < 0) return;

					c.globalAlpha = a;
					c.fillStyle = `hsl(${180 + wave * 20}deg, 100%, 50%)`;
					c.fillRect(
						x - (a * vertexSize) / 2,
						y - (a * vertexSize) / 2,
						a * vertexSize,
						a * vertexSize
					);
					c.globalAlpha = 1;
				});
				c.restore();

				postctx.drawImage(canvas, 0, 0);

				postctx.globalCompositeOperation = 'screen';
				postctx.filter = 'blur(16px)';
				postctx.drawImage(canvas, 0, 0);
				postctx.filter = 'blur(0)';
				postctx.globalCompositeOperation = 'source-over';

				requestAnimationFrame(loop);
			};

			// Generating dots
			for (let i = 0; i < vertexCount; i++) {
				let x = i % oceanWidth;
				let y = 0;
				let z = (i / oceanWidth) >> 0;
				let offset = oceanWidth / 2;
				vertices.push([(-offset + x) * gridSize, y * gridSize, z * gridSize]);
			}

			loop();
		} catch (error) {
			console.log('error', error);
		}
	}, []);

	useEffect(() => {
		FirsteGroupRef.current.addEventListener('keyup', () =>
			emailFirstRef.current.classList.toggle(
				'valid',
				FirsteGroupRef.current.value
			)
		);
	}, [FirsteGroupRef.current]);

	useEffect(() => {
		SecondGroupRef.current.addEventListener('keyup', () =>
			emailSecondRef.current.classList.toggle(
				'valid',
				emailSecondRef.current.value
			)
		);
	}, [SecondGroupRef.current]);

	function parallax1(e) {
		let _w = window.innerWidth / 2;
		let _h = window.innerHeight / 2;
		let _mouseX = e.clientX;
		let _mouseY = e.clientY;
		let _depth1 = `${50 - (_mouseX - _w) * 0.01}% ${
			50 - (_mouseY - _h) * 0.01
		}%`;
		let _depth2 = `${50 - (_mouseX - _w) * 0.02}% ${
			50 - (_mouseY - _h) * 0.02
		}%`;
		let _depth3 = `${50 - (_mouseX - _w) * 0.06}% ${
			50 - (_mouseY - _h) * 0.06
		}%`;
		let x = `${_depth3}, ${_depth2}, ${_depth1}`;
		setParallex(x);
		parallex1Ref.current.style.backgroundPosition = x;
	}

	useEffect(() => {
		document.addEventListener('mousemove', parallax1);
	}, []);

	function parallax(e) {
		let _w = window.innerWidth / 2;
		let _h = window.innerHeight / 2;
		let _mouseX = e.clientX;
		let _mouseY = e.clientY;
		let _depth1 = `${50 - (_mouseX - _w) * 0.01}% ${
			50 - (_mouseY - _h) * 0.01
		}%`;
		let _depth2 = `${50 - (_mouseX - _w) * 0.02}% ${
			50 - (_mouseY - _h) * 0.02
		}%`;
		let _depth3 = `${50 - (_mouseX - _w) * 0.06}% ${
			50 - (_mouseY - _h) * 0.06
		}%`;
		let x = `${_depth3}, ${_depth2}, ${_depth1}`;
		setParallex(x);
		parallexRef.current.style.backgroundPosition = x;
	}

	useEffect(() => {
		document.addEventListener('mousemove', parallax);
	}, []);

	const names = [
		'Synthetic Media',
		'Fashion Visualization',
		'Making Memes',
		'Transcription',
		'FaceApp Like Edits',
		'MultiFace Tracking'
	];

	const [activeIndex, setIndex] = useState(0);

	useEffect(() => {
		const intenal = setInterval(() => {
			setIndex((prev) => (names.length - 1 == prev ? 0 : prev + 1));
		}, 4000);

		return () => {
			clearInterval(intenal);
		};
	}, []);

	return (
		<div className="landing home-wrpper">
			{messageContent && (
				<MessagePopup
					content={messageContent}
					onClick={() => setMessageContent(!messageContent)}
				/>
			)}
			{openSelectLang && (
				<SelectLang onClick={(selected) => verifySelectedLang(selected)} />
			)}
			<div className="home-landing">
				<Effect />
				<div className="overlap-group17 container">
					<div className="content-wrpper">
						<div className="the-ai-api-for">The AI API for</div>
						<h1 className="title">
						<span className="shine">{names[activeIndex]}</span>
						</h1>
						<br />
						<div className="text-31">
							Simple Developer API to Build with Generative AI in mins not
							months. Code with What you Know to leverage TensorFlow.
						</div>
						<div className="email-input" ref={emailFirstRef}>
							<form>
								<div className="email-access">
									<div className="group">
										<input
											ref={FirsteGroupRef}
											placeholder="xyz@gmail.com"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
										<FiAtSign />
									</div>
									<div className="emailinputbutton">
										<button
											className="early-access sfprodisplay-bold-white-16px"
											onClick={(e) => selectLang(e, email)}
										>
											Early Access
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div className="text-5 hidden-md" style={{ marginTop: 120 }}>
				Powerful AI + Analytics
			</div>
			<div className="text-29 hidden-md">
				Adapt, Convert, &amp; Scale Synthetic Media Efficiently
			</div>
			<div className="projects-container">
				<div className="container">
					<div className="parallax" ref={parallexRef}></div>
				</div>
			</div>
			<div className="container">
				<div className="text-5 hidden-sm">Powerful AI + Analytics</div>
				<div className="text-29 hidden-sm">
					Adapt, Convert, &amp; Scale Synthetic Media Efficiently
				</div>
				<div className="text-7">Use What you Know, Not Tensorflow</div>
				<div className="langs-container">
					{languages.map((item, i) => (
						<div key={i} className="lang-container">
							<img src={item.icon} />
							<div className="sfprodisplay-bold-white-20px">{item.title}</div>
						</div>
					))}
				</div>
			</div>
			<div className="projects-container">
				<div className="container">
					<div className="text-5" style={{ marginTop: 150 }}>
						Use AI in Minutes not Months
					</div>
					<div className="content-text">
						The MediaMagic API is a dual modern GraphQL + REST API that makes
						Synthetic Media easy. Create, Transform, and Deliver Fast, Simply,
						and Magically.
					</div>
					<div className="parallax1" ref={parallex1Ref}></div>
				</div>
			</div>
			<div className="overlap-group18 container">
				<div className="text-5" style={{ marginBlock: 50 }}>
					One Easy API for Cutting Edge AI
				</div>
				<div className="properities-wrp">
					<ul className="properities">
						<li className="property">
							<div className="easing">
								<span>
									<img src={simple} />
								</span>
							</div>
							<h2>Simple</h2>
							<p>Zero devops and configuration required</p>
						</li>
						<li className="property">
							<div className="parenting">
								<span>
									<img src={secure} />
								</span>
							</div>
							<h2>Secure</h2>
							<p>Automatic HTTPS. Private by Default</p>
						</li>
						<li className="property">
							<div className="transformation">
								<span>
									<img src={scalable} />
								</span>
							</div>
							<h2>Scalable</h2>
							<p>Automatic HTTPS. Private by Default</p>
						</li>
						<li className="property">
							<div className="dimension">
								<span>
									<img src={fast} />
								</span>
							</div>
							<h2>Fast</h2>
							<p>Automatic HTTPS. Private by Default</p>
						</li>
						<li className="property">
							<div className="dimension">
								<img src={debug} />
							</div>
							<h2>Debuggable</h2>
							<p>Automatic HTTPS. Private by Default</p>
						</li>
						<li className="property">
							<div className="transformation">
								<img src={sync} />
							</div>
							<h2>In Sync</h2>
							<p>Automatic HTTPS. Private by Default</p>
						</li>
					</ul>
				</div>
			</div>
			<div className="tutorial-section">{/* <SelectTutorial /> */}</div>
			<div className="newsletter position-relative">
				<div className="dot-effect">
					<div id="multicanvas"></div>
				</div>
				<div className="container bottom-con">
					<div className="text-29">
						Dead Simple Scaling <br />
						Pain Free Integration
					</div>
					<div className="home-landing">
						<div className="overlap-group17 container">
							<div className="content-wrpper">
								<div className="email-input" ref={emailSecondRef}>
									<form>
										<div className="email-access">
											<div className="group">
												<input
													ref={SecondGroupRef}
													placeholder="xyz@gmail.com"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>
												<FiAtSign />
											</div>
											<div className="emailinputbutton">
												<button
													className="early-access sfprodisplay-bold-white-16px"
													onClick={(e) => selectLang(e, email)}
												>
													Early Access
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Landing;
