import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	deleteMediaAction,
	getMediaDataAction,
	updateMediaAction
} from 'store/actions/media.action';
import './style.css';
import { Loading, TagsInput } from 'components';
import close from 'assets/common/close.svg';

const MediaTaggingModal = ({
	files,
	onClose,
	single,
	hideDeleteBtn,
	selectedMediaIndex
}) => {
	const dispatch = useDispatch();
	const [filesTags, setFilesTags] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const tempFiles = [...files];
		setFilesTags(tempFiles.map((item) => item.tags));
	}, [files]);
	const updateMediaTags = async () => {
		setLoading(true);
		await filesTags.forEach((fileTags, index) => {
			if (fileTags?.length) {
				dispatch(
					updateMediaAction(
						files[index].id,
						{
							tags: fileTags
						},
						selectedMediaIndex
					)
				);
			}
		});
		setTimeout(() => {
			setLoading(false);
			onClose();
		}, 200);
	};

	const removeImage = (key) => {
		{
			dispatch(
				deleteMediaAction(files[key].id, () => {
					files.splice(key, 1);
					const tempFilesTags = [...filesTags];
					tempFilesTags.splice(key, 1);
					setFilesTags(tempFilesTags);
					dispatch(getMediaDataAction());
					if (files.length === 0) onClose();
				})
			);
		}
	};
	return (
		<>
			<div className="low-opacity" />
			{loading && <Loading />}
			<div
				className={`postupload-modal animate-appear modal-background ${
					single ? 'postupload-single-item-container' : ''
				}`}
			>
				<div className="sfprodisplay-bold-white-24px">Media Tagging</div>
				<div className="closemodalbutton" onClick={onClose}>
					<img src={close} />
				</div>
				{filesTags && (
					<div className="postupload-content">
						{files.map((item, key) => (
							<div className="item" key={`uploaded-img-${key}`}>
								<div className="image-container">
									<img src={item.url} className="uploaded-image" />
									{!hideDeleteBtn && (
										<img
											src={close}
											className="remove-icon"
											onClick={() => removeImage(key)}
										/>
									)}
								</div>
								<TagsInput
									placeHolder="+ Tag"
									value={filesTags[key]}
									onChange={(tagsList) => {
										const tempFilesTags = [...filesTags];
										tempFilesTags[key] = tagsList;
										setFilesTags(tempFilesTags);
									}}
								/>
							</div>
						))}
					</div>
				)}
				<button
					className="save-btn"
					onClick={() => {
						updateMediaTags();
					}}
				>
					Save
				</button>
			</div>
		</>
	);
};

export default MediaTaggingModal;
