import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDashboardDataAction } from 'store/actions/dashboard.action';
import Divider from './components/Divider/Divider';
import DashboardRightTab from './components/DashboardRightTab/DashboardRightTab';
import PerformanceContainer from './components/PerformanceContainer/PerformanceContainer';
import Item from './components/Item/Item';
import { popularData, data } from './sample-data';
import './style.css';

const Dashboard = () => {
	const dispatch = useDispatch();
	const [selectedDashboard, setSelectedDashboard] = useState(
		window.location.pathname.includes('performance') ? 1 : 0
	);

	useEffect(() => {
		dispatch(getDashboardDataAction());
	}, []);

	return (
		<div className="dashboard">
			<DashboardRightTab
				onClick={(val) => setSelectedDashboard(val)}
				selectedDashboard={selectedDashboard}
			/>
			{selectedDashboard === 0 ? (
				<div className="started-container">
					<div className="flex-col-3">
						<Divider leftValue="Popular" rightVaue="See All" noline={true} />
						<div
							className="flex-row-9"
							style={{ gridTemplateColumns: 'auto auto', paddingLeft: 0 }}
						>
							{popularData.map((item, i) => (
								<Item
									key={i}
									title={item.title}
									content={item.content}
									img={item.img}
									updateSmallScreen
								/>
							))}
						</div>
						{data.map((sections, i) => (
							<div key={i} className="group-46922">
								<Divider
									leftValue={sections.sectionTitle}
									rightVaue="View Docs"
								/>
								<div className="flex-row-9">
									{sections.sectionContent.map((item, j) => (
										<Item
											key={`${i}-item-${j}`}
											title={item.title}
											background={sections.background}
											content={item.content}
											img={item.img}
											icon={item.icon}
											smaller
										/>
									))}
								</div>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className="started-container">
					<PerformanceContainer />
				</div>
			)}
		</div>
	);
};

export default Dashboard;
