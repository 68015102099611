import React from 'react';
import './style.css';

const ProgressBar = ({
	title,
	progressValue,
	setProgressValue,
	min,
	max,
	step = 1,
	style = {},
	noMove
}) => {
	return (
		<div className="progress-container" style={style}>
			<div className="text sfprodisplay-medium-white-16px">{title}</div>
			<div className="filter-number">{min}</div>
			<div className="filter-number" style={{ right: 0, left: 'auto' }}>
				{max}
			</div>
			<input
				className="line-60"
				type="range"
				min={min}
				max={max}
				onChange={(e) =>
					setProgressValue(
						step < 1 ? parseFloat(e.target.value) : parseInt(e.target.value, 10)
					)
				}
				step={step}
				value={progressValue}
			/>
			<div
				className="group-8416"
				style={{
					left: noMove ? '47.5%' : progressValue * 0.87
				}}
			>
				<div className="group-8414">
					<div className="filter-overlap-group-1">
						<div className="filter-number-2" id="credit-value">
							{progressValue}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProgressBar;
