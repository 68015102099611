import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
const Loading = () => (
	<Backdrop
		sx={{ color: '#fff', zIndex: 99999 }}
		open={true}
	>
		<CircularProgress color="inherit" />
	</Backdrop>
);

export default Loading;
