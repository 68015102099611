import React, { useState } from 'react';
import './style.css';
import arrowUp from 'assets/common/arrow_up.svg';
import arrowDown from 'assets/common/arrow_down.svg';
const EditTableModal = ({ onClick }) => {
    const [credit, setCredit] = useState(10);
    const [pause, setPause] = useState(10);
    const [stop, setStop] = useState(10);
    const increase = () => {
        setCredit(credit + 1);
    };

    const decrease = () => {
        if (credit !== 0) setCredit(credit - 1);
    };
    return (
        <div className="edit-table-popup">
            <div className="overlap-group5">
                <div className="edit-table-popup-flex-row">
                    <div className="edit-table-popup-flex-col">
                        <div className="text-2">EDIT JOB</div>
                        <div className="group-8418">
                            <div className="edit-table-popup-text sfprodisplay-medium-white-14px">Adjust Credits</div>
                            <p className="edit-table-popup-text-1 sfprodisplay-regular-white-7px">May result in additional charges</p>
                        </div>
                        <div className="group-8419">
                            <div className="edit-table-popup-text sfprodisplay-medium-white-14px">Pause Job</div>
                            <p className="edit-table-popup-text-1 sfprodisplay-regular-white-7px">
								Credits will be temporally stopped
                            </p>
                        </div>
                        <div className="group-8420">
                            <div className="edit-table-popup-text sfprodisplay-medium-white-14px">Stop Job</div>
                            <p className="edit-table-popup-text-1 sfprodisplay-regular-white-7px">
								Credits will be permenantly stopped
                            </p>
                        </div>
                    </div>
                    <div className="edit-table-popup-flex-col-1">
                        <div className="edit-table-popup-closemodalbutton" onClick={onClick}>
                            <div className="edit-table-popup-overlap-group">
                                <div className="rectangle-2285"></div>
                                <div className="rectangle-2286"></div>
                            </div>
                        </div>
                        <div className="overlap-group6">
                            <div className="edit-table-popup-overlap-group1">
                                <div className="text-3">{credit}</div>
                                <div className="counter">
                                    <img className="group-8417" src={arrowUp} onClick={increase} />
                                    <img className="group-8417" src={arrowDown} onClick={decrease} />
                                </div>
                            </div>
                            <div
                                className={!stop ? 'overlap-group2-start' : 'edit-table-popup-overlap-group2'}
                                onClick={() => setStop(!stop)}
                            >
                                <div className="text-4">Stop</div>
                                <div className="rectangle-2366"></div>
                            </div>
                            <div
                                className={!pause ? 'overlap-group3-start' : 'edit-table-popup-overlap-group3'}
                                onClick={() => setPause(!pause)}
                            >
                                <div className="text-5">Pause</div>
                                <div className="group-8421">
                                    <div className="rectangle-2364"></div>
                                    <div className="rectangle-2364"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlap-group4">
                    <div className="confirm" onClick={onClick}>
						CONFIRM
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditTableModal;
