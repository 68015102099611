import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { StatsigProvider } from 'statsig-react';
import { useDispatch, useSelector } from 'react-redux';
import { getModelsAction } from 'store/actions/browseai.action';
import AppRoute from 'routes/appRoute';
import AuthRoute from 'routes/authRoute';
import LandingRoute from 'routes/landingRoute';
import { ScrollToTop, Loading } from 'components';
import './global.css';

const App = () => {
	const { isAuthenticated, isLoading, user } = useAuth0();
	const { isPublicAccess } = useSelector((state) => state.auth);
	const [prodVersion, isProdVersion] = useState(false);
	const dispatch = useDispatch();
	const { data: browseAiData } = useSelector((state) => state.browseAi);

	useEffect(() => {
		if (
			window.location.host === 'www.mediamagic.ai' ||
			window.location.host === 'mediamagic.ai'
		)
			isProdVersion(true);

		if (!browseAiData) dispatch(getModelsAction());
	}, []);

	if (isLoading) return <Loading />;
	return (
		<div className="screen">
			<BrowserRouter>
				<ScrollToTop>
					<Switch>
						{(isAuthenticated || isPublicAccess) && !prodVersion && (
							<Route path="/app">
								<StatsigProvider
									sdkKey="client-zVIgNKKdnV3swldG00RFH8gePuXAFGYuoFAaTWH3YCN"
									user={{
										userID: user?.sub,
										email: user?.email
									}}
									waitForInitialization={true}
								>
									<AppRoute isAuthenticated={isAuthenticated} />
								</StatsigProvider>
							</Route>
						)}
						{!prodVersion && (
							<Route path="/auth">
								<AuthRoute />
							</Route>
						)}
						<Route path="*">
							<LandingRoute prodVersion={prodVersion} />
						</Route>
					</Switch>
				</ScrollToTop>
			</BrowserRouter>
		</div>
	);
};

export default App;
