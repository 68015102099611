import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import browsee from '@browsee/web-sdk';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';

browsee.init({ apiKey: process.env.REACT_APP_BROWSEE_KEY });

ReactDOM.render(
	<Provider store={store}>
		<Auth0Provider
			domain="mediamagic.eu.auth0.com"
			clientId={'ya6qt8GGaloQuxBt2UsgXCrwNjGKVR1j'}
			redirectUri={window.location.origin}
		>
			<App />
		</Auth0Provider>
	</Provider>,
	document.getElementById('root')
);
