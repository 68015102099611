export const teamSampleData = [
	{
		name: 'Drake Owens',
		email: 'odrake@gmail.com',
		role: 'Admin'
	},
	{
		name: 'Nick',
		email: 'nick@gmail.com',
		role: 'Admin'
	},
	{
		name: 'Steve',
		email: 'steve@gmail.com',
		role: 'Member'
	},
	{
		name: 'Anas',
		email: 'anas@gmail.com',
		role: 'Guest'
	}
];

export const paymentSampleInfo = [
	{
		type: 'Visa',
		cardNo: 'xxxx-xxxx-xxxx-4672',
		expireDate: '05-2026'
	},
	{
		type: 'Visa',
		cardNo: 'xxxx-xxxx-xxxx-1234',
		expireDate: '03-2024'
	}
];

export const secretKeys = [
	{
		name: 'Publishable',
		token: 'absndjfj_skdxjsJshdnjJshhi394kdsOdk',
		lastUsed: 'May 29',
		createdDate: 'May 26, 2020',
		hiddenToken: false
	},
	{
		name: 'Publishable',
		token: 'absndjfj_skdxjsJshdnjJshhi394kdsOdk',
		lastUsed: 'May 29',
		createdDate: 'May 26, 2020',
		hiddenToken: false
	},
	{
		name: 'Secret',
		token: 'absndjfj_skdxjsJshdnjJshhi394kdsOdk',
		lastUsed: 'Jun 4',
		createdDate: 'May 26, 2020',
		hiddenToken: true
	}
];

export const restrictedKeys = [];
