import { SET_TOAST, REMOVE_TOAST } from '../types/toast.type';

const intialState = {
	message: '',
	status: null,
	visible: false
};

const toastReducer = (state = intialState, action) => {
	switch (action.type) {
		case SET_TOAST:
			return {
				status: action.payload.status,
				message: action.payload.message,
				visible: true
			};

		case REMOVE_TOAST:
			return {
				message: '',
				visible: false
			};

		default:
			return state;
	}
};

export default toastReducer;
