import React, { useEffect } from 'react';

const Effect = () => {
	const canvasRef = React.useRef();
	const speed = 10;
	const size = 8;
	const totalBoids = 150;

	let width = null;
	let height = null;
	let ctx = null;

	let boids = [];

	const init = () => {
		try {
			for (let i = 0; i < totalBoids; i++) {
				boids.push({
					x: Math.random() * width,
					y: Math.random() * height,
					v: {
						x: Math.random() * 2 - 1,
						y: Math.random() * 2 - 1
					},
					c:
						'rgba(' +
						Math.floor(Math.random() * 15) +
						',' +
						Math.floor(Math.random() * 155) +
						',' +
						Math.floor(Math.random() * 55) +
						', 1.0)'
				});
			}
			setInterval(update, 40);
		} catch (error) {
			console.log(error);
		}
	};

	let calculateDistance = (v1, v2) => {
		try {
			let x = Math.abs(v1.x - v2.x);
			let y = Math.abs(v1.y - v2.y);

			return Math.sqrt(x * x + y * y);
		} catch (error) {
			console.log(error);
		}
	};

	let checkWallCollisions = (index) => {
		try {
			if (boids[index].x > width) {
				boids[index].x = 0;
			} else if (boids[index].x < 0) {
				boids[index].x = width;
			}

			if (boids[index].y > height) {
				boids[index].y = 0;
			} else if (boids[index].y < 0) {
				boids[index].y = height;
			}
		} catch (error) {
			console.log(error, 'checkWallCollisions error');
		}
	};

	let addForce = function (index, force) {
		try {
			boids[index].v.x += force.x;
			boids[index].v.y += force.y;

			let magnitude = calculateDistance(
				{
					x: 0,
					y: 0
				},
				{
					x: boids[index].v.x,
					y: boids[index].v.y
				}
			);

			boids[index].v.x = boids[index].v.x / magnitude;
			boids[index].v.y = boids[index].v.y / magnitude;
		} catch (error) {
			console.log(error, 'addForce error');
		}
	};

	const applyForces = (index) => {
		try {
			let percievedCenter = {
				x: 0,
				y: 0
			};
			let flockCenter = {
				x: 0,
				y: 0
			};
			let percievedVelocity = {
				x: 0,
				y: 0
			};
			let count = 0;
			for (let i = 0; i < boids.length; i++) {
				if (i != index) {
					let dist = calculateDistance(boids[index], boids[i]);

					if (dist > 0 && dist < 50) {
						count++;
						percievedCenter.x += boids[i].x;
						percievedCenter.y += boids[i].y;

						percievedVelocity.x += boids[i].v.x;
						percievedVelocity.y += boids[i].v.y;

						if (calculateDistance(boids[i], boids[index]) < 12) {
							flockCenter.x -= boids[i].x - boids[index].x;
							flockCenter.y -= boids[i].y - boids[index].y;
						}
					}
				}
			}
			if (count > 0) {
				percievedCenter.x = percievedCenter.x / count;
				percievedCenter.y = percievedCenter.y / count;

				percievedCenter.x = (percievedCenter.x - boids[index].x) / 400;
				percievedCenter.y = (percievedCenter.y - boids[index].y) / 400;

				percievedVelocity.x = percievedVelocity.x / count;
				percievedVelocity.y = percievedVelocity.y / count;

				flockCenter.x /= count;
				flockCenter.y /= count;
			}

			addForce(index, percievedCenter);

			addForce(index, percievedVelocity);

			addForce(index, flockCenter);
		} catch (error) {
			console.log(error);
		}
	};

	const update = () => {
		try {
			clearCanvas();
			for (let i = 0; i < boids.length; i++) {
				ctx.beginPath();
				ctx.strokeStyle = boids[i].c;

				ctx.lineWidth = size;
				ctx.moveTo(boids[i].x, boids[i].y);
				boids[i].x += boids[i].v.x * speed;
				boids[i].y += boids[i].v.y * speed;
				applyForces(i);
				ctx.lineTo(boids[i].x, boids[i].y);
				ctx.stroke();
				ctx.fill();

				checkWallCollisions(i);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const clearCanvas = () => {
		try {
			ctx.fillStyle = 'rgba(0, 0, 0, 1.0)';
			ctx.beginPath();
			ctx.rect(0, 0, width, height);
			ctx.closePath();
			ctx.fill();
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		try {
			if (typeof window != undefined) {
				width = window.innerWidth;
				height = window.innerHeight;
				const canvas = canvasRef.current;
				if (!canvas) return;
				ctx = canvas.getContext('2d');
				canvas.width = width;
				canvas.height = height;

				init();
			}
		} catch (error) {
			console.log(error);
		}
	}, []);
	return (
		<canvas id="canvas" width="1000" height="1000" ref={canvasRef}></canvas>
	);
};

export default Effect;
