import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
	Landing,
	Pricing,
	ComingSoon,
	Contact,
	Help,
	HelpArticle,
	BrowseAI,
} from 'pages';
import { MainHeader, MainFooter } from 'components';
const LandingRoute = ({ prodVersion }) => {
	return (
		<div
			className="app-container"
			style={{ padding: 0, marginTop: 52 }}
		>
			<MainHeader prodVersion={prodVersion} />
			<Switch>
				{!prodVersion && <Route path="/pricing" component={Pricing} />}
				{!prodVersion && <Route path="/contact-us" component={Contact} />}
				{!prodVersion && <Route path="/products" component={BrowseAI} />}
				{!prodVersion && <Route path="/help/:slug" component={HelpArticle} />}
				{!prodVersion && <Route path="/help" component={Help} />}
				{!prodVersion && <Route path="/coming-soon" component={ComingSoon} />}
				<Route path="/" component={Landing} />
				<Route path="*" render={() => <Redirect to="/" />} />
			</Switch>
			<MainFooter prodVersion={prodVersion} />
		</div>
	);
};

export default LandingRoute;
