import React, { useState } from 'react';
import './style.css';
const SwipableCarousal = ({ items, setActive }) => {
    const [sequence, setSequence] = useState([0, 1, 2]);
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const onSwipeStart = e => {
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onSwipeMoved = e => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const onSwipeEnded = () => {
        if (touchStart - touchEnd > 50) {
            // do your stuff here for left swipe
            setSequence([sequence[2], sequence[0], sequence[1]]);
            setActive(sequence[0]);
        }

        if (touchStart - touchEnd < -50) {
            // do your stuff here for right swipe
            setSequence([sequence[1], sequence[2], sequence[0]]);
            setActive(sequence[2]);
        }
        setTouchEnd(0);
        setTouchStart(0);
    };
    return (
        <div className="carousal">
            {sequence.map((seq, i) => (
                <div
                    key={i}
                    style={
                        touchEnd !== 0
                            ? i === 0
                                ? {
                                    opacity: touchStart - touchEnd > 0 && 1,
                                    marginLeft:
											touchEnd - touchStart < -10
											    ? (touchStart - touchEnd) * 0.9
											    : touchEnd - touchStart > 10 && -(touchStart - touchEnd) * 0.7,
                                    zIndex: touchEnd - touchStart < -10 && 99
								  }
                                : i === 1
                                    ? {
                                        marginLeft: touchStart - touchEnd,
                                        zIndex: 9
								  }
                                    : {
                                        opacity: touchStart - touchEnd < 0 && 1,
                                        marginLeft:
											touchEnd - touchStart > 10
											    ? (touchStart - touchEnd) * 0.9
											    : touchEnd - touchStart < -10 && -(touchStart - touchEnd) * 0.7,
                                        zIndex: touchEnd - touchStart > 10 && 99
								  }
                            : {}
                    }
                    onTouchStart={e => i === 1 && onSwipeStart(e)}
                    onTouchMove={e => i === 1 && onSwipeMoved(e)}
                    onTouchEnd={e => i === 1 && onSwipeEnded(e)}
                    onClick={() =>
                        i !== 1 &&
						setSequence(
						    i === 2
						        ? [sequence[0], sequence[2], sequence[1]]
						        : i === 0 && [sequence[1], sequence[0], sequence[2]]
						)
                    }
                >
                    {items[seq]}
                </div>
            ))}
        </div>
    );
};

export default SwipableCarousal;
