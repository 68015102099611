import React, { useRef, useState, useEffect } from 'react';
import { TweenLite, TimelineLite, Power0, Power1, Power2 } from 'gsap/all';
gsap.registerPlugin(TweenLite, TimelineLite, Power0, Power1, Power2);
import Snap from 'snapsvg-cjs';
import { downloadFile } from 'helper/utils';
import {
	gsap,
	ScrollTrigger,
	Draggable,
	MotionPathPlugin,
	CustomEase
} from 'gsap/all';
gsap.registerPlugin(
	gsap,
	ScrollTrigger,
	Draggable,
	MotionPathPlugin,
	CustomEase
);
export const BookmarkSvg = () => {
	const [redd, setRedd] = useState(false);
	const svgRef = useRef();

	const handleBookmark = () => {
		let svg = svgRef.current;
		let svgPath = new Proxy(
			{
				y: 0,
				x: 0,
				b: 0
			},
			{
				set(target, key, value) {
					target[key] = value;
					let { y, x, b } = target;
					if (y !== null && x != null && b != null) {
						svg.innerHTML =
							'<path d="M8 4H24V5C24 7 ' +
							(24 + x) +
							' ' +
							(8 + y) +
							' ' +
							(24 + x) +
							' ' +
							(11 + y) +
							'C' +
							(24 + x) +
							' ' +
							(13 + y) +
							' ' +
							(24 + b) +
							' 25 ' +
							(24 + b) +
							' 27.5V28L' +
							(16 + b) +
							' 24L' +
							(8 + b) +
							' 28V27.5C' +
							(8 + b) +
							' 25 ' +
							(8 + x) +
							' ' +
							(13 + y) +
							' ' +
							(8 + x) +
							' ' +
							(11 + y) +
							' C' +
							(8 + x) +
							' ' +
							(8 + y) +
							' 8 7 8 5V4Z" />';
					}
					return true;
				},
				get(target, key) {
					return target[key];
				}
			}
		);

		gsap.to(svgPath, {
			keyframes: [
				{
					x: 2.5,
					duration: 0.125
				},
				{
					y: 10,
					b: 0.5,
					duration: 0.2
				},
				{
					b: 2,
					x: 2,
					duration: 0.125
				},
				{
					b: 1,
					x: 0,
					duration: 0.125
				},
				{
					b: 0,
					duration: 0.175
				}
			],
			onComplete() {
				svgPath.y = 0;
				svgPath.x = 0;
				svgPath.b = 0;
			}
		});
		setRedd((prevState) => !prevState);
	};

	return (
		<div>
			<button
				className={'bookmark' + (redd ? ' active' : '')}
				onClick={handleBookmark}
			>
				<svg viewBox="0 0 32 32" ref={svgRef}>
					<path d="M8 4H24V28L16 24L8 28V4Z" />
				</svg>
			</button>
		</div>
	);
};

export const DowanlodButton = ({ selectedMedia }) => {
	const btnRef = useRef();
	const dotRef = useRef();
	const textRef = useRef();
	const mainCircleRef = useRef();
	const subCircleRef = useRef();
	const mainCircleFillRef = useRef();
	const arrowRef = useRef();
	const rectRef = useRef();
	const [downloading, setdownloading] = useState(false);
	const [points, setpoints] = useState([]);

	const onDownload = () => {
		if (downloading) return;
		setdownloading((prevState) => !prevState);
		let tl;

		const restart = () => {
			setTimeout(() => {
				try {
					textRef.current.textContent = 'download';
					TweenLite.set(textRef.current, { x: 0 });
					tl.pause();
					setdownloading(false);
					tl.seek(0, false);
					downloadFile(selectedMedia);
				} catch (err) {
					console.log('err', err);
				}
			}, 2000);
		};

		function changeText() {
			textRef.current.textContent = 'open';
			TweenLite.set(textRef.current, { x: -5 });
		}

		let downloadTime = Math.random() * 0.5 + 0.7;
		tl = new TimelineLite({ onComplete: restart });

		tl.restart()
			.play()
			.to(
				arrowRef.current,
				0.35,
				{
					y: 2.5,
					ease: CustomEase.create(
						'custom',
						'M0,0,C0.042,0.14,0.374,1,0.5,1,0.64,1,0.964,0.11,1,0'
					)
				},
				'click'
			)
			.to(
				textRef.current,
				0.3,
				{
					svgOrigin: '55% 35%',
					scale: 0.77,
					ease: CustomEase.create(
						'custom',
						'M0,0,C0.042,0.14,0.374,1,0.5,1,0.64,1,0.964,0.11,1,0'
					)
				},
				'click+=.05'
			)
			.set(
				subCircleRef.current,
				{ fillOpacity: 1, strokeOpacity: 1 },
				'squeeze-=.3'
			)
			.to(
				subCircleRef.current,
				0.35,
				{ fillOpacity: 0, ease: Power1.easeInOut },
				'squeeze-=.3'
			)
			.to(
				subCircleRef.current,
				0.45,
				{
					attr: { r: 13 },
					strokeOpacity: 0,
					class: '+=strokeW',
					ease: Power0.easeNone
				},
				'squeeze-=.3'
			)
			.to(
				btnRef.current,
				0.7,
				{
					attr: {
						d: 'M50,25 h0 a10,10 0 0,1 10,10 a10,10 0 0,1 -10,10 s0,0 0,0  a10,10 0 0,1 -10,-10 a10,10 0 0,1 10,-10 h0'
					}
				},
				'squeeze'
			)
			.to(
				[
					mainCircleRef.current,
					mainCircleFillRef.current,
					rectRef.current,
					arrowRef.current
				],
				0.7,
				{ x: 30 },
				'squeeze'
			)
			.to(rectRef.current, 0.7, { fill: '#fff', rotation: 270 }, 'squeeze')
			.to(
				textRef.current,
				0.3,
				{ autoAlpha: 0, y: 7, onComplete: changeText },
				'squeeze'
			)
			.to(
				arrowRef.current,
				0.7,
				{
					attr: {
						d: 'M20,39 l3.5,-3.5 l-3.5,-3.5 M20,39 l-3.5,-3.5 l3.5,-3.5 M20,39 l0,0'
					},
					transformOrigin: '50% 50%',
					rotation: 225
				},
				'squeeze'
			)
			.to(dotRef.current, 0.4, {
				attr: { r: 1.5 }
			})
			.set(subCircleRef.current, {
				DrawSVG: 0,
				strokeOpacity: 1,
				transformOrigin: '50% 50%',
				x: 30,
				rotation: -90,
				attr: { r: 9.07 }
			})
			.to(
				subCircleRef.current,
				downloadTime,
				{ DrawSVG: '102%', ease: Power2.easeIn },
				'fill+=.02'
			)
			.to(
				dotRef.current,
				downloadTime,
				{
					bezier: { type: 'cubic', values: points },
					attr: { r: 2.7 },
					ease: Power2.easeIn
				},
				'fill'
			)
			.to(
				'.gradient',
				downloadTime,
				{ attr: { offset: '0%' }, ease: Power2.easeIn },
				'fill'
			)
			.to(
				dotRef.current,
				0.44,
				{ fill: '#02fc86', y: -22, ease: Power1.easeOut },
				'stretch-=.01'
			)
			.to(
				dotRef.current,
				0.27,
				{
					transformOrigin: '50% 50%',
					scaleX: 0.5
				},
				'stretch+=.04'
			)
			.to(dotRef.current, 0.3, { scaleY: 0.6 }, 'stretch+=.31')
			.to(
				dotRef.current,
				0.44,
				{ scaleX: 0.4, y: 22, ease: Power2.easeIn },
				'stretch+=.45'
			)
			.to(
				[
					mainCircleRef.current,
					subCircleRef.current,
					arrowRef.current,
					rectRef.current,
					mainCircleFillRef.current
				],
				0.33,
				{ opacity: 0, ease: Power2.easeOut },
				'stretch+=.2'
			)
			.to(
				btnRef.current,
				0.4,
				{
					attr: {
						d: 'M50,25 h20 a10,10 0 0,1 10,10 a10,10 0 0,1 -10,10 s-20,0 -40,0 a10,10 0 0,1 -10,-10 a10,10 0 0,1 10,-10 h20'
					},
					ease: Power1.easeOut
				},
				'stretch+=.2'
			)
			.set(dotRef.current, { opacity: 0 }, 'stretch+=.875')
			.to(
				btnRef.current,
				0.01,
				{ stroke: '#02fc86', ease: Power2.easeIn },
				'stretch+=.87'
			)
			.to(
				btnRef.current,
				0.3,
				{
					attr: {
						d: 'M50,25 h20 a10,10 0 0,1 10,10 a12,12 0 0,1 -10,10.5 s-20,6 -40,0 a12,12 0 0,1 -10,-10.5 a10,10 0 0,1 10,-10 h20'
					},
					ease: CustomEase.create(
						'custom',
						'M0,0 C0.046,0.062 0.018,1 0.286,1 0.532,1 0.489,-0.206 0.734,-0.206 0.784,-0.206 0.832,-0.174 1,0'
					)
				},
				'stretch+=.869'
			)
			.to(textRef.current, 0.45, { autoAlpha: 1, y: 0 }, 'stretch+=.855');
	};

	useEffect(() => {
		let data = Snap.path.toCubic('M0,0 a9,9 0 0,1 0,18 a9,9 0 0,1 0,-18');
		let dataLen = data.length;
		let _points = [];

		for (let i = 0; i < dataLen; i++) {
			let seg = data[i];
			if (seg[0] === 'M') {
				let point = {};
				point.x = seg[1];
				point.y = seg[2];
				_points.push(point);
			} else {
				for (let i = 1; i < 6; i += 2) {
					let point = {};
					point.x = seg[i];
					point.y = seg[i + 1];
					_points.push(point);
				}
			}
		}

		setpoints(_points);

		TweenLite.set(rectRef.current, {
			transformOrigin: '50% 50%',
			rotation: 45
		});
	}, []);

	return (
		<>
			<button className="btn-dowanlod-style">
				{' '}
				<svg viewBox="0 0 100 50" width="620" height="310" fill="none">
					<circle
						ref={mainCircleRef}
						cx="20"
						cy="35"
						r="8.5"
						fill="#00cffc"
						className="mainCircle"
					></circle>
					<circle
						ref={mainCircleFillRef}
						cx="20"
						cy="35"
						r="8.05"
						stroke="#00cffc"
						strokeWidth=".9"
						fill="url(#gradient)"
						className="mainCircleFill"
					></circle>
					<rect
						ref={rectRef}
						x="17.5"
						y="32.5"
						width="5"
						height="5"
						stroke="none"
						fill="#00cffc"
						className="rect"
					></rect>
					<path
						ref={arrowRef}
						d="M20,39 l3.5,-3.5 l0,0 M20,39 l-3.5,-3.5 l0,0 M20,39 l0,-7.5"
						stroke="#fff"
						strokeLinecap="round"
						strokeWidth=".8"
						className="arrow"
					></path>
					<text
						ref={textRef}
						x="55"
						y="36.5"
						fill="#fff"
						textAnchor="middle"
						fontSize="11"
						fontFamily="Roboto"
						letterSpacing=".2"
						className="text"
					>
						download
					</text>

					<path
						ref={btnRef}
						d="M50,25 h30 a10,10 0 0,1 10,10 a10,10 0 0,1 -10,10 s-30,0 -60,0 a10,10 0 0,1 -10,-10 a10,10 0 0,1 10,-10 h30"
						stroke="#00cffc"
						strokeWidth=".7"
						fill="transparent"
						className="btn"
						onClick={onDownload}
					></path>
					<circle
						ref={subCircleRef}
						cx="20"
						cy="35"
						r="7.9"
						fill="#fff"
						fillOpacity="0"
						stroke="#fff"
						strokeWidth="1.6"
						strokeOpacity="0"
						className="subCircle"
					></circle>
					<circle
						ref={dotRef}
						cx="50"
						cy="26"
						r="0"
						fill="#fff"
						className="dot"
					></circle>
					<linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
						<stop offset="98%" className="gradient" stopColor="transparent" />
						<stop offset="98%" className="gradient" stopColor="#00afd3" />
					</linearGradient>
				</svg>
			</button>
		</>
	);
};
