import React from 'react';
import './style.css';
const ConfirmationPopup = ({ content, onClick, onCancel }) => {
    return (
        <>
            <div className="low-opacity"></div>
            <div className="confirmation-popup modal-background">
                <p>{content}</p>
                <div>
                    <button className="popup-btn" onClick={onCancel ? onCancel : onClick}>
						CANCEL
                    </button>
                    <button className="popup-btn blue-btn" onClick={onClick}>
						CONFIRM
                    </button>
                </div>
            </div>
        </>
    );
};

export default ConfirmationPopup;
