import axios from 'axios';
import { errorResponseMessage } from 'helper/utils';
import store from '../store';
import { SET_TOAST } from 'store/types/toast.type';

axios.defaults.baseURL = 'https://mediamagic.dev/api/v1/';
axios.interceptors.request.use(
	(config) => {
		const { headers } = config;
		headers['Accept'] = '*/*';
		headers['Content-Type'] = 'application/mediamagic.rest.v1+json';
		return config;
	},
	(err) => Promise.reject(err)
);
axios.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error?.response?.data?.error === 'not_signed_in') {
			store.dispatch({ type: 'LOGOUT' });
		} else {
			store.dispatch({
				type: SET_TOAST,
				payload: {
					status: 'error',
					message:
						errorResponseMessage(error?.response?.data || error) ||
						'Unexpected Error'
				}
			});
		}

		return Promise.reject(error);
	}
);

const http = axios;

export default http;
