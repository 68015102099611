import {
	DASHBOARD_REQUESTED,
	GET_DASHBOARD_SUCCESS,
	GET_DASHBOARD_FAILED
} from '../types/dashboard.type';

const intialState = {
	data: null,
	loading: false,
	error: null
};

const dashboardReducer = (state = intialState, action) => {
	switch (action.type) {
		case DASHBOARD_REQUESTED:
			return { ...state, loading: true };

		case GET_DASHBOARD_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case GET_DASHBOARD_FAILED:
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload
			};
		default:
			return state;
	}
};

export default dashboardReducer;
