import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersDataAction } from 'store/actions/users.action';
import { Table, Loading } from 'components';
import { data } from './sample-data';
import { aiTypes } from 'helper/constants';

const Users = () => {
	const dispatch = useDispatch();
	const { data: usersData } = useSelector((state) => state.users);
	const { data: browseAiData } = useSelector((state) => state.browseAi);
	const [modifiedUsersData, setModifiedUsersData] = useState(null);

	useEffect(() => {
		if (!usersData) dispatch(getUsersDataAction());
	}, []);

	useEffect(() => {
		if (usersData && !modifiedUsersData) {
			let tempData = [];
			usersData.map((item, i) => {
				tempData.push({
					id: item.id,
					model_name: item.name,
					job_id: item.job_id,
					...data[i % 10]
				});
			});
			setModifiedUsersData(tempData);
		}
	}, [usersData]);

	if (!modifiedUsersData) return <Loading />;
	return (
		<Table
			data={modifiedUsersData}
			filters={browseAiData}
			types={aiTypes}
			headers={[
				'User ID',
				'Model Name',
				'Job ID',
				'Submitted Date',
				'Queue Number',
				'AI Used',
				'Credits Used',
				'Time Elapsed',
				'Details'
			]}
		/>
	);
};

export default Users;
