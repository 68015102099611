import React, { useState } from 'react';
import { validators } from 'helper/validator';
import { EmailAccess, MessagePopup, SelectLang } from 'components';
import './style.css';
import rocket from 'assets/common/rocket.svg';

const ComingSoon = () => {
	const [email, setEmail] = useState('');
	const [messageContent, setMessageContent] = useState(false);
	const [openSelectLang, setOpenSelectLang] = useState(false);

	const selectLang = (email) => {
		let errorExist =
			validators.required(email, 'Email is required') ||
			validators.email(email);
		if (errorExist) setMessageContent(errorExist);
		else setOpenSelectLang(true);
	};

	const verifySelectedLang = (selected) => {
		if (!selected) return setOpenSelectLang(false);
		if (selected && !selected.includes(true))
			return setMessageContent('Please selected at least one language!');
		setOpenSelectLang(false);
		return setMessageContent(
			`Thank you for requesting early access: ${email} will be notified of updates!`
		);
	};
	return (
		<div className="coming-soon">
			{messageContent && (
				<MessagePopup
					content={messageContent}
					onClick={() => setMessageContent(!messageContent)}
				/>
			)}

			{openSelectLang && (
				<SelectLang onClick={(selected) => verifySelectedLang(selected)} />
			)}
			<div className="content-container">
				<img src={rocket} className="rocket" />
				<h1 className="sfprodisplay-bold-white-77px">PAGE COMING SOON</h1>
				<p className="sfprodisplay-regular-white-18px">
					Page is on its way! If you want to be notified when the page goes
					life,
					<br />
					please subscribe to our mailing list below!
				</p>
				<EmailAccess
					email={email}
					onClick={(email) => selectLang(email)}
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>
		</div>
	);
};

export default ComingSoon;
