import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThirdPartyDataAction } from 'store/actions/media.action';
import useIntersectionObserver from 'hooks/InfiniteScroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { MasonryLayout, SearchInput } from 'components';
import { DragDropModal, MediaTaggingModal } from 'modals';
import './style.css';
import hardCodedMediaData from 'assets/mediaData.json';
import uploadIcon from 'assets/common/browse.svg';

const SelectMediaModal = ({ data, onClose, selectMedia, selectedAI }) => {
	const dispatch = useDispatch();
	const { thirdpartyData } = useSelector((state) => state.media);
	const { isPublicAccess } = useSelector((state) => state.auth);

	const [page, setPage] = useState(1);
	const [search, setSearch] = useState(null);
	const [activeCatgory, setActiveCategory] = useState(['All Media']);
	const [dragDropVisible, setDragDropVisible] = useState(false);
	const [isPostUpload, setPostUpload] = useState(false);
	const mediaStartingDiv = useRef();
	const [ref, { entry }] = useIntersectionObserver();
	const isVisible = entry && entry.isIntersecting;

	useEffect(() => {
		if (!thirdpartyData) dispatch(getThirdPartyDataAction(page));
	}, []);

	useEffect(() => {
		if (isVisible) {
			dispatch(getThirdPartyDataAction(page + 1));
			setPage(page + 1);
		}
	}, [isVisible]);

	useEffect(() => {
		if (selectedAI) {
			let tempCategory;
			switch (selectedAI) {
				case 'colorx':
				case 'color change':
				case 'wct2':
					tempCategory = 'color swap';
					break;
				case 'fashionx':
					tempCategory = 'top model pose';
					break;
				case 'stylex':
				case 'makeupx':
					tempCategory = 'face';
					break;
				default:
					tempCategory = 'All Media';
			}
			setActiveCategory(tempCategory);
		}
	}, [selectedAI]);

	return (
		<div className="select-media-modal animate-appear modal-background">
			{dragDropVisible && (
				<DragDropModal
					onClose={() => setDragDropVisible(false)}
					afterUploadCompleted={(files) => {
						setPostUpload(JSON.parse(JSON.stringify(files)));
						setDragDropVisible(false);
					}}
				/>
			)}
			{isPostUpload && (
				<MediaTaggingModal
					onClose={() => setPostUpload(false)}
					files={isPostUpload}
				/>
			)}
			<div
				className="sfprodisplay-medium-white-18px"
				style={{ marginBottom: 20 }}
			>
				Select Media
			</div>
			<div className="closemodalbutton" onClick={onClose}>
				<div></div>
				<div></div>
			</div>
			<div className="header-container">
				{!isPublicAccess && (
					<label
						htmlFor="upload-media"
						style={{ cursor: 'pointer' }}
						onClick={() => setDragDropVisible(true)}
					>
						<div className="upload sfprodisplay-regular-white-7px">
							<img src={uploadIcon} />
							Upload
						</div>
					</label>
				)}
				<SearchInput
					style={{ width: '100%' }}
					onChange={(e) => {
						dispatch(getThirdPartyDataAction(1, e.target.value));
						mediaStartingDiv.current.scrollIntoView();
						setSearch(e.target.value);
					}}
				/>
			</div>

			<MasonryLayout
				containerClass="select-media-container"
				startingElementRef={mediaStartingDiv}
				columnClass="select-media-column"
			>
				{!search &&
					data?.map(
						(item, i) =>
							item.media_key.substr(item.media_key?.length - 4) !== 'json' && (
								<div
									key={`media-item-${i}`}
									style={{ cursor: 'pointer' }}
									onClick={() => selectMedia(item)}
								>
									<LazyLoadImage
										style={{ width: '100%' }}
										src={item.media_key}
									/>
								</div>
							)
					)}
				{!search &&
					hardCodedMediaData[activeCatgory].map((item, i) => (
						<div
							key={`hc-media-item-${i}`}
							style={{ cursor: 'pointer' }}
							onClick={() =>
								selectMedia({ media_key: item?.modelInput || item })
							}
						>
							<LazyLoadImage
								style={{ width: '100%' }}
								src={item?.modelInput || item}
							/>
						</div>
					))}
				{thirdpartyData?.map((item, i) => (
					<div
						key={`3rd-media-item-${i}`}
						style={{ cursor: 'pointer' }}
						onClick={() => selectMedia({ media_key: item.urls.regular })}
					>
						<LazyLoadImage style={{ width: '100%' }} src={item.urls.thumb} />
					</div>
				))}
				{thirdpartyData?.length > 0 && <div ref={ref}>test</div>}
			</MasonryLayout>
		</div>
	);
};

export default SelectMediaModal;
