import React from 'react';
import close from 'assets/common/close.svg';
const Tag = ({ text, remove, disabled }) => {
	const handleOnRemove = (e) => {
		e.stopPropagation();
		remove(text);
	};

	return (
		<span className="tag">
			<span className="sfprodisplay-medium-white-16px">#{text}</span>
			{!disabled && (
				<div onClick={handleOnRemove}>
					<img src={close} className="remove-icon" />
				</div>
			)}
		</span>
	);
};

export default Tag;
