import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Resizer from 'react-image-file-resizer';
import {
	gsap,
	ScrollTrigger,
	Draggable,
	MotionPathPlugin,
	CustomEase
} from 'gsap/all';
gsap.registerPlugin(
	gsap,
	ScrollTrigger,
	Draggable,
	MotionPathPlugin,
	CustomEase
);
import { getModelsAction } from 'store/actions/browseai.action';
import {
	getMediaDataAction,
	uploadMediaAction
} from 'store/actions/media.action';
import { createDeploymentAction } from 'store/actions/users.action';
import { copyToClipboard, dataURIToBlob, notifyToast } from 'helper/utils';
import { Loading, SketchCanvas } from 'components';
import { MediaTaggingModal, MediaModal } from 'modals';
import ModelsList from './components/ModelsList/ModelsList';
import SelectMediaModal from './components/SelectMediaModal/SelectMediaModal';
import AdjustSetting from './components/AdjustSetting/AdjustSetting';
import rightArrow from 'assets/common/arrow_right.svg';
import copy from 'assets/common/copy.svg';
import './style.css';
import Loader from 'pages/Media/loader';
import { BookmarkSvg, DowanlodButton } from './components/Svg/SvgComponent';

const Editor = ({
	selectedMedia,
	tags,
	name,
	goNext,
	goBack,
	imgLoading,
	selectedMediaIndex,
	mediaSelectedAI
}) => {
	const dispatch = useDispatch();
	const { data: models, loading: browseAiLoading } = useSelector(
		(state) => state.browseAi
	);
	const { data: mediaList } = useSelector((state) => state.media);
	const { loading } = useSelector((state) => state.users);
	const { isPublicAccess } = useSelector((state) => state.auth);
	const canvasRef = useRef();
	const [activeMedia, setActiveMedia] = useState(selectedMedia);
	const [oldActiveMedia, setOldActiveMedia] = useState(null);
	const [mainLoading, setMainLoading] = useState(false);
	const [selectedAI, setSelectedAI] = useState(mediaSelectedAI);
	const [displayedMedia, setDisplayedMedia] = useState(null);
	const [mediaTags, setMediaTags] = useState(null);
	const [selectMediaModalVisible, setSelectMediaModalVisible] = useState(false);
	const [fontWidth, setFontWidth] = useState(50);
	const [enableSketch, setEnableSketch] = useState(false);
	const [color, setColor] = useState('#000000b0');
	const [imageDimensions, setImageDimensions] = useState(null);
	const [actualImageDimensions, setActualImageDimensions] = useState(null);

	useEffect(() => {
		if (!models && !browseAiLoading) dispatch(getModelsAction());
		if (!mediaList && !isPublicAccess) dispatch(getMediaDataAction());
	}, []);

	useEffect(() => {
		if (!enableSketch) setActiveMedia(selectedMedia);
		if (oldActiveMedia) setOldActiveMedia(null);
	}, [selectedMedia]);

	useEffect(() => {
		if (activeMedia) getImageDimensions();
	}, [activeMedia]);

	const getImageDimensions = () => {
		const img = new Image();

		img.onload = function () {
			var height = img.height;
			var width = img.width;
			setImageDimensions({ height, width });
		};

		img.src = activeMedia;
	};

	useEffect(() => {
		document.addEventListener('keydown', downHandler);
		return () => {
			document.removeEventListener('keydown', downHandler);
		};
	}, []);

	const downHandler = (e) => {
		if (e.keyCode === 39) goNext();
		else if (e.keyCode === 37) goBack();
	};
	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				imageDimensions.width,
				imageDimensions.height,
				'PNG',
				100,
				0,
				(uri) => {
					resolve(uri);
				},
				'blob',
				imageDimensions.width - 0.5,
				imageDimensions.height - 0.5
			);
		});

	const exportMask = (filledColor, callback = null) => {
		if (!canvasRef.current)
			return notifyToast('error', 'please add mask over selected image');
		canvasRef.current?.prepareMask(filledColor, async (paths) => {
			if (paths.length === 0) {
				return notifyToast('error', 'please add mask over selected image');
			}
			setMainLoading(true);

			const file = canvasRef.current.toDataURL({});
			const blobFile = dataURIToBlob(file);
			const resizedBlobFile = await resizeFile(blobFile);
			canvasRef.current._backgroundColor('transparent');
			uploadFile(resizedBlobFile, 'mask', 'image.png', (maskPath) => {
				setMainLoading(false);
				canvasRef.current.clear();
				callback && callback(maskPath);
			});
		});
	};

	const runDeployment = (
		{ filesList, stringsList, floatsList, masksList, numbersList },
		callback = null
	) => {
		const payload = {
			name: selectedAI.name,
			model_id: selectedAI.id,
			args: {
				...filesList.reduce(
					(obj, item) =>
						Object.assign(obj, {
							[item.key]: item.path
						}),
					{}
				),
				...masksList.reduce(
					(obj, item) =>
						Object.assign(obj, {
							[item.key]: item.path
						}),
					{}
				),
				...stringsList.reduce(
					(obj, item) =>
						Object.assign(obj, {
							[item.key]: item.value
						}),
					{}
				),
				...floatsList.reduce(
					(obj, item) =>
						Object.assign(obj, {
							[item.key]: item.value
						}),
					{}
				),
				...numbersList.reduce(
					(obj, item) =>
						Object.assign(obj, {
							[item.key]: item.value
						}),
					{}
				)
			}
		};

		dispatch(
			createDeploymentAction(payload, (res) => {
				if (res?.status === 'complete' && res.file) {
					if (res?.suggestedModels)
						if (!tags || isPublicAccess)
							setDisplayedMedia({
								media_key: res?.file,
								suggestedModels: res?.suggestedModels,
								imageTags: res?.imageTags
							});
						else
							setMediaTags({
								id: activeMedia
									.split('https://cdn.mediamagic.dev/media/')[1]
									.split('.')[0],
								url: activeMedia,
								tags: [...tags, ...res?.imageTags, ...res?.suggestedModels]
							});
					else {
						setOldActiveMedia(activeMedia);
						setActiveMedia(res?.file);
					}
				}
				callback && callback();
			})
		);
	};

	const uploadFile = (
		file,
		type = 'image',
		fileName = null,
		callback = null
	) => {
		const formData = new FormData();
		if (fileName) formData.append('media', file, fileName);
		else formData.append('media', file);
		dispatch(
			uploadMediaAction(formData, [type], true, (res) =>
				callback ? callback(res) : dispatch(getMediaDataAction())
			)
		);
	};
	return (
		<div className="editor-container">
			{(loading || mainLoading || (!imageDimensions && activeMedia)) && (
				<Loading />
			)}
			{displayedMedia && (
				<MediaModal
					data={displayedMedia}
					onClose={() => setDisplayedMedia(null)}
				/>
			)}
			{mediaTags && (
				<MediaTaggingModal
					onClose={() => setMediaTags(false)}
					files={[mediaTags]}
					single={true}
					hideDeleteBtn={true}
					selectedMediaIndex={selectedMediaIndex}
				/>
			)}
			{selectMediaModalVisible && (
				<SelectMediaModal
					data={mediaList}
					selectedAI={selectedAI?.name.toLowerCase()}
					onClose={() => setSelectMediaModalVisible(false)}
					selectMedia={(selected) => {
						setActiveMedia(selected.media_key);
						setSelectMediaModalVisible(false);
						if (enableSketch) {
							setEnableSketch(false);
						}
						if (oldActiveMedia) setOldActiveMedia(null);
					}}
				/>
			)}
			<div className="left-section">
				<div className="header-container">
					<h2 className="sfprodisplay-medium-white-16px">{name}</h2>
					<div
						style={{ cursor: 'pointer' }}
						onClick={() => setSelectMediaModalVisible(true)}
					>
						<h2 className="sfprodisplay-bold-white-14px">Select New Media</h2>
					</div>
				</div>
				<div className="media-container">
					{!enableSketch ? (
						<div className="carousal-container">
							<img
								className="left-arrow"
								src={rightArrow}
								onClick={() => goBack()}
							/>
							{imgLoading == true ? (
								<Loader />
							) : (
								<img
									src={activeMedia}
									className="Carousel-img"
									onLoad={(e) => {
										setActualImageDimensions({
											height: e.target.clientHeight,
											width: e.target.clientWidth
										});
									}}
								/>
							)}

							<BookmarkSvg />
							<img
								className="right-arrow"
								src={rightArrow}
								onClick={() => goNext()}
							/>
						</div>
					) : (
						actualImageDimensions && (
							<div className="sketch-container">
								<SketchCanvas
									lineWidth={fontWidth}
									image={activeMedia}
									color={color}
									canvasRef={canvasRef}
									loading={mainLoading}
									imageDimensions={actualImageDimensions}
								/>
							</div>
						)
					)}
				</div>
				<div className="btns-container">
					<DowanlodButton selectedMedia={activeMedia} />

					<button
						className="copy-btn"
						onClick={() => copyToClipboard(activeMedia)}
					>
						<img src={copy} />
						Copy Media Url
					</button>
					{tags && !isPublicAccess && (
						<button
							className="tag-btn"
							onClick={() =>
								setMediaTags({
									id: activeMedia
										.split('https://cdn.mediamagic.dev/media/')[1]
										.split('.')[0],
									url: activeMedia,
									tags
								})
							}
						>
							<div>#</div>
							Update Tags
						</button>
					)}
				</div>
			</div>
			<div className="right-section">
				<h2 className="sfprodisplay-bold-white-16px">
					{selectedAI ? 'Adjust Settings' : 'Available AI Models'}
				</h2>
				{!selectedAI ? (
					<ModelsList
						models={models}
						setSelectedAI={(item) => setSelectedAI(item)}
						tags={tags}
					/>
				) : (
					<AdjustSetting
						selectedAI={selectedAI}
						setMainLoading={setMainLoading}
						setFontWidth={(val) => setFontWidth(val)}
						fontWidth={fontWidth}
						actualImageDimensions={actualImageDimensions}
						setEnableSketch={() => setEnableSketch(!enableSketch)}
						enableSketch={enableSketch}
						undoSelectedAI={() => {
							setSelectedAI(null);
							if (enableSketch) setEnableSketch(!enableSketch);
						}}
						mediaList={mediaList}
						activeMedia={oldActiveMedia || activeMedia}
						runDeployment={(params) => {
							runDeployment(params);
						}}
						setColor={(val) => setColor(val)}
						color={color}
						changeImage={(val) => {
							if (oldActiveMedia) setOldActiveMedia(null);
							setActiveMedia(val);
						}}
						storeMask={(filledColor, callback) =>
							exportMask(filledColor, callback)
						}
					/>
				)}
			</div>
		</div>
	);
};

export default Editor;
