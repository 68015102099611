import React from 'react';

const JsonFormatter = ({ header, data, space = 2, editable, divRef }) => {
	const spanEnd = '</span>';
	const keySpan = '<span class="__json-key__">';
	const valSpan = '<span class="__json-value__">';
	const strSpan = '<span class="__json-string__">';
	const booSpan = '<span class="__json-boolean__">';
	const regLine =
		/^( *)("[^"]+": )?("[^"]*"|[\w.+-]*)?([,[{]|\[\s*\],?|\{\s*\},?)?$/gm;

	const cleanData = () => {
		const text = JSON.stringify(data, null, space);

		if (!text) {
			return text;
		}

		return text
			.replace(/&/g, '&amp;')
			.replace(/\\"([^,])/g, '\\&quot;$1')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(regLine, (a, tab, key, value, separator) =>
				addStyle(tab, key, value, separator)
			);
	};

	const addStyle = (tab = '', key, value, separator = '') => {
		let payload = tab;
		if (key) {
			payload += '"' + keySpan + key.replace(/^"|":\s$/g, '') + spanEnd + '": ';
		}

		if (value) {
			if (value === 'true' || value === 'false') {
				payload += booSpan + value + spanEnd;
			} else {
				payload += (value[0] === '"' ? strSpan : valSpan) + value + spanEnd;
			}
		}

		return payload + separator;
	};
	return (
		<div>
			{header}
			<div
				ref={divRef}
				contentEditable={editable}
				dangerouslySetInnerHTML={{
					__html: `<pre>${cleanData()}</pre>`
				}}
				style={{ height: '95%', padding: 5 }}
			></div>
		</div>
	);
};

export default JsonFormatter;
