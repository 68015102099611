export const data = [
	{
		date: '2022-04-01',
		queue_no: 37,
		ai_used: ['Image Blending', 'Face Morphing', 'Facial Landmarks'],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		date: '2022-03-31',
		queue_no: 37,
		ai_used: ['Face Morphing', 'Facial Landmarks'],
		credit_used: 15,
		time_elapsed: 5.2
	},
	{
		date: '2022-03-29',
		queue_no: 37,
		ai_used: ['Image Blending', 'Face Morphing'],
		credit_used: 1,
		time_elapsed: 5.2
	},
	{
		date: '2022-03-28',
		queue_no: 37,
		ai_used: ['Face Morphing'],
		credit_used: 22,
		time_elapsed: 5.2
	},
	{
		date: '2022-03-25',
		queue_no: 37,
		ai_used: ['Image Blending', 'Face Morphing', 'Facial Landmarks'],
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		date: '2022-03-25',
		queue_no: 37,
		ai_used: ['Image Blending', 'Face Morphing', 'Facial Landmarks'],
		credit_used: 19,
		time_elapsed: 5.2
	},
	{
		date: '2022-03-21',
		queue_no: 37,
		ai_used: ['Face Morphing', 'Facial Landmarks'],
		credit_used: 50,
		time_elapsed: 5.2
	},
	{
		date: '2022-03-19',
		queue_no: 37,
		ai_used: ['Image Blending', 'Face Morphing'],
		credit_used: 30,
		time_elapsed: 5.2
	},
	{
		date: '2022-03-18',
		queue_no: 37,
		ai_used: ['Face Morphing'],
		credit_used: 100,
		time_elapsed: 5.2
	},
	{
		date: '2022-03-15',
		queue_no: 37,
		ai_used: ['Image Blending', 'Face Morphing', 'Facial Landmarks'],
		credit_used: 300,
		time_elapsed: 5.2
	}
];
