export const MEDIA_REQUESTED = 'MEDIA_REQUESTED';
export const GET_MEDIA_SUCCESS = 'GET_MEDIA_SUCCESS';
export const GET_MEDIA_FAILED = 'GET_MEDIA_FAILED';
export const ADD_MEDIA = 'ADD_MEDIA';
export const UPLOAD_MASK = 'UPLOAD_MASK';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAILED = 'DELETE_MEDIA_FAILED';
export const UPDATE_MEDIA_SUCCESS = 'UPDATE_MEDIA_SUCCESS';
export const UPDATE_MEDIA_FAILED = 'UPDATE_MEDIA_FAILED';
export const THIRD_PARTY_REQUESTED = 'THIRD_PARTY_REQUESTED';
export const GET_THIRD_PARTY_SUCCESS = 'GET_THIRD_PARTY_SUCCESS';
export const GET_THIRD_PARTY_FAILED = 'GET_THIRD_PARTY_FAILED';
