import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import arrowRight from 'assets/common/arrow_right.svg';

const Card = ({
    header,
    subheader,
    contentTitle,
    content,
    beforePrice,
    price,
    afterPrice,
    signup,
    setActive = () => {},
    active,
    overrideClass = ''
}) => {
    return (
        <div
            className={`card-container ${overrideClass} ${active && !signup ? 'linearBackground' : 'linearBackground-small'}`}
            style={{
                borderColor: active && '#fff'
            }}
            onClick={setActive}
        >
            <div className="card-header">{header}</div>
            {!signup && (
                <div className="price-container">
                    <div className="card-price">$</div>
                    <div className="card-number">{price}</div>
                    <div className="card-mo">/mo</div>
                </div>
            )}
            {subheader && <p className="sfprodisplay-regular-white-18px margin-bottom">{subheader}</p>}
            {contentTitle && <p className="sfprodisplay-regular-white-16px margin-bottom">{contentTitle}</p>}
            <ul className="content-container">
                {content.map((item, i) => (
                    <li key={i} className="sfprodisplay-regular-white-16px">
                        {item}
                    </li>
                ))}
            </ul>
            <div className="lower-container">
                {beforePrice &&
					beforePrice.map((item, i) => (
					    <div key={i} className="sfprodisplay-medium-white-16px">
					        {item}
					    </div>
					))}

                {signup && (
                    <div className="price-container">
                        <div className="card-price">$</div>
                        <div className="card-number">{price}</div>
                        <div className="card-mo">/mo</div>
                    </div>
                )}
                {afterPrice && <p className="sfprodisplay-regular-white-16px margin-bottom">{afterPrice}</p>}
                {signup && (
                    <Link to="/auth/register">
                        <button className="button">
                            <div className="sfprodisplay-heavy-white-16px">SIGN UP</div>
                            <img src={arrowRight} />
                        </button>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default Card;
