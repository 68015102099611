import React, { useState } from 'react';
import { ProgressBar } from 'components';
import './style.css';
import calendarDate from 'assets/common/calendar_date.svg';

const Filter = ({ onClose, onFilter }) => {
	const [from, setFrom] = useState('2021-12-31');
	const [to, setTo] = useState('2023-12-31');
	const [progressValue, setProgressValue] = useState(145);
	return (
		<div className="filter-container">
			<div className="overlap-group3">
				<div className="filter-flex-row">
					<div className="filter-flex-col">
						<div className="filter-text-1 sfprodisplay-medium-white-16px">
							Filters
						</div>
						<div className="overlap-group4">
							<div className="text sfprodisplay-medium-white-16px">
								Date Range
							</div>
							<img className="calendar" src={calendarDate} />
						</div>
					</div>
					<div
						className="filter-closemodalbutton"
						onClick={() => onClose(null)}
					>
						<div className="filter-overlap-group">
							<div className="rectangle-2285"></div>
							<div className="rectangle-2286"></div>
						</div>
					</div>
				</div>
				<div className="overlap-group6 sfprodisplay-medium-white-14px">
					<div className="overlap-group1-2 phoneContainer">
						<input
							className="calender"
							type="date"
							defaultValue={from}
							onChange={(e) => setFrom(e.target.value)}
						/>
					</div>
					<div className="filter-overlap-group1 phoneContainer">
						<input
							className="calender"
							type="date"
							defaultValue={to}
							onChange={(e) => setTo(e.target.value)}
						/>
					</div>

					<div className="text-2">-</div>
				</div>
				<ProgressBar
					title="Credits Used"
					progressValue={progressValue}
					setProgressValue={(value) => setProgressValue(value)}
					min={0}
					max={300}
				/>

				<div
					className="filter-overlap-group2"
					onClick={() => onFilter({ credit: progressValue, from, to })}
				>
					FILTER
				</div>
			</div>
		</div>
	);
};

export default Filter;
