import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, SwipableCarousal } from 'components';
import { enterprise, desktopContent, benefitTable } from './pricing-data';
import './style.css';
import cylinder from 'assets/pricing/cylinder.png';
import arrowRight from 'assets/common/arrow_right.svg';
import supertoroid from 'assets/pricing/supertoroid.png';
import supertoroid2 from 'assets/pricing/supertoroid-2.png';
import roundcube from 'assets/pricing/roundcube.png';
import tick from 'assets/common/tick-2.svg';
import arrowLeftWhite from 'assets/common/left_arrow_white.svg';
import rightArrow from 'assets/common/arrow_right.svg';

const Pricing = () => {
	const [activeOffer, setActiveOffer] = useState(1);

	const items = [
		<Card
			key={0}
			header="FREE FOREVER"
			subheader="Discover, test, be amazed and join the Synthetic Media revolution. Today is the first day of your future."
			contentTitle="Free access to:"
			content={[
				'Basic Docs',
				'Media Magic Discord chat',
				'Enhanced APIs',
				'Full Archive Data'
			]}
			price="0"
			afterPrice="Up to 2 Apps andd  1 User"
			signup
			active={activeOffer === 0}
			setActive={() => setActiveOffer(0)}
		/>,
		<Card
			key={1}
			header="COMMUNITY"
			subheader="Join the Community. For less than a latte or beer a month, Everything in Free, plus"
			contentTitle="Community access to:"
			content={[
				'Priority Rendering',
				'2x Higher Rate Limits',
				'Premium Docs',
				'Coding Examples on how to get started',
				'Parity Trace and Get Debug',
				'Enhanced Support',
				'Live tutorials',
				'Submit feature requests',
				'Submit custom model'
			]}
			beforePrice={['1,000 Octane monthly credits', '5 Photos | $1 /credit']}
			price="7"
			afterPrice="Up to 5 Users and 10 Apps"
			signup
			active={activeOffer === 1}
			setActive={() => setActiveOffer(1)}
		/>,
		<Card
			key={2}
			header="PREMIUM"
			subheader="In publishing and graphic design, Lorem ipsum is a placeholder text commonly"
			contentTitle="Premium access to:"
			content={[
				'$20/hr AI Customization',
				'Enhanced APIs',
				'Full Archive Data',
				'Access to our AI Models',
				'Premium Support',
				'Code Access for models'
			]}
			beforePrice={[
				'25,000 Octane monthly credits',
				'200 Photos | $0.50 / credit'
			]}
			price="79"
			afterPrice="Up to Unlimited Users and Unlimited Apps"
			signup
			active={activeOffer === 2}
			setActive={() => setActiveOffer(2)}
		/>
	];

	return (
		<div className="pricing">
			<h1 className="text-9">Welcome to the Future</h1>
			<div className="text-10">Pick a Plan, Lets Go</div>
			<div className="text-11">
				Try Media Magic free, feel the power,
				<br />
				upgrade for more
			</div>
			<div className="swipe-container hidden-md">
				<img src={arrowLeftWhite} />
				<img src={arrowLeftWhite} />
				<div className="text-11">swipe to change plan</div>
				<img src={rightArrow} />
				<img src={rightArrow} />
			</div>
			<div className="offers-container hidden-sm">
				{items.map((item) => item)}
			</div>
			<div className=" offers-swiper-container hidden-md">
				<SwipableCarousal
					items={items}
					setActive={(val) => setActiveOffer(val)}
				/>
			</div>
			<div className="overlap-group24">
				<img className="cylinder-black-matte" src={cylinder} />
				<img className="super-toroid-black-matte" src={supertoroid2} />
				<img className="super-toroid-black-matte-1" src={supertoroid} />
				<img className="round-cube-black-matte hidden-sm" src={roundcube} />
				<div className="flex-col">
					<div className="sfprodisplay-bold-white-32px">ENTERPRISE ACCOUNT</div>
					<div
						className="sfprodisplay-medium-white-18px"
						style={{ marginTop: 15, lineHeight: 1.3 }}
					>
						For organizations requiring digital media at scale, including
						<br />
						enhanced features, secutiry, support, and services
					</div>
				</div>
				<div className="flex-row">
					{enterprise.map((item, i) => (
						<div key={i} className="enterprise-item">
							<img className="tick" src={tick} />
							<div className="sfprodisplay-medium-white-16px">{item}</div>
						</div>
					))}
				</div>

				<Link to="/contact-us">
					<div className="marketing-btn">
						<div className="contact-us ">CONTACT US</div>
					</div>
				</Link>
				<div className="flex-row hidden-sm">
					{desktopContent.map((item, i) => (
						<div key={i}>
							<div className="enterprise-item">
								<img className="tick" src={tick} />
								<div className="sfprodisplay-medium-white-16px">
									{item.header}
								</div>
							</div>
							<div className="sfprodisplay-medium-white-16px content-text">
								{item.content}
							</div>
						</div>
					))}
				</div>
			</div>
			<div style={{ width: '80%' }} className="hidden-sm">
				<table className="group-8564">
					<thead>
						<tr className="overlap-group22">
							<th
								className="sfprodisplay-heavy-white-26px"
								style={{ textAlign: 'left', paddingLeft: 25 }}
							>
								BENEFIT
							</th>
							<th className="sfprodisplay-heavy-white-26px">FREE</th>
							<th className="sfprodisplay-heavy-white-26px">COMMUNITY</th>
							<th className="sfprodisplay-heavy-white-26px">PREMIUM</th>
						</tr>
					</thead>
					<tbody>
						<tr style={{ height: 1 }} />
						{benefitTable?.map((item, i) => (
							<tr className="overlap-group3" key={i}>
								<td className="table-price-title sfprodisplay-medium-white-24px">
									{item.title}
								</td>
								<td className="sfprodisplay-medium-white-24px">
									{item.free && <img className="tick" src={tick} />}
								</td>
								<td className="sfprodisplay-medium-white-24px">
									{item.community && <img className="tick" src={tick} />}
								</td>
								<td className="sfprodisplay-medium-white-24px">
									{item.premium && <img className="tick" src={tick} />}
								</td>
							</tr>
						))}
						<tr className="overlap-group3">
							<td className="table-price-title sfprodisplay-medium-white-24px"></td>
							<td className="sfprodisplay-medium-white-24px">
								<Link to="/auth/register">
									<div className="marketingsignupbuttons-2">
										<div className="sfprodisplay-heavy-white-16px">
											FREE
											<br />
											SIGN UP
										</div>
										<img className="group-3-1" src={arrowRight} />
									</div>
								</Link>
							</td>
							<td
								className="sfprodisplay-medium-white-24px"
								style={{ padding: 20 }}
							>
								<Link to="/auth/register">
									<div className="marketingsignupbuttons-2">
										<div className="sfprodisplay-heavy-white-16px">
											Community
											<br />
											SIGN UP
										</div>
										<img className="group-3-1" src={arrowRight} />
									</div>
								</Link>
							</td>
							<td className="sfprodisplay-medium-white-24px">
								<Link to="/auth/register">
									<div className="marketingsignupbuttons-2">
										<div className="sfprodisplay-heavy-white-16px">
											Premium
											<br />
											SIGN UP
										</div>
										<img className="group-3-1" src={arrowRight} />
									</div>
								</Link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default Pricing;
