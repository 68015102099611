import React, { Component } from 'react';
import { Flipper } from 'react-flip-toolkit';
import DropdownContainer from './DropdownContainer';

export default class AnimatedNavbar extends Component {
	state = {
		activeIndices: []
	};

	resetDropdownState = (i) => {
		this.setState({
			activeIndices: typeof i === 'number' ? [i] : [],
			animatingOut: false
		});
		delete this.animatingOutTimeout;
	};

	onMouseEnter = (i) => {
		if (this.animatingOutTimeout) {
			clearTimeout(this.animatingOutTimeout);
			this.resetDropdownState(i);
			return;
		}
		if (this.state.activeIndices[this.state.activeIndices.length - 1] === i)
			return;

		this.setState((prevState) => ({
			activeIndices: prevState.activeIndices.concat(i),
			animatingOut: false
		}));
	};

	onMouseLeave = () => {
		this.setState({
			animatingOut: true
		});
		this.animatingOutTimeout = setTimeout(
			this.resetDropdownState,
			this.props.duration
		);
	};

	render() {
		const { duration, navbarConfig } = this.props;
		let CurrentDropdown;
		let PrevDropdown;
		let direction;

		const currentIndex =
			this.state.activeIndices[this.state.activeIndices.length - 1];
		const prevIndex =
			this.state.activeIndices.length > 1 &&
			this.state.activeIndices[this.state.activeIndices.length - 2];

		if (typeof currentIndex === 'number')
			CurrentDropdown = navbarConfig[currentIndex].dropdown;
		if (typeof prevIndex === 'number') {
			PrevDropdown = navbarConfig[prevIndex].dropdown;
			direction = currentIndex > prevIndex ? 'right' : 'left';
		}

		return (
			<Flipper
				flipKey={currentIndex}
				spring={duration === 300 ? 'noWobble' : { stiffness: 10, damping: 10 }}
			>
				<nav onMouseLeave={this.onMouseLeave}>
					<ul
						style={{
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						{navbarConfig.map((n, index) => {
							return (
								<li
									onMouseEnter={() => this.onMouseEnter(index)}
									onFocus={() => this.onMouseEnter(index)}
									key={`nav-${index}`}
									style={{ position: 'relative' }}
								>
									{n.item}
									<div
										style={{
											position: 'absolute',
											left: '50%',
											transform: 'translateX(-50%)',
											perspective: 1500
										}}
										onClick={() => this.onMouseLeave(index)}
									>
										{currentIndex === index && (
											<DropdownContainer
												direction={direction}
												animatingOut={this.state.animatingOut}
												duration={duration}
												containerStyle={n.containerStyle}
												caretStyle={n.caretStyle}
											>
												{CurrentDropdown}
												{PrevDropdown && PrevDropdown}
											</DropdownContainer>
										)}
									</div>
								</li>
							);
						})}
					</ul>
				</nav>
			</Flipper>
		);
	}
}
