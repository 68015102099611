import {
	JOBS_REQUESTED,
	GET_JOBS_SUCCESS,
	GET_JOBS_FAILED
} from '../types/jobs.type';

const intialState = {
	data: null,
	loading: false,
	error: null,
	length: null,
	completedJobsLength: null
};

const jobsReducer = (state = intialState, action) => {
	switch (action.type) {
		case JOBS_REQUESTED:
			return { ...state, loading: true };

		case GET_JOBS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				length: action.payload.length,
				completedJobsLength: action.payload.filter(
					(item) => item.status === 'complete'
				).length
			};
		case GET_JOBS_FAILED:
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload
			};
		default:
			return state;
	}
};

export default jobsReducer;
