import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteMediaAction,
	getMediaDataAction,
	getThirdPartyDataAction
} from 'store/actions/media.action';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Dropdown, Loading, MasonryLayout, AIDock } from 'components';
import { EditorModal } from 'modals';
import useIntersectionObserver from 'hooks/InfiniteScroll';
import hardCodedMediaData from 'assets/mediaData.json';
import './style.css';
import filter from 'assets/common/filter.svg';
import allMedia from 'assets/common/all_medias.svg';
import unsplash from 'assets/common/unsplash.svg';
import fashion from 'assets/common/fashion.svg';
import processing from 'assets/common/processings_icon.svg';
import face from 'assets/common/face.svg';
import swapColor from 'assets/common/swap-color.svg';
import photo from 'assets/common/photos_icon.svg';
import text from 'assets/common/text.svg';
import video from 'assets/common/videos_icon.svg';
import Siriwave from 'react-siriwave';

const headerContent = {
	'All Media': allMedia,
	unsplash: unsplash,
	'pixel+': photo,
	'color swap': swapColor,
	face: face,
	'fashion-top': fashion,
	text: text,
	background: photo,
	sky: photo,
	objects: processing
};
const Media = () => {
	const mediaUrl = new URLSearchParams(document.location.search).get(
		'mediaUrl'
	);
	const dispatch = useDispatch();
	const {
		data: mediaData,
		loading,
		thirdpartyData,
		newMediaAdded
	} = useSelector((state) => state.media);
	const { data: models } = useSelector((state) => state.browseAi);
	const { isPublicAccess } = useSelector((state) => state.auth);
	const search = useSelector((state) => state.header.search);
	const hardCodedMediaDataLength = hardCodedMediaData['All Media'].length;
	const [activeHover, setActiveHover] = useState(null);
	const [selectedMediaIndex, setSelectedMediaIndex] = useState(null);
	const [selectedHardCodedMedia, setSelectedHardCodedMedia] = useState(null);
	const [selected3rdPartyMedia, setSelected3rdPartyMedia] = useState(null);
	const [activeTab, setActiveTab] = useState('All Media');
	const [page, setPage] = useState(1);
	const [siriWave, showSiriWave] = useState(false);
	const [mediaSelectedAI, setMediaSelectedAI] = useState(null);
	const [loader, setLoader] = useState(false);

	const [ref, { entry }] = useIntersectionObserver();
	const isVisible = entry && entry.isIntersecting;
	useEffect(() => {
		if (!mediaData && !isPublicAccess) dispatch(getMediaDataAction());
		if (!thirdpartyData) dispatch(getThirdPartyDataAction(page));
	}, [isPublicAccess]);

	useEffect(() => {
		if (!isPublicAccess)
			dispatch(getMediaDataAction(activeTab === 'All Media' ? '' : activeTab));
	}, [activeTab]);

	useEffect(() => {
		if (search) {
			dispatch(getThirdPartyDataAction(1, search));
			window.scrollTo(0, 0);
		} else if (thirdpartyData && search === '')
			dispatch(getThirdPartyDataAction(page));
	}, [search]);

	useEffect(() => {
		if (isVisible) {
			dispatch(getThirdPartyDataAction(page + 1));
			setPage(page + 1);
		}
	}, [isVisible]);

	useEffect(() => {
		if (newMediaAdded && activeTab !== 'All Media') setActiveTab('All Media');
	}, [newMediaAdded]);

	useEffect(() => {
		if (mediaUrl) {
			setSelectedHardCodedMedia(mediaUrl);
			setSelectedMediaIndex(0);
		}
	}, []);

	const renderImage = (value) => {
		if (value.slice(-3) === 'mp4' || value.slice(-3) === 'mov') return video;
		else return value;
	};

	const onClickDropdown = (value, index) => {
		if (value === 'Hide') {
			mediaData.splice(index, 1);
			setActiveHover(null);
		} else {
			dispatch(
				deleteMediaAction(mediaData[index].id, () => {
					mediaData.splice(index, 1);
				})
			);
		}
	};
	const onClick3rdDropdown = (index) => {
		thirdpartyData.splice(index, 1);
		setActiveHover(null);
	};
	const goNext = () => {
		setLoader(true);
		setTimeout(() => {
			setLoader(false);
		}, 1000);

		if (
			(mediaData?.length || 0) +
				thirdpartyData.length +
				hardCodedMediaDataLength >
			selectedMediaIndex + 1
		)
			setSelectedMediaIndex(selectedMediaIndex + 1);
		if (
			selectedMediaIndex + 1 >= (mediaData?.length || 0) &&
			selectedMediaIndex + 1 <
				hardCodedMediaDataLength + (mediaData?.length || 0)
		) {
			setSelectedHardCodedMedia(
				hardCodedMediaData[activeTab][selectedMediaIndex + 1]
			);
		} else if (selectedMediaIndex + 1 >= (mediaData?.length || 0)) {
			setSelected3rdPartyMedia(
				thirdpartyData[selectedMediaIndex + 1 - hardCodedMediaDataLength].urls
					.regular
			);
		} else {
			if (selected3rdPartyMedia) setSelected3rdPartyMedia(null);
			if (selectedHardCodedMedia) setSelectedHardCodedMedia(null);
		}
	};

	const goBack = () => {
		if (selectedMediaIndex === 0) return;
		setLoader(true);
		setTimeout(() => {
			setLoader(false);
		}, 1000);
		setSelectedMediaIndex(selectedMediaIndex - 1);

		if (
			selectedMediaIndex - 1 >= (mediaData?.length || 0) &&
			selectedMediaIndex - 1 <
				hardCodedMediaDataLength + (mediaData?.length || 0)
		) {
			setSelectedHardCodedMedia(
				hardCodedMediaData[activeTab][selectedMediaIndex - 1]
			);
		} else if (selectedMediaIndex - 1 >= (mediaData?.length || 0)) {
			setSelected3rdPartyMedia(
				thirdpartyData[selectedMediaIndex - 1 - hardCodedMediaDataLength].urls
					.regular
			);
		} else {
			if (selected3rdPartyMedia) setSelected3rdPartyMedia(null);
			if (selectedHardCodedMedia) setSelectedHardCodedMedia(null);
		}
	};

	const renderImgTopHover = (i, internalImg = true) => (
		<div className="hover-top">
			<div className="img-title"></div>
			<div className="img-threedots">
				<Dropdown
					items={
						internalImg
							? [
									{ key: 0, value: 'Hide' },
									{ key: 1, value: 'Remove' }
							  ]
							: [{ key: 0, value: 'Hide' }]
					}
					menuclassName="bla"
					noValue
					handleChange={(e) => {
						if (internalImg) onClickDropdown(e.target.value, i);
						else onClick3rdDropdown(i);
					}}
				/>
			</div>
		</div>
	);

	const renderImgBottomHover = (tags, isUnsplash = null) => (
		<div className="hover-bottom">
			<div
				style={{
					flexDirection: 'column',
					alignItems: 'flex-start'
				}}
			>
				<div>
					{isUnsplash ? (
						<div className="icon-container">
							<img src={unsplash} width="15px" />
						</div>
					) : (
						tags?.map(
							(item, key) =>
								headerContent[item] && (
									<div className="icon-container" key={`${key}-icon`}>
										<img src={headerContent[item]} width="15px" height="15px" />
									</div>
								)
						)
					)}
				</div>
			</div>
		</div>
	);
	return (
		<div className="media">
			{((!mediaData && !isPublicAccess) || loading) && <Loading />}
			<div className="container">
				<div className="media-header hidden-sm">
					{Object.keys(headerContent).map((key, i) => (
						<div
							key={i}
							style={{ opacity: activeTab !== key && 0.6 }}
							onClick={() => setActiveTab(key)}
						>
							<img src={headerContent[key]} />
							<div className="sfprodisplay-bold-white-14px">{key}</div>
						</div>
					))}
				</div>
				<div className={siriWave == true ? 'siri-canvas' : 'siri-canvas-hide'}>
					<Siriwave style="ios9" amplitude="3" />
				</div>
				<AIDock
					showSiriWave={showSiriWave}
					models={models}
					setMediaSelectedAI={setMediaSelectedAI}
				/>
				<div className="media-header-mobile hidden-md">
					<div className="flex-row">
						{Object.keys(headerContent).map((key, i) => (
							<div
								key={i}
								className="overlap-group"
								style={{ filter: activeTab === key && 'invert(1)' }}
								onClick={() => setActiveTab(key)}
							>
								<div className="frame-8007">
									<img className="polygon-1" src={headerContent[key]} />
								</div>
								<div className="all-media">{key}</div>
							</div>
						))}
					</div>
					<div className="flex-row">
						<div className="headersearchinput">
							<input id="search" placeholder="Search Media..." />
						</div>
						<img className="frame-8478" src={filter} />
					</div>
				</div>

				<MasonryLayout columnClass="galleryContainer">
					{activeTab !== 'unsplash' &&
						!search &&
						mediaData?.map(
							(item, i) =>
								item.media_key.substr(item.media_key?.length - 4) !==
									'json' && (
									<div
										key={i}
										style={{ position: 'relative', cursor: 'pointer' }}
										className="item-container"
										onMouseEnter={() =>
											window.innerWidth > 800 ? setActiveHover(i) : {}
										}
										onMouseLeave={() =>
											window.innerWidth > 800 ? setActiveHover(null) : {}
										}
									>
										{activeHover === i && renderImgTopHover(i)}
										{activeHover === i && renderImgBottomHover(item.tags)}
										<LazyLoadImage
											className={`img ${activeHover === i ? 'scale-up' : ''}`}
											src={renderImage(item.media_key)}
											onClick={() => setSelectedMediaIndex(i)}
										/>
									</div>
								)
						)}
					{hardCodedMediaData[activeTab]?.map((item, i) => (
						<div
							key={`th-${i}`}
							className="item-container"
							onMouseEnter={() =>
								window.innerWidth > 800 ? setActiveHover(i + 1000) : {}
							}
							onMouseLeave={() =>
								window.innerWidth > 800 ? setActiveHover(null) : {}
							}
						>
							{activeHover === i + 1000 && renderImgTopHover(i, false)}
							{activeHover === i + 1000 && renderImgBottomHover([activeTab])}
							<LazyLoadImage
								className={`img ${activeHover === i + 1000 ? 'scale-up' : ''}`}
								src={renderImage(item?.original || item)}
								onClick={() => {
									setSelectedMediaIndex(i + (mediaData?.length || 0));
									setSelectedHardCodedMedia(item);
								}}
							/>
						</div>
					))}
					{(activeTab === 'All Media' || activeTab === 'unsplash') &&
						thirdpartyData?.map((item, i) => (
							<div
								key={`th-${i}`}
								className="item-container"
								onMouseEnter={() =>
									window.innerWidth > 800 ? setActiveHover(i + 2000) : {}
								}
								onMouseLeave={() =>
									window.innerWidth > 800 ? setActiveHover(null) : {}
								}
							>
								{activeHover === i + 2000 && renderImgTopHover(i, false)}
								{activeHover === i + 2000 &&
									renderImgBottomHover(null, 'unsplash')}
								<LazyLoadImage
									className={`img ${
										activeHover === i + 2000 ? 'scale-up' : ''
									}`}
									src={renderImage(item.urls.regular)}
									onClick={() => {
										setSelected3rdPartyMedia(item.urls.regular);
										setSelectedMediaIndex(
											i + (mediaData?.length || 0) + hardCodedMediaDataLength
										);
									}}
								/>
							</div>
						))}
				</MasonryLayout>
				{thirdpartyData?.length > 0 && <div ref={ref} />}
			</div>
			{(selectedMediaIndex !== null || mediaSelectedAI) && (
				<EditorModal
					selectedMedia={
						mediaSelectedAI
							? null
							: selected3rdPartyMedia ||
							  selectedHardCodedMedia?.modelInput ||
							  selectedHardCodedMedia ||
							  mediaData[selectedMediaIndex].media_key
					}
					mediaSelectedAI={mediaSelectedAI}
					tags={
						selectedMediaIndex + 1 <= (mediaData?.length || 0) &&
						mediaData[selectedMediaIndex].tags
					}
					onClose={() => {
						setSelectedMediaIndex(null);
						if (selected3rdPartyMedia) setSelected3rdPartyMedia(null);
						if (hardCodedMediaData) setSelectedHardCodedMedia(null);
						if (mediaSelectedAI) setMediaSelectedAI(null);
					}}
					selectedMediaIndex={selectedMediaIndex}
					goNext={() => goNext()}
					goBack={() => goBack()}
					imgLoading={loader}
				/>
			)}
		</div>
	);
};

export default Media;
