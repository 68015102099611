import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import start from 'assets/common/start.svg';
import arrowRight from 'assets/common/arrow_right.svg';
import rate from 'assets/common/rate.svg';
import like from 'assets/common/like.svg';
import article1 from 'assets/article/article1.png';
import article2 from 'assets/article/article2.png';

const ArticleV2 = () => {
	const [activeTab, setActiveTab] = useState(0);

	const handleScroll = (e) => {
		if (e.target.scrollTop) {
			document.getElementById('container').classList += ' postscroll-container';
		}
	};
	return (
		<div
			className="article-v2"
			id="container"
			onScroll={handleScroll}
			style={{ maxHeight: 730 }}
		>
			<Link to="/app/dashboard">
				<div
					className="group-47082 sfprodisplay-regular-white-12px"
					id="header"
				>
					<svg
						className="confirmbutton"
						width="10"
						height="17"
						viewBox="0 0 10 17"
						fill="#fff"
					>
						<path d="M0.753336 9.32407L7.27376 15.6823C7.73133 16.1365 8.41769 16.1365 8.87527 15.6823C9.33284 15.2281 9.33284 14.5469 8.87527 14.0927L3.26999 8.41575L8.87527 2.73877C9.33284 2.28461 9.33284 1.60337 8.87527 1.14921C8.64648 0.922133 8.41769 0.808594 8.07451 0.808594C7.73133 0.808594 7.50255 0.922133 7.27376 1.14921L0.753336 7.50744C0.295763 8.07513 0.295763 8.75637 0.753336 9.32407C0.753336 9.21053 0.753336 9.21053 0.753336 9.32407Z" />
					</svg>
					Back
				</div>
			</Link>
			<div className="overlap-group3">
				<div className="group-47059">
					<div className="img-container">
						<img src={article1} />
						<div className="overlap-group-5">
							<img className="polygon-39" src={start} />
						</div>
					</div>
					<div className="video-details hidden-prescroll">
						<div className="subheader">
							{['CHAPTERS', 'RELATED', 'MEDIA'].map((item, i) => (
								<div
									key={i}
									className={`item sfprodisplay-regular-white-12px ${
										i === activeTab ? 'active-tab' : ''
									}`}
									onClick={() => setActiveTab(i)}
								>
									{item}
								</div>
							))}
						</div>
						<div className="section sfprodisplay-regular-white-12px">
							CHAPTERS
						</div>
						<div>
							<div className="section-bar">
								00:12
								<img src={arrowRight} /> Background
								<button>
									<img src={start} /> SKIP TO CUE
								</button>
							</div>
							<div className="section-bar">
								01:30
								<img src={arrowRight} /> How it works
								<button>
									<img src={start} /> SKIP TO CUE
								</button>
							</div>
							<div className="section-bar">
								02:24
								<img src={arrowRight} /> Awesome media examples
								<button>
									<img src={start} /> SKIP TO CUE
								</button>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className="group-47071">
						<div className="frame-47058-1">
							<div className="face sfprodisplay-regular-white-12px">face</div>
						</div>
						<div className="frame-47059">
							<div className="ocr sfprodisplay-regular-white-12px">OCR</div>
						</div>
						<div className="frame-47060">
							<div className="weight sfprodisplay-regular-white-12px">
								weight
							</div>
						</div>
						<div className="group-47079">
							<div className="frame-47061">
								<div className="address sfprodisplay-regular-white-12px">
									5 min 33 secs
								</div>
							</div>
						</div>
					</div>
					<div className="overlap-group1-1">
						<h1 className="text-1">Face Morphing : Getting Started</h1>
						<div className="text-2">
							Here is an easy less than 10 minute tutorial to making your first
							Face Morph. Lets morph a clothing modeling into your favorite
							celebrity.
						</div>
					</div>
					<div className="posrscroll-content hidden-prescroll">
						<div className="divider"></div>
						<div className="specs">
							<div className="logo">Logo</div>
							<div className="memeking">THEMEMEKING</div>
							<div className="icon-text">OCTOBER 10, 2021</div>
							<div className="icon-text">
								<img src={like} /> 7,345 VIEWS
							</div>
							<div className="icon-text">
								<img src={like} />
								75 LIKES
							</div>
							<div className="icon-text">
								<img src={rate} />
								4.63 on 74 RATINGS
							</div>
						</div>
						<div className="specs-ext">
							<div className="like-container">
								<img src={like} /> 75
							</div>
							<div>
								RATEIT
								<img src={rate} style={{ marginLeft: 5 }} />
								<img src={rate} />
								<img src={rate} />
								<img src={rate} />
								<img src={rate} />
							</div>
						</div>
						<div className="sfprodisplay-medium-white-14px">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
							pharetra purus eleifend, commodo nulla sit amet, facilisis sem.
							Vivamus maximus ac justo ut feugiat. Phasellus finibus lacus ut
							rhoncus tincidunt. Suspendisse potenti. Aliquam eget quam eu leo
							tincidunt ultricies sed at quam. Cras enim lorem, dapibus at nunc
							ut, lacinia ullamcorper nisi. Proin porta, ipsum pulvinar aliquam
							vehicula, lectus massa rhoncus ipsum, quis aliquam purus arcu in
							nunc. Aliquam a erat sit amet sapien facilisis convallis non ac
							magna. Nam vitae sollicitudin nunc. Sed ac semper lorem.
							Suspendisse ut luctus sem. Vestibulum ante ipsum primis in
							faucibus orci luctus et ultrices posuere cubilia curae; Proin at
							pellentesque eros, ut blandit mi. Nam laoreet magna lacus, eu
							varius mi fermentum eget. Orci varius natoque penatibus et magnis
							dis parturient montes, nascetur ridiculus mus.
						</div>
						<div className="text-2">Background</div>
						<div className="divider"></div>
						<div className="sfprodisplay-medium-white-14px">
							Fusce elementum ante sed maximus viverra. Fusce vitae tempor
							purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus
							blandit. Sed ac mi arcu. Pellentesque interdum est nec justo
							vestibulum luctus. Ut vitae enim hendrerit urna semper tempus eget
							ac magna. Nullam sodales id tortor in blandit. Morbi tellus purus,
							vestibulum tristique odio ut, iaculis semper nisi. Sed eu rhoncus
							ligula. Etiam non sodales est. Mauris in est eget nibh rhoncus
							faucibus.
						</div>
						<br />
						<div className="sfprodisplay-medium-white-14px">
							Donec viverra, neque ut malesuada accumsan, dolor turpis ultrices
							augue, suscipit dapibus diam nibh quis ex. Cras a tincidunt orci.
							Ut sollicitudin tincidunt libero pretium dictum. Suspendisse
							potenti. Maecenas sit amet finibus ante. Sed ac fermentum lectus,
							convallis fermentum ligula. Duis placerat ac lectus vitae tempus.
							Vestibulum quis sem a justo rutrum lobortis in in odio. Cras
							tincidunt lorem neque, eget lobortis lacus porta placerat. Class
							aptent taciti sociosqu ad litora torquent per conubia nostra, per
							inceptos himenaeos. Aenean quis euismod nisi, et aliquet turpis.
							Pellentesque a nibh ullamcorper dolor placerat vehicula et eget
							libero. Nam rhoncus tempus enim, quis pulvinar nunc tincidunt
							eget. Nulla molestie pellentesque ante eget tincidunt. Suspendisse
							ac metus tellus.
						</div>
						<div className="text-2">How it works</div>
						<div className="divider"></div>
						<div className="sfprodisplay-medium-white-14px">
							Fusce elementum ante sed maximus viverra. Fusce vitae tempor
							purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus
							blandit. Sed ac mi arcu. Pellentesque interdum est nec justo
							vestibulum luctus. Ut vitae enim hendrerit urna semper tempus eget
							ac magna. Nullam sodales id tortor in blandit. Morbi tellus purus,
							vestibulum tristique odio ut, iaculis semper nisi. Sed eu rhoncus
							ligula. Etiam non sodales est. Mauris in est eget nibh rhoncus
							faucibus.
						</div>
						<br />
						<div className="sfprodisplay-medium-white-14px">
							Donec viverra, neque ut malesuada accumsan, dolor turpis ultrices
							augue, suscipit dapibus diam nibh quis ex. Cras a tincidunt orci.
							Ut sollicitudin tincidunt libero pretium dictum. Suspendisse
							potenti. Maecenas sit amet finibus ante. Sed ac fermentum lectus,
							convallis fermentum ligula. Duis placerat ac lectus vitae tempus.
							Vestibulum quis sem a justo rutrum lobortis in in odio. Cras
							tincidunt lorem neque, eget lobortis lacus porta placerat. Class
							aptent taciti sociosqu ad litora torquent per conubia nostra, per
							inceptos himenaeos. Aenean quis euismod nisi, et aliquet turpis.
							Pellentesque a nibh ullamcorper dolor placerat vehicula et eget
							libero. Nam rhoncus tempus enim, quis pulvinar nunc tincidunt
							eget. Nulla molestie pellentesque ante eget tincidunt. Suspendisse
							ac metus tellus.
						</div>
						<div className="text-2">Awesome media examples</div>
						<div className="divider"></div>
						<div className="flex-content sfprodisplay-medium-white-14px">
							Fusce elementum ante sed maximus viverra. Fusce vitae tempor
							purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus
							blandit. Sed ac mi arcu. Pellentesque interdum est nec justo
							vestibulum luctus. Ut vitae enim hendrerit urna semper tempus eget
							ac magna. Nullam sodales id tortor in blandit. Morbi tellus purus,
							vestibulum tristique odio ut, iaculis semper nisi. Sed eu rhoncus
							ligula. Etiam non sodales est. Mauris in est eget nibh rhoncus
							faucibus.
							<img src={article2} />
						</div>
						<br />
						<div className="sfprodisplay-medium-white-14px">
							Donec viverra, neque ut malesuada accumsan, dolor turpis ultrices
							augue, suscipit dapibus diam nibh quis ex. Cras a tincidunt orci.
							Ut sollicitudin tincidunt libero pretium dictum. Suspendisse
							potenti. Maecenas sit amet finibus ante. Sed ac fermentum lectus,
							convallis fermentum ligula. Duis placerat ac lectus vitae tempus.
							Vestibulum quis sem a justo rutrum lobortis in in odio. Cras
							tincidunt lorem neque, eget lobortis lacus porta placerat. Class
							aptent taciti sociosqu ad litora torquent per conubia nostra, per
							inceptos himenaeos. Aenean quis euismod nisi, et aliquet turpis.
							Pellentesque a nibh ullamcorper dolor placerat vehicula et eget
							libero. Nam rhoncus tempus enim, quis pulvinar nunc tincidunt
							eget. Nulla molestie pellentesque ante eget tincidunt. Suspendisse
							ac metus tellus.
						</div>
						<br />
						<div className="flex-content sfprodisplay-medium-white-14px">
							<img
								src={article2}
								style={{ marginRight: 20, marginBottom: 20 }}
							/>
							Fusce elementum ante sed maximus viverra. Fusce vitae tempor
							purus. Vivamus non eleifend orci. Praesent porta erat a rhoncus
							blandit. Sed ac mi arcu. Pellentesque interdum est nec justo
							vestibulum luctus. Ut vitae enim hendrerit urna semper tempus eget
							ac magna. Nullam sodales id tortor in blandit. Morbi tellus purus,
							vestibulum tristique odio ut, iaculis semper nisi. Sed eu rhoncus
							ligula. Etiam non sodales est. Mauris in est eget nibh rhoncus
							faucibus.
						</div>
						<Link to="dashboard">
							<div className="group-47082 sfprodisplay-regular-white-12px">
								<svg
									className="confirmbutton"
									width="10"
									height="17"
									viewBox="0 0 10 17"
									fill="#fff"
								>
									<path d="M0.753336 9.32407L7.27376 15.6823C7.73133 16.1365 8.41769 16.1365 8.87527 15.6823C9.33284 15.2281 9.33284 14.5469 8.87527 14.0927L3.26999 8.41575L8.87527 2.73877C9.33284 2.28461 9.33284 1.60337 8.87527 1.14921C8.64648 0.922133 8.41769 0.808594 8.07451 0.808594C7.73133 0.808594 7.50255 0.922133 7.27376 1.14921L0.753336 7.50744C0.295763 8.07513 0.295763 8.75637 0.753336 9.32407C0.753336 9.21053 0.753336 9.21053 0.753336 9.32407Z" />
								</svg>
								Back
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ArticleV2;
