import React from 'react';
// import { Link } from 'react-router-dom';
import './style.css';
import logoWord from 'assets/common/logo-word.svg';

// const footerLinks = {
// 	PRODUCTS: [
// 		{ title: 'Solutions', link: '/coming-soon' },
// 		{ title: 'SDK', link: '/coming-soon' },
// 		{ title: 'AI Features', link: '/coming-soon' },
// 		{ title: 'How it works?', link: '/coming-soon' }
// 	],
// 	RESOURCES: [
// 		{ title: 'Help center', link: '/help' },
// 		{ title: 'Knowledge Base', link: '/coming-soon' },
// 		{ title: 'Launch your AI', link: '/coming-soon' },
// 		{ title: 'Learning center', link: '/coming-soon' }
// 	],
// 	COMPANY: [
// 		{ title: 'About us', link: '/coming-soon' },
// 		{ title: 'Our Team', link: '/coming-soon' },
// 		{ title: 'Contacts', link: '/contact-us' }
// 	]
// };
const MainFooter = ({ prodVersion }) => {
	return (
		<div className="main-footer" style={{ minHeight: !prodVersion ? 40 : 40 }}>
			{/* {!prodVersion && (
				<div className="content-container" style={{ width: '100%' }}>
					{Object.keys(footerLinks).map((key, i) => (
						<div className="flex-col-1" key={i}>
							<div className="sfprodisplay-bold-black-16px">{key}</div>
							<div className="group-7757">
								{footerLinks[key].map((item, j) => (
									<Link to={item.link} key={j}>
										<div className="solutions sfprodisplay-medium-black-22px">
											{item.title}
										</div>
									</Link>
								))}
							</div>
						</div>
					))}
				</div>
			)} */}
			<div className="copy-rights">
				<div className="text-16">© 2022 media AI by OneUp AI Inc.</div>
				{/* {!prodVersion && (
					<div className="text-16">Terms of Use &amp; Privacy Policy</div>
				)} */}
			</div>

			<div className={`logo-container ${(prodVersion || !prodVersion) ? 'logo-container-noaccess' : ''}`}>
				<div className="zoose-1">media</div>
				<img src={logoWord} />
			</div>
		</div>
	);
};

export default MainFooter;
