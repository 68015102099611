import React, { useEffect, useState } from 'react';
import './style.css';
import close from 'assets/common/close.svg';
import {
	getReveryItemsAction,
	reveryS3Url
} from 'store/actions/revery.action';

const TryOnModal = ({ onClose }) => {
	const [gender, setGender] = useState(0);
	const [category, setCategory] = useState(0);
	const [data, setData] = useState([]);
	useEffect(() => {
		getReveryData()
	}, []);

	const getReveryData = async () => {
		const shoesData = await getReveryItemsAction('shoes');
		setData(shoesData.shoe_s3_urls);
	};
	return (
		<>
			<div className="low-opacity" />
			<div className="tryon-modal animate-appear modal-background">
				<div className="closemodalbutton" onClick={onClose}>
					<img src={close} />
				</div>
				<div className="tryon-content">
					<img src={close} />
					<div className="select-container">
						<div
							className={`sfprodisplay-bold-white-20px ${
								gender === 0 ? 'selected-option' : ''
							}`}
							onClick={() => setGender(0)}
						>
							Male
						</div>
						<div
							className={`sfprodisplay-bold-white-20px ${
								gender === 1 ? 'selected-option' : ''
							}`}
							onClick={() => setGender(1)}
						>
							Female
						</div>
					</div>
					<div className="select-container" style={{ gap: '0.5vw' }}>
						{[
							'Tops',
							'Bottoms',
							'Outerwear',
							'Allbody',
							'Pants',
							'Shorts',
							'Skirts',
							'Dress',
							'Shoes'
						].map((item, key) => (
							<div
								key={`cat-${key}`}
								className={`sfprodisplay-bold-white-20px ${
									category === key ? 'selected-option' : ''
								}`}
								onClick={() => setCategory(key)}
							>
								{item}
							</div>
						))}
					</div>
					<div className="gallery-container">
						{data.map((item, key) => (
							<div key={`try-${key}`}>
								<img src={reveryS3Url + item} height="85px" />
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default TryOnModal;
