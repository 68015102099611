import React from 'react';
import SketchField from './SketchField';

const SketchCanvas = ({
	lineWidth,
	color,
	image,
	canvasRef,
	loading,
	imageDimensions
}) => {
	return (
		<div>
			<SketchField
				ref={canvasRef}
				height={imageDimensions.height}
				width={imageDimensions.width}
				lineColor={color}
				lineWidth={lineWidth}
				backgroundColor={loading ? 'black' : 'transparent'}
				style={
					!loading
						? { backgroundImage: `url(${image})`, backgroundSize: '100% 100%' }
						: {}
				}
			/>
			<div style={{ position: 'absolute', marginLeft: '30%' }}>
				<button
					onClick={() => {
						canvasRef.current.undo();
					}}
				>
					Undo
				</button>
				<button onClick={() => canvasRef.current.redo()}>Redo</button>
				<button onClick={() => canvasRef.current.clear()}>Clear</button>
			</div>
		</div>
	);
};

export default SketchCanvas;
