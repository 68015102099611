import React from 'react';
import './style.css';

const MasonryLayout = ({
	children,
	containerClass = '',
	columnClass = '',
	startingElementRef
}) => {
	const getColumns = () => {
		const columns = Array.from({ length: 4 }, () => []);

		React.Children.forEach(children, (child, index) => {
			if (child && React.isValidElement(child)) {
				columns[index % 4].push(child);
			}
		});

		return columns;
	};

	return (
		<div className={`masonry-container ${containerClass}`}>
			{startingElementRef && <div ref={startingElementRef} />}
			{getColumns().map((column, i) => (
				<div key={i} className={`col-container ${columnClass}`}>
					{column.map((item) => item)}
				</div>
			))}
		</div>
	);
};

export default MasonryLayout;
