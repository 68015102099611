import React, { useEffect, useState } from 'react';
import arrowRight from 'assets/common/arrow-right.svg';

const ModelsList = ({ models, setSelectedAI, tags }) => {
	const [filteredModels, setFilteredModels] = useState(null);
	useEffect(() => {
		if (tags) {
			let tempFilteredModels = [];
			for (let i = 0; i < models.length; i++) {
				for (let j = 0; j < tags.length; j++) {
					if (models[i].tags.includes(tags[j])) {
						tempFilteredModels.push(models[i]);
						break;
					}
				}
			}
			if (tempFilteredModels.length) setFilteredModels(tempFilteredModels);
		} else if (filteredModels) setFilteredModels(null);
	}, [tags]);
	return (
		<div>
			<div>
				{(filteredModels || models)?.map((item, i) => (
					<div
						className="ai-item"
						key={i}
						onClick={() => setSelectedAI({ ...item })}
					>
						<img src={item.color_icon} className="ai-icon" />
						<div className="text-content">
							<h3 className="sfprodisplay-heavy-white-16px">{item.name}</h3>
							<p className="sfprodisplay-medium-white-14px">
								{item.description}
							</p>
						</div>
						<img className="arrow" src={arrowRight} />
					</div>
				))}
			</div>
		</div>
	);
};

export default ModelsList;
