import React from 'react';
import './style.css';

const Tabs = ({ tabs, active, onClick }) => {
	return (
		<div className="tabs">
			{tabs.map((item, i) => (
				<div
					className={`tab ${active === i ? 'active-tab' : ''}`}
					key={i}
					onClick={() => onClick(i)}
				>
					{item.icon && <img src={item.icon} />}
					<div>{item.title}</div>
				</div>
			))}
		</div>
	);
};

export default Tabs;
