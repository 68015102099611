import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { uploadMediaAction } from 'store/actions/media.action';
import { runRecommenderAction } from 'store/actions/users.action';
import { acceptedUploadFiles } from 'helper/constants';
import { notifyToast } from 'helper/utils';
import './style.css';
import close from 'assets/common/close.svg';
import upload from 'assets/common/upload.svg';
import browse from 'assets/common/browse.svg';

const DragDropModal = ({ afterUploadCompleted, onClose }) => {
	const dispatch = useDispatch();

	const [dragActive, setDragActive] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(null);

	const inputRef = useRef(null);

	const handleFile = (files) => {
		let filesList = [];
		const filesLength = files.length;
		let index = -1;
		let errorsLength = 0;
		for (const file of files) {
			let validFile = true;
			index += 1;
			if (!acceptedUploadFiles.includes(file.type)) {
				errorsLength += 1;
				validFile = false;
				notifyToast(
					'error',
					`invalid file type for file number ${
						index + 1
					}, must be one of: mp4, mov, jpeg, jpg or png'`
				);
			}
			if (index === 0) setUploadProgress(0);
			const formData = new FormData();
			formData.append('media', file);
			if (validFile)
				dispatch(
					uploadMediaAction(
						formData,
						['image'],
						false,
						async (url, id) => {
							await dispatch(
								runRecommenderAction(url, (res) => {
									filesList.push({
										url,
										id,
										tags: res ? [...res.imageTags, ...res.suggestedModels] : []
									});
									processingHandler(filesList, errorsLength, filesLength);
								})
							);
						},
						() => {
							errorsLength += 1;
							processingHandler(filesList, errorsLength, filesLength);
						}
					)
				);
			else {
				processingHandler(filesList, errorsLength, filesLength);
			}
		}
	};
	const processingHandler = (filesList, errorsLength, filesLength) => {
		const precentage = (filesList.length + errorsLength) / filesLength;
		setUploadProgress(precentage);
		if (precentage === 1) afterUploadCompleted(filesList);
	};
	// handle drag events
	const handleDrag = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true);
		} else if (e.type === 'dragleave') {
			setDragActive(false);
		}
	};

	// triggers when file is dropped
	const handleDrop = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			handleFile(e.dataTransfer.files);
		}
		e.target.value = '';
	};

	// triggers when file is selected with click
	const handleChange = function (e) {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			handleFile(e.target.files);
		}
		e.target.value = '';
	};

	// triggers the input when the button is clicked
	const onButtonClick = () => {
		inputRef.current.click();
	};
	return (
		<>
			<div className="low-opacity" />
			<div className="dragdrop-modal animate-appear modal-background">
				<div className="closemodalbutton" onClick={onClose}>
					<img src={close} />
				</div>
				{uploadProgress !== null ? (
					<div className="progress-container">
						<div className="upper-container">
							<CircularProgress color="inherit" size="30px" />
							<p className="sfprodisplay-bold-white-14px">
								media is being uploaded and scanned...
							</p>
						</div>
						<div className="lower-container">
							<div
								style={{
									width: `${uploadProgress * 100}%`
								}}
							></div>
						</div>
					</div>
				) : (
					<>
						<div className="content-container">
							<form
								onDragEnter={handleDrag}
								onSubmit={(e) => e.preventDefault()}
							>
								<input
									ref={inputRef}
									type="file"
									id="input-file-upload"
									multiple={true}
									onChange={handleChange}
									accept="image/*, video/*"
								/>
								<label
									htmlFor="input-file-upload"
									className={dragActive ? 'drag-active' : ''}
								>
									<div>
										<img className="icon" src={upload} />
										<p className="sfprodisplay-bold-white-14px">
											Drag and drop file
										</p>
									</div>
								</label>
								{dragActive && (
									<div
										className="drag-container"
										onDragEnter={handleDrag}
										onDragLeave={handleDrag}
										onDragOver={handleDrag}
										onDrop={handleDrop}
									></div>
								)}
							</form>
							<div className="upload-button" onClick={onButtonClick}>
								<img className="icon" src={browse} />
								<span className="sfprodisplay-bold-white-14px">
									Upload from computer
								</span>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default DragDropModal;
