import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJobsDataAction } from 'store/actions/jobs.action';
import { Table, Loading } from 'components';
import { data } from './sample-data';
import { aiTypes } from 'helper/constants';

const Jobs = () => {
	const dispatch = useDispatch();
	const { data: jobsData } = useSelector((state) => state.jobs);
	const { data: browseAiData } = useSelector((state) => state.browseAi);
	const [modifiedJobsData, setModifiedJobsData] = useState(null);
	useEffect(() => {
		if (!jobsData) dispatch(getJobsDataAction());
	}, []);

	useEffect(() => {
		if (jobsData && !modifiedJobsData) {
			let tempData = [];
			jobsData.map((item, i) => {
				tempData.push({
					id: item.id,
					args: Object.keys(item.args),
					status: item.status,
					created_at: item.created_at,
					...data[i % 10]
				});
			});
			setModifiedJobsData(tempData);
		}
	}, [jobsData]);

	if (!modifiedJobsData) return <Loading />;
	return (
		<Table
			data={modifiedJobsData}
			filters={browseAiData}
			types={aiTypes}
			headers={[
				'Job ID',
				'Arguments',
				'Status',
				'Submitted Date',
				'Credits Used',
				'Time Elapsed',
				'Details'
			]}
		/>
	);
};

export default Jobs;
