import React from 'react';

const Loader = () => {
	return (
		<div className="img-loader">
			<div className="blobs">
				<div className="blob-center"></div>
				<div className="blob"></div>
				<div className="blob"></div>
				<div className="blob"></div>
				<div className="blob"></div>
				<div className="blob"></div>
				<div className="blob"></div>
			</div>
		</div>
	);
};

export default Loader;
