import styled, { keyframes } from 'styled-components';

const getDropdownRootKeyFrame = ({ animatingOut, direction }) => {
	if (!animatingOut && direction) return null;
	return keyframes`
  from {
    transform: ${animatingOut ? 'rotateX(0)' : 'rotateX(-15deg)'};
    opacity: ${animatingOut ? 1 : 0};
  }
  to {
    transform: ${animatingOut ? 'rotateX(-15deg)' : 'rotateX(0)'};
    opacity: ${animatingOut ? 0 : 1};
  }
`;
};

export const DropdownRoot = styled.div`
	transform-origin: 0 0;
	will-change: transform;
	animation-name: ${getDropdownRootKeyFrame};
	animation-duration: ${(props) => props.duration}ms;
	/* use 'forwards' to prevent flicker on leave animation */
	animation-fill-mode: forwards;
	/* flex styles will center the caret child component */
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	top: -10px;
	margin-right: 22vw;
`;

export const Caret = styled.div`
	width: 0;
	height: 0;
	border-width: 10px;
	border-style: solid;
	border-color: transparent transparent var(--white);
	/* make sure it's above the main dropdown container so now box-shadow bleeds over it */
	z-index: 1;
	position: relative;
	/* prevent any gap in between caret and main dropdown */
	top: 1px;
	margin-right: -22vw;
`;

export const DropdownBackground = styled.div`
	transform-origin: 0 0;
	overflow: hidden;
	position: relative;
	will-change: transform;
	border-radius: 1.25vw;
	background: rgba(23, 23, 23, 0.5);
	box-shadow: 11.3661px 36.3715px 22.7322px rgba(0, 0, 0, 0.5),
		inset 0px 4px 4px rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(10px);
`;

export const AltBackground = styled.div`
	background-color: var(--grey);
	width: 300%;
	height: 100%;
	position: absolute;
	top: 0;
	left: -100%;
	transform-origin: 0 0;
	z-index: 0;
	transition: transform ${(props) => props.duration}ms;
`;

export const InvertedDiv = styled.div`
	will-change: transform;
	position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
	top: 0;
	left: 0;
	&:first-of-type {
		z-index: 1;
	}
	&:not(:first-of-type) {
		z-index: -1;
	}
`;
