import React from 'react';
import './style.css';
import search from 'assets/common/search_icon.svg';

const SearchInput = ({
	clear,
	style,
	placeholder,
	fontSize,
	onChange = () => {}
}) => {
	return (
		<div className="headersearchinput" style={style}>
			<div className="group-8671">
				<div className="group-144">
					<img className="overlap-group-12" src={search} />
				</div>
				<input
					className="search"
					name="search"
					placeholder={placeholder || 'Search...'}
					type="text"
					style={{ fontSize: fontSize || 12.5 }}
					onChange={onChange}
					value={clear ? '' : undefined}
				/>
			</div>
		</div>
	);
};

export default SearchInput;
