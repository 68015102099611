import { useEffect } from 'react';

export const useDisableBodyScroll = () => {
	useEffect(() => {
		document.body.style.overflow = 'hidden';
	}, []);

	useEffect(() => {
		return () => {
			document.body.style.overflow = 'unset';
		};
	}, []);
};
