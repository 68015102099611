export const data = [
	{
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		credit_used: 11,
		time_elapsed: 5.2
	},
	{
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		credit_used: 15,
		time_elapsed: 5.2
	},
	{
		credit_used: 50,
		time_elapsed: 5.2
	},
	{
		credit_used: 40,
		time_elapsed: 5.2
	},
	{
		credit_used: 30,
		time_elapsed: 5.2
	},
	{
		credit_used: 22,
		time_elapsed: 5.2
	},
	{
		credit_used: 56,
		time_elapsed: 5.2
	},
	{
		credit_used: 10,
		time_elapsed: 5.2
	},
	{
		credit_used: 10,
		time_elapsed: 5.2
	}
];
