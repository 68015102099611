import React, { useState } from 'react';
import { validators } from 'helper/validator';
import { Input, MessagePopup } from 'components';
import { Checkbox } from '@mui/material';
import './style.css';
import messageIcon from 'assets/common/message.svg';
import chat from 'assets/common/chat.svg';
import mic from 'assets/common/mic.svg';

const Contact = () => {
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [company, setCompany] = useState('');
	const [country, setCountry] = useState('');
	const [phone, setPhone] = useState('');
	const [reason, setReason] = useState('');
	const [message, setMessage] = useState('');
	const [errorMsg, setErrorMsg] = useState({
		firstName: null,
		lastName: null,
		email: null,
		company: null,
		country: null,
		reason: null,
		message: null
	});

	const [messageContent, setMessageContent] = useState(false);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	const sendEmail = () => {
		window.location = 'mailto:support@mediamagic.ai';
	};

	const onSubmit = () => {
		const errorObj = {
			email: validators.required(email) || validators.email(email),
			firstName:
				validators.required(firstName) || validators.minLength(firstName, 3),
			lastName:
				validators.required(lastName) || validators.minLength(lastName, 3),
			company: validators.required(company) || validators.minLength(company, 3),
			country: validators.required(country) || validators.minLength(country, 3),
			reason: validators.required(reason) || validators.minLength(reason, 3),
			message: validators.required(message)
		};
		setErrorMsg(errorObj);
		if (Object.values(errorObj).every((value) => !value)) {
			setMessageContent(
				`Thank you for submiting. ${email} will be notified of updates!`
			);
		}
	};
	return (
		<div className="contact">
			{messageContent && (
				<MessagePopup
					content={messageContent}
					onClick={() => setMessageContent(!messageContent)}
				/>
			)}
			<div className="upper-section">
				<div className="content-container">
					<h2 className="sfprodisplay-bold-white-32px">Contact Us</h2>
					<div>
						<Input
							placeholder="First Name*"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							errorMsg={errorMsg.firstName}
						/>
						<Input placeholder="How did you hear about us?" />
						<Input
							placeholder="Last Name*"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							errorMsg={errorMsg.lastName}
						/>
						<Input
							placeholder="Reason*"
							value={reason}
							onChange={(e) => setReason(e.target.value)}
							errorMsg={errorMsg.reason}
						/>
						<div>
							<Input
								placeholder="Email*"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								errorMsg={errorMsg.email}
							/>
							<Input
								placeholder="Company*"
								value={company}
								onChange={(e) => setCompany(e.target.value)}
								errorMsg={errorMsg.company}
							/>
							<Input
								placeholder="Country*"
								value={country}
								onChange={(e) => setCountry(e.target.value)}
								errorMsg={errorMsg.country}
							/>
							<Input
								placeholder="Phone Number (optional)"
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								errorMsg={errorMsg.phone}
							/>
						</div>

						<Input
							placeholder="Message*"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							errorMsg={errorMsg.message}
							inputStyle={{ height: 206, borderRadius: 16 }}
							textArea
						/>
					</div>
					<div className="checkbox-container">
						<p className="sfprodisplay-regular-white-12px">
							I agree to recieve MediaMagic communications
						</p>
						<Checkbox />
					</div>
					<button
						className="sfprodisplay-bold-white-24px"
						onClick={() => onSubmit()}
					>
						Submit
					</button>
					<p className="contact-bottom-text sfprodisplay-regular-white-12px">
						Privacy Policy: MediaMagic is deticated to protecting your
						information and security. For more specifics regarding the our
						policies and practices, please see our{' '}
						<span
							onClick={() => (window.location = '/coming-soon')}
							style={{ color: '#CB0202', fontWeight: 800 }}
						>
							Privacy Policy
						</span>
					</p>
				</div>
			</div>
			<div className="lower-section">
				<h2 className="sfprodisplay-bold-white-32px">Tech Support</h2>
				<p className="sfprodisplay-medium-white-20px">
					Select an option below for any technical questions or support requests
				</p>
				<div className="options-container">
					<div className="option" onClick={() => scrollToTop()}>
						<img src={chat} />
						<h6 className="sfprodisplay-regular-white-14px">
							Support Forum
						</h6>
						<p className="sfprodisplay-regular-white-12px">
							Available: 24/7
						</p>
					</div>
					<div className="option" onClick={() => scrollToTop()}>
						<img src={mic} />
						<h6 className="sfprodisplay-regular-white-14px">
							Open Support Ticket
						</h6>
						<p className="sfprodisplay-regular-white-12px">
							Available: M - F 9am - 5pm est
						</p>
					</div>
					<div className="option" onClick={() => sendEmail()}>
						<img src={messageIcon} />
						<h6 className="sfprodisplay-regular-white-14px">
							Email: support@mediamagic.ai
						</h6>
						<p className="sfprodisplay-regular-white-12px">
							Available: 24/7
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
