import React from 'react';
import './style.css';
import rightArrow from 'assets/common/arrow_right.svg';
const Divider = ({ leftValue, rightVaue, noline }) => {
    return (
        <div className="divider">
            <div className="sfprodisplay-heavy-white-24px">{leftValue}</div>
            <div className="flex-container" style={{ width: '100%' }}>
                {!noline && <div className="line-40"></div>}
                <div className="sfprodisplay-heavy-white-24px docs">{rightVaue}</div>
                <img src={rightArrow} />
            </div>
        </div>
    );
};

export default Divider;
