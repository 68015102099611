import http from 'config/http';
import { PUBLIC_ACCESS } from 'helper/constants';
import { Statsig } from 'statsig-react';
import {
	BROWSEAI_REQUESTED,
	GET_BROWSEAI_FAILED,
	GET_BROWSEAI_SUCCESS,
	DELETE_BROWSEAI_ITEM
} from '../types/browseai.type';

export const getModelsAction = () => async (dispatch) => {
	dispatch({ type: BROWSEAI_REQUESTED });
	const token = localStorage.getItem('session');
	// if (token) Statsig.logEvent('LIST_BROWSEAI_DATA');
	try {
		const payload = await http
			.get('models', {
				headers: token ? { Authorization: `Bearer ${token}` } : PUBLIC_ACCESS
			})
			.then((res) => res.data);
		dispatch({ type: GET_BROWSEAI_SUCCESS, payload: payload });
	} catch (err) {
		dispatch({ type: GET_BROWSEAI_FAILED, payload: err?.response?.data });
	}
};

export const deleteModelAction = (id, callback) => async (dispatch) => {
	dispatch({ type: DELETE_BROWSEAI_ITEM });
	const token = localStorage.getItem('session');
	Statsig.logEvent('DELETE_BROWSEAI_ITEM');
	await http.delete(`models/${id}`, {
		headers: { Authorization: `Bearer ${token}` }
	});
	callback && callback();
};
