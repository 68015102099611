import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SearchInput } from 'components';
import './style.css';
import chat from 'assets/common/chat_icon.svg';
import arrowRight from 'assets/common/arrow_right.svg';
import upload from 'assets/common/upload.svg';
import browseMedia from 'assets/common/browse-media.svg';
import browse from 'assets/common/browse.svg';

const DocsDropdown = ({ onClick, create, desktop, setDragDropVisible }) => {
	const { data: browseAiData } = useSelector((state) => state.browseAi);

	return (
		<div className={`large-menu modal-background ${desktop ? 'desktop-container' : ''}`}>
			<div className="hidden-md">
				<SearchInput style={{ width: '80%', marginTop: 20, marginLeft: 15 }} />
				<div className="closeicon" onClick={onClick}>
					<div></div>
					<div></div>
				</div>
				<div className="keys-container">
					<div className="flex-container">
						<p className="sfprodisplay-heavy-white-14px">Your API Keys</p>
					</div>
					<div className="flex-container">
						<p className="sfprodisplay-bold-white-12px">Publishable Key:</p>
						<p className="sfprodisplay-bold-white-12px">
							absndjfj_skdxjsJshdnjJhi39
						</p>
					</div>
					<div className="flex-container">
						<p className="sfprodisplay-bold-white-12px">Secret key:</p>
						<p className="sfprodisplay-bold-white-12px">
							absndjfj_skdxjsJshdnjJhi39
						</p>
					</div>
				</div>
			</div>
			<div className="upper-section">
				<div className="left-section" onClick={onClick}>
					<h2 className="sfprodisplay-heavy-white-28px">AI Docs</h2>
					<div className="left-section-content">
						{browseAiData?.map((item, i) => (
							<a
								href="https://mediamagic.dev/docs"
								target={'_blank'}
								key={i}
								rel="noreferrer"
							>
								<div className="item-container">
									<div>
										<img src={item.color_icon} />
										<div>
											<h2 className="sfprodisplay-heavy-white-16px">
												{item.name}
											</h2>
											<p className="sfprodisplay-bold-white-16px">
												{item.description}
											</p>
										</div>
									</div>
									<img className="arrow" src={arrowRight} />
								</div>
							</a>
						))}
					</div>
				</div>
				<div className="right-section hidden-sm">
					<div className="flex-container">
						<h2 className="sfprodisplay-heavy-white-28px">
							{create ? 'Media' : 'Help'}
						</h2>
					</div>
					<div className="right-section-content" onClick={onClick}>
						{!create ? (
							<>
								<Link to="/app/account-setting">
									<h2 className="sfprodisplay-bold-white-20px">
										Getting Started
									</h2>
									<p>Creating your App API KEY</p>
									<p>Manage your Account</p>
									<p>More....</p>
								</Link>

								<Link to="/app/account-setting">
									<h2 className="sfprodisplay-bold-white-20px">Guides</h2>
									<p>Creating your App Token</p>
									<p>Manage your Subscription</p>
									<p>Manage your Team</p>
									<p>More....</p>
								</Link>
							</>
						) : (
							<div>
								<Link className="icon-container" to="/app/media">
									<img src={browseMedia} />
									<p>Media Library</p>
								</Link>
								<Link
									className="icon-container"
									to="/app/media"
									onClick={() => setDragDropVisible(true)}
								>
									<img src={upload} />
									<p> Drag & Drop</p>
								</Link>
								<Link className="icon-container" to="/app/browse-ai">
									<img src={browse} />
									<p> Browse AI</p>
								</Link>
							</div>
						)}
					</div>
				</div>
			</div>
			{!create && (
				<div className="lower-section">
					<h2 className="sfprodisplay-bold-white-20px hidden-md">Help</h2>
					<div className="flex-container">
						<img src={chat} />
						<h2 className="sfprodisplay-bold-white-20px">Support</h2>
					</div>
					<div className="lower-section-content">
						<Link to="/app/dashboard">
							<p>Dashboard</p>
						</Link>
						<Link to="/app/users">
							<p>Users & Deployments</p>
						</Link>
						<Link to="/app/jobs">
							<p>Jobs</p>
						</Link>
						<a
							href="https://element.mediamagic.ai"
							target={'_blank'}
							rel="noreferrer"
						>
							<p>Start Chat</p>
						</a>
					</div>
					<div className="hidden-md lower-section-content-2">
						<div>
							<h2 className="sfprodisplay-bold-white-16px">Getting Started</h2>
							<p>Creating your App Token</p>
							<p>Manage your Account</p>
							<p>Manage your Account</p>
						</div>

						<div>
							<h2 className="sfprodisplay-bold-white-16px">Guides</h2>
							<p>Creating your App Token</p>
							<p>Manage your Account</p>
							<p>Manage your Account</p>
							<p>More....</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default DocsDropdown;
