// import http from 'config/http';
import { Statsig } from 'statsig-react';
import {
	DASHBOARD_REQUESTED
	// GET_DASHBOARD_FAILED,
	// GET_DASHBOARD_SUCCESS
} from '../types/dashboard.type';

export const getDashboardDataAction = () => async (dispatch) => {
	dispatch({ type: DASHBOARD_REQUESTED });
	// const token = localStorage.getItem('session');
	Statsig.logEvent('LIST_DASHBOARD_DATA');
	// try {
	// 	const payload = await http.get('dashboard/', param, {
	//		headers: { Authorization: `Bearer ${token}` }
	//	}).then((res) => res.data);
	// 	dispatch({ type: GET_DASHBOARD_SUCCESS, payload });
	// 	callback && callback();
	// } catch (err) {
	// 	dispatch({ type: GET_DASHBOARD_FAILED, payload: err?.response?.data });
	// }
};
