/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import { fabric } from 'fabric';
import History from './history';

/**
 * Sketch Tool based on FabricJS for React Applications
 */
class SketchField extends PureComponent {
	static defaultProps = {
		lineColor: 'black',
		lineWidth: 10,
		backgroundColor: 'transparent',
		widthCorrection: 0,
		heightCorrection: 0,
		forceValue: false
	};

	state = {
		action: true
	};

	_initPencil = () => {
		this._fc.freeDrawingBrush.width = this.props.lineWidth;
		this._fc.freeDrawingBrush.color = this.props.lineColor;
	};

	/**
	 * Disable touch Scrolling on Canvas
	 */
	disableTouchScroll = () => {
		let canvas = this._fc;
		if (canvas.allowTouchScrolling) {
			canvas.allowTouchScrolling = false;
		}
	};

	/**
	 * convert stroke into white color and background into black
	 */
	prepareMask = (filledColor, callback) => {
		const json = this._fc.toJSON();
		if (json.objects.length === 0) return callback(json.objects);
		json.background = '#000';
		if (filledColor)
			json.objects = json.objects.map((obj) => {
				obj.stroke = filledColor;
				return obj;
			});
		this._fc.loadFromJSON(json);
		callback(json.objects);
	};

	/**
	 * Action when an object is added to the canvas
	 */
	_onObjectAdded = (e) => {
		if (!this.state.action) {
			this.setState({ action: true });
			return;
		}
		let obj = e.target;
		obj.__version = 1;
		// record current object state as json and save as originalState
		let objState = obj.toJSON();
		obj.__originalState = objState;
		let state = JSON.stringify(objState);
		// object, previous state, current state
		this._history.keep([obj, state, state]);
	};

	/**
	 * Action when an object is removed from the canvas
	 */
	_onObjectRemoved = (e) => {
		let obj = e.target;
		if (obj.__removed) {
			obj.__version += 1;
			return;
		}
		obj.__version = 0;
	};

	/**
	 * Track the resize of the window and update our state
	 *
	 * @param e the resize event
	 * @private
	 */
	_resize = (e, canvasWidth = null, canvasHeight = null) => {
		if (e) e.preventDefault();
		let { widthCorrection, heightCorrection } = this.props;
		let canvas = this._fc;
		let { offsetWidth, clientHeight } = this._container;
		let prevWidth = canvasWidth || canvas.getWidth();
		let prevHeight = canvasHeight || canvas.getHeight();
		let wfactor = ((offsetWidth - widthCorrection) / prevWidth).toFixed(2);
		let hfactor = ((clientHeight - heightCorrection) / prevHeight).toFixed(2);
		canvas.setWidth(offsetWidth - widthCorrection);
		canvas.setHeight(clientHeight - heightCorrection);
		if (canvas.backgroundImage) {
			// Need to scale background images as well
			let bi = canvas.backgroundImage;
			bi.width = bi.width * wfactor;
			bi.height = bi.height * hfactor;
		}
		let objects = canvas.getObjects();
		for (let i in objects) {
			let obj = objects[i];
			let scaleX = obj.scaleX;
			let scaleY = obj.scaleY;
			let left = obj.left;
			let top = obj.top;
			let tempScaleX = scaleX * wfactor;
			let tempScaleY = scaleY * hfactor;
			let tempLeft = left * wfactor;
			let tempTop = top * hfactor;
			obj.scaleX = tempScaleX;
			obj.scaleY = tempScaleY;
			obj.left = tempLeft;
			obj.top = tempTop;
			obj.setCoords();
		}
		canvas.renderAll();
		canvas.calcOffset();
	};

	/**
	 * Sets the background color for this sketch
	 * @param color in rgba or hex format
	 */
	_backgroundColor = (color) => {
		if (!color) return;
		let canvas = this._fc;
		canvas.setBackgroundColor(color, () => canvas.renderAll());
	};

	/**
	 * Perform an undo operation on canvas, if it cannot undo it will leave the canvas intact
	 */
	undo = () => {
		let history = this._history;
		let [obj, prevState, currState] = history.getCurrent();
		history.undo();
		if (obj.__removed) {
			this.setState({ action: false }, () => {
				this._fc.add(obj);
				obj.__version -= 1;
				obj.__removed = false;
			});
		} else if (obj.__version <= 1) {
			this._fc.remove(obj);
		} else {
			obj.__version -= 1;
			obj.setOptions(JSON.parse(prevState));
			obj.setCoords();
			this._fc.renderAll();
		}
		if (this.props.onChange) {
			this.props.onChange();
		}
	};

	/**
	 * Perform a redo operation on canvas, if it cannot redo it will leave the canvas intact
	 */
	redo = () => {
		let history = this._history;
		if (history.canRedo()) {
			let canvas = this._fc;
			//noinspection Eslint
			let [obj, prevState, currState] = history.redo();
			if (obj.__version === 0) {
				this.setState({ action: false }, () => {
					canvas.add(obj);
					obj.__version = 1;
				});
			} else {
				obj.__version += 1;
				obj.setOptions(JSON.parse(currState));
			}
			obj.setCoords();
			canvas.renderAll();
			if (this.props.onChange) {
				this.props.onChange();
			}
		}
	};

	/**
	 * Exports canvas element to a dataurl image. Note that when multiplier is used, cropping is scaled appropriately
	 *
	 * Available Options are
	 * <table style="width:100%">
	 *
	 * <tr><td><b>Name</b></td><td><b>Type</b></td><td><b>Argument</b></td><td><b>Default</b></td><td><b>Description</b></td></tr>
	 * <tr><td>format</td> <td>String</td> <td><optional></td><td>png</td><td>The format of the output image. Either "jpeg" or "png"</td></tr>
	 * <tr><td>quality</td><td>Number</td><td><optional></td><td>1</td><td>Quality level (0..1). Only used for jpeg.</td></tr>
	 * <tr><td>multiplier</td><td>Number</td><td><optional></td><td>1</td><td>Multiplier to scale by</td></tr>
	 * <tr><td>left</td><td>Number</td><td><optional></td><td></td><td>Cropping left offset. Introduced in v1.2.14</td></tr>
	 * <tr><td>top</td><td>Number</td><td><optional></td><td></td><td>Cropping top offset. Introduced in v1.2.14</td></tr>
	 * <tr><td>width</td><td>Number</td><td><optional></td><td></td><td>Cropping width. Introduced in v1.2.14</td></tr>
	 * <tr><td>height</td><td>Number</td><td><optional></td><td></td><td>Cropping height. Introduced in v1.2.14</td></tr>
	 *
	 * </table>
	 *
	 * @returns {String} URL containing a representation of the object in the format specified by options.format
	 */
	toDataURL = (options) => this._fc.toDataURL(options);

	/**
	 * Returns JSON representation of canvas
	 *
	 * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
	 * @returns {string} JSON string
	 */
	toJSON = (propertiesToInclude) => this._fc.toJSON(propertiesToInclude);

	/**
	 * Populates canvas with data from the specified JSON.
	 *
	 * JSON format must conform to the one of fabric.Canvas#toDatalessJSON
	 *
	 * @param json JSON string or object
	 */
	fromJSON = (json) => {
		if (!json) return;
		let canvas = this._fc;
		setTimeout(() => {
			canvas.loadFromJSON(json, () => {
				canvas.renderAll();
				if (this.props.onChange) {
					this.props.onChange();
				}
			});
		}, 100);
	};

	/**
	 * Clear the content of the canvas, this will also clear history but will return the canvas content as JSON to be
	 * used as needed in order to undo the clear if possible
	 *
	 * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
	 * @returns {string} JSON string of the canvas just cleared
	 */
	clear = (propertiesToInclude) => {
		let discarded = this.toJSON(propertiesToInclude);
		this._fc.clear();
		this._history.clear();
		return discarded;
	};

	componentDidMount = () => {
		let { value, defaultValue, backgroundColor } = this.props;

		let canvas = (this._fc = new fabric.Canvas(this._canvas));
		canvas.isDrawingMode = true;

		this._initPencil();

		// set initial backgroundColor
		this._backgroundColor(backgroundColor);

		// Control resize
		window.addEventListener('resize', this._resize, false);

		// Initialize History, with maximum number of undo steps
		this._history = new History(25);

		// Events binding
		canvas.on('object:added', (e) => this._onObjectAdded(e));
		canvas.on('object:removed', (e) => this._onObjectRemoved(e));

		this.disableTouchScroll();

		this._resize();

		// initialize canvas with controlled value if exists
		(value || defaultValue) && this.fromJSON(value || defaultValue);
	};

	componentWillUnmount = () =>
		window.removeEventListener('resize', this._resize);

	componentDidUpdate = (prevProps, prevState) => {
		if (
			this.props.width !== prevProps.width ||
			this.props.height !== prevProps.height ||
			this.props.style !== prevProps.style
		) {
			this._resize();
		}

		if (this.props.backgroundColor !== prevProps.backgroundColor) {
			this._backgroundColor(this.props.backgroundColor);
		}

		if (
			this.props.value !== prevProps.value ||
			(this.props.value && this.props.forceValue)
		) {
			this.fromJSON(this.props.value);
		}
		if (
			this.props.lineColor !== prevProps.lineColor ||
			this.props.lineWidth !== prevProps.lineWidth
		)
			this._initPencil();
	};

	render = () => {
		let { className, style, width, height } = this.props;

		let canvasDivStyle = Object.assign(
			{},
			style ? style : {},
			width ? { width: width } : {},
			height ? { height: height } : { height: 512 }
		);
		return (
			<div
				className={className}
				ref={(c) => (this._container = c)}
				style={canvasDivStyle}
			>
				<canvas id={'id-1'} ref={(c) => (this._canvas = c)}></canvas>
			</div>
		);
	};
}

export default SketchField;
