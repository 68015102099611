import React from 'react';
import { Input } from 'components';
import './style.css';
import tick from 'assets/common/tick-2.svg';
import card from 'assets/common/card.svg';

const AddPayment = ({
	setFirstName,
	setLastName,
	setCardNo,
	setAddress,
	setCvv,
	expireDate,
	setExpireDate,
	errorMsg
}) => {
	return (
		<div className="add-payment">
			<div className="add-payment-header">
				<span className="sfprodisplay-bold-white-32px">
					{'Add payment details now and unlock \npowerful benefits'}
				</span>
				<div className="optional">optional</div>
			</div>

			<div className="unique-text-container">
				<div className="group-7844">
					<img className="line-30" src={tick} />
				</div>
				<span className="sfprodisplay-regular-white-16px">
					<span style={{ fontWeight: 900 }}>1,000,000</span> additional free
					compute units per month
				</span>
			</div>
			<div className="credit-container">
				<div className="flex-container">
					<Input
						label="User Info"
						placeholder="First Name..."
						onChange={(e) => setFirstName(e.target.value)}
						errorMsg={errorMsg.firstName}
					/>
					<Input
						placeholder="Last Name..."
						onChange={(e) => setLastName(e.target.value)}
						errorMsg={errorMsg.lastName}
					/>
				</div>
				<div className="flex-container">
					<Input
						icon={card}
						label="Credit Card"
						placeholder="Card Number"
						onChange={(e) => setCardNo(e.target.value)}
						errorMsg={errorMsg.cardNo}
					/>
					<div className="sub-flex-container">
						<Input
							placeholder="MM - YYYY"
							type="month"
							value={expireDate}
							onChange={(e) => setExpireDate(e.target.value)}
							style={{ maxWidth: 200 }}
							errorMsg={errorMsg.expireDate}
						/>
						<Input
							placeholder="CVV"
							style={{ maxWidth: 100 }}
							onChange={(e) => setCvv(e.target.value)}
							errorMsg={errorMsg.cvv}
							type="number"
						/>
					</div>
				</div>
				<Input
					inputStyleClass="lastinput"
					placeholder="Billing Address"
					style={{ marginTop: 45 }}
					onChange={(e) => setAddress(e.target.value)}
					errorMsg={errorMsg.address}
				/>
			</div>
		</div>
	);
};

export default AddPayment;
