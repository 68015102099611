/* eslint-disable no-debugger */
import React, { useEffect, useRef, useState } from 'react';
import JsonFormatter from './JsonFormatter';
import './style.css';

const CommandModal = ({
	selectedAI,
	onClose,
	onRequest,
	dispatch,
	setMainLoading,
	filesList,
	masksList,
	stringsList,
	floatsList,
	numbersList
}) => {
	const divRef = useRef();
	const [header, setHeader] = useState('');
	const [body, setBody] = useState('');
	const [response, setResponse] = useState('No response yet');

	useEffect(() => {
		commandFormat();
	}, []);

	const commandFormat = () => {
		let payload = {
			name: selectedAI.name,
			model_id: selectedAI.id,
			args: {}
		};
		let filesIndex, masksIndex, floatsIndex, stringsIndex;
		filesIndex = masksIndex = floatsIndex = stringsIndex = 0;
		Object.keys(selectedAI.args).map((arg) => {
			if (
				selectedAI.args[arg].type === 'file' &&
				arg.toLowerCase().includes('mask')
			)
				payload.args[arg] =
					masksList[masksIndex++]?.path ||
					`add value in type ${selectedAI.args[arg].type}`;
			else if (selectedAI.args[arg].type === 'file')
				payload.args[arg] =
					filesList[filesIndex++]?.path ||
					`add value in type ${selectedAI.args[arg].type}`;
			else if (
				selectedAI.args[arg].type === 'float' ||
				selectedAI.args[arg].type === 'array'
			)
				payload.args[arg] =
					floatsList[floatsIndex++]?.value ||
					`add value in type ${selectedAI.args[arg].type}`;
			else if (
				selectedAI.args[arg].type === 'integer' ||
				selectedAI.args[arg].type === 'number'
			)
				payload.args[arg] =
					numbersList[floatsIndex++]?.value ||
					`add value in type ${selectedAI.args[arg].type}`;
			else if (selectedAI.args[arg].type === 'string')
				payload.args[arg] =
					stringsList[stringsIndex++]?.value ||
					`add value in type ${selectedAI.args[arg].type}`;
		});
		setHeader('curl --location --request POST $API $HEADERS --data-raw ');
		setBody(payload);
	};

	const tryCurl = () => {
		setMainLoading(true);
		try {
			let payload = divRef.current?.innerText;
			const parsedPayload = JSON.parse(payload);
			dispatch(
				onRequest(
					parsedPayload,
					(res) => {
						if (res?.data?.message)
							res.data.message = res.data.message
								.replaceAll(/\n/g, '')
								.replaceAll(/\t/g, ' ');
						setResponse(res.data ? res.data : res, null, 2);
					},
					true
				)
			);
		} catch (e) {
			console.error('error in try curl', e);
		}

		setMainLoading(false);
	};
	return (
		<div className="command-modal animate-appear">
			<div
				className="sfprodisplay-medium-white-18px"
				style={{ marginBottom: 20 }}
			>
				Try curl request for selected AI model
			</div>
			<div className="closemodalbutton" onClick={onClose}>
				<div></div>
				<div></div>
			</div>
			<div className="command-section">
				<JsonFormatter divRef={divRef} header={header} data={body} editable />
				<button onClick={tryCurl}>Try it</button>
				<JsonFormatter data={response} />
			</div>
		</div>
	);
};

export default CommandModal;
