import store from 'store';
import { SET_TOAST } from 'store/types/toast.type';

export const errorResponseMessage = (error) => {
	if (!error) return '';
	if (Array.isArray(error.message)) return error.message.join(', ');
};

export const downloadFile = (link) => {
	fetch(link, {
		method: 'GET',
		headers: {}
	})
		.then((response) => {
			response.arrayBuffer().then(function (buffer) {
				const extension =
					response?.url?.split('.')[response?.url?.split('.').length - 1] ||
					'png';
				
				const url = window.URL.createObjectURL(new Blob([buffer]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `image.${extension}`); //or any other extension
				document.body.appendChild(link);
				link.click();
			});
		})
		.catch((err) => {
			console.log(err);
		});
};

export const copyToClipboard = (data) => {
	const el = document.createElement('input');
	el.value = data;
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
	return notifyToast('success', 'Media URL is copied successfully!');
};

export const notifyToast = (status, message) => {
	store.dispatch({
		type: SET_TOAST,
		payload: {
			status,
			message
		}
	});
};

export const dataURIToBlob = (dataURI) => {
	const splitDataURI = dataURI.split(',');
	const byteString =
		splitDataURI[0].indexOf('base64') >= 0
			? atob(splitDataURI[1])
			: decodeURI(splitDataURI[1]);
	const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

	return new Blob([ia], { type: mimeString });
};
