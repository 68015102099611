/* eslint-disable no-debugger */
import http from 'config/http';
import { PUBLIC_ACCESS } from 'helper/constants';
import { Statsig } from 'statsig-react';
import {
	MEDIA_REQUESTED,
	GET_MEDIA_FAILED,
	GET_MEDIA_SUCCESS,
	DELETE_MEDIA_FAILED,
	ADD_MEDIA,
	DELETE_MEDIA_SUCCESS,
	THIRD_PARTY_REQUESTED,
	GET_THIRD_PARTY_SUCCESS,
	GET_THIRD_PARTY_FAILED,
	UPDATE_MEDIA_SUCCESS,
	UPDATE_MEDIA_FAILED,
	UPLOAD_MASK
} from '../types/media.type';

export const getMediaDataAction =
	(type = null) =>
	async (dispatch) => {
		dispatch({ type: MEDIA_REQUESTED });
		const token = localStorage.getItem('session');
		try {
			// if (token) Statsig.logEvent('LIST_MEDIA_DATA');
			const payload = await http
				.get(`media?${type ? `tags=${type}` : 'excludeTags=mask'}`, {
					headers: token ? { Authorization: `Bearer ${token}` } : PUBLIC_ACCESS
				})
				.then((res) => res.data);
			dispatch({
				type: GET_MEDIA_SUCCESS,
				payload: payload?.media?.reverse() || []
			});
		} catch (err) {
			dispatch({ type: GET_MEDIA_FAILED, payload: err?.response?.data });
		}
	};

export const getThirdPartyDataAction =
	(page = 1, search = null) =>
	async (dispatch) => {
		dispatch({ type: THIRD_PARTY_REQUESTED });
		try {
			const payload = await http
				.get(
					`https://api.unsplash.com/${
						!search ? 'photos' : 'search/photos'
					}?page=${page}&per_page=30${search ? `&query=${search}` : ''}`,
					{
						headers: {
							Authorization: `Client-ID ${process.env.REACT_APP_UNSPLASH_KEY}`
						}
					}
				)
				.then((res) => res.data);
			dispatch({
				type: GET_THIRD_PARTY_SUCCESS,
				payload: search ? payload?.results : payload,
				totalPages: search ? payload.total_pages : 1000,
				page
			});
		} catch (err) {
			dispatch({ type: GET_THIRD_PARTY_FAILED, payload: err?.response?.data });
		}
	};

export const deleteMediaAction = (id, callback) => async (dispatch) => {
	const token = localStorage.getItem('session');
	Statsig.logEvent('DELETE_MEDIA_SUCCESS');
	try {
		await http.delete(`media/${id}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		dispatch({ type: DELETE_MEDIA_SUCCESS });

		callback && callback();
	} catch (err) {
		dispatch({ type: DELETE_MEDIA_FAILED, payload: err?.response?.data });
	}
};

export const updateMediaAction =
	(id, body, selectedIndex) => async (dispatch) => {
		const token = localStorage.getItem('session');
		try {
			await http.put(`media/${id}`, body, {
				headers: { Authorization: `Bearer ${token}` }
			});
			dispatch({
				type: UPDATE_MEDIA_SUCCESS,
				selectedIndex,
				tags: body.tags
			});
		} catch (err) {
			dispatch({ type: UPDATE_MEDIA_FAILED, payload: err?.response?.data });
		}
	};

export const uploadMediaAction =
	(data, tags = ['image'], loading = true, callback, errorCallback) =>
	async (dispatch) => {
		if (loading) dispatch({ type: MEDIA_REQUESTED });
		const token = localStorage.getItem('session');
		tags.forEach((tag, key) => data.append(`tags[${key}]`, tag));

		try {
			// if (token) Statsig.logEvent('ADD_MEDIA_FILE');
			const payload = await http
				.post('https://mediamagic.dev/uploads/upload', data, {
					headers: token ? { Authorization: `Bearer ${token}` } : PUBLIC_ACCESS
				})
				.then((res) => res.data);
			dispatch({
				type: tags.length && tags[0] === 'mask' ? UPLOAD_MASK : ADD_MEDIA,
				payload: {
					id: payload?.media?.id,
					media_key: payload?.media?.url,
					status: 'live'
				}
			});
			callback && callback(payload?.media?.url, payload?.media?.id);
		} catch (err) {
			errorCallback && errorCallback();
			dispatch({ type: GET_MEDIA_FAILED, payload: 'File is invalid' });
		}
	};
